import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { cn } from '@/lib/utils'
import { UseFormReturn } from 'react-hook-form'
import { levels } from '../utils/ConfigureAssement'
import { Label } from '@/components/ui/label'
import { Activity } from 'lucide-react'


const ExperienceLevelSelector = ({form,disabled,placeholder}:{form:UseFormReturn<any>,disabled?:boolean,placeholder?:string}) => {
    
    return (
        <div>
            <FormField
                control={form.control}
                name="experienceLevel"
                render={({field}) => (
                    <FormItem>
                        <Label htmlFor="experienceLevel" className="text-base font-semibold flex items-center gap-2">
                            <Activity className="w-5 h-5" />
                            Experience Level
                        </Label>
                        <Select onValueChange={field.onChange} defaultValue={field.value} disabled={disabled}>
                            <FormControl>
                                <SelectTrigger className={cn("w-full bg-white",form.formState.errors["experienceLevel"] && "border-red-500")}>
                                    <SelectValue placeholder={placeholder??"Select the Experience level"} />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent className="bg-white">
                                {levels.map((level)=><SelectItem value={level}>{level}</SelectItem>)}
                            </SelectContent>
                        </Select>
                        <FormMessage className="text-red-500"/>
                    </FormItem>
                )}
            />
        </div>
    )
}

export default ExperienceLevelSelector