//import * as React from 'react';
import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { ResponsiveContainer } from 'recharts';
//import { ProjectResultsMacroCodeReliability, ProjectResultsMacroOverallRatingData } from 'openapi/api/model';

export type Chart={
  label: string,
  value: number
}

export default function PieChartWithCustomizedLabel({chart}:any) {

  const keyVal_to_array =(obj:any)=>{
    //if(obj){
      return Object.keys(obj).map((key)=>{
        return {
          label:key,
          value:parseInt((obj[key] as number??0).toFixed())
        }
      }
    )//}else{
      //return []
    //}
  }

  //console.log("Array from PC: ",keyVal_to_array(chart))
  const pieData = keyVal_to_array(chart)

  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };
  const TOTAL = pieData.map((item) => item.value).reduce((a, b) => a + b, 0);
  //console.log(TOTAL)
  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed()}%`;
  };

  if(!chart||pieData.every((num)=>{return num.value===0})){
    return(
      <ResponsiveContainer width="100%" height={250}>
        <div className='flex flex-col h-full justify-center items-center text-center text-black'>No data</div>
      </ResponsiveContainer>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart
      colors={['#26A69A','#ccfbf1']}
      series={[
        {
          outerRadius: 125,
          data:pieData,
          arcLabel: getArcLabel,
          arcLabelMinAngle: 45,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'black',
          fontSize: 14,
        },
      }}
      {...sizing}
    />
    </ResponsiveContainer>
    
  );
}