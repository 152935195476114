import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Toaster } from "@/components/ui/sonner"
import { useGetHiringcampaignsId, usePutHiringcampaignsId } from "../../../../openapi/api/endpoints/default/default"
import { HiringCampaign } from "openapi/api/model"
import { toast } from "sonner"
import { useNavigate } from "@tanstack/react-router"
//import { Input } from "@/components/ui/input"
//import { Label } from "@/components/ui/label"

export function CloseCampaignDialog({btn,id}:{btn:any,id:string}) {
    const navigate=useNavigate({from:`/allCampaigns/${id}`})
    const {data:hcData} = useGetHiringcampaignsId(id)
    const {mutate,isSuccess,isError,error} = usePutHiringcampaignsId()

    const onSubmit = () => {
      console.log("Closing...")
      const hc:HiringCampaign={
        //_id: id,
        companyId: hcData?.companyId as string,
        companyName: hcData?.companyName as string,
        createdBy: hcData?.createdBy,
        devlimit: hcData?.devlimit as number,
        experienceLevel: hcData?.experienceLevel as string,
        //licenseId: hcData?.licenseId as string,
        minimumScore: hcData?.minimumScore as number,
        position: hcData?.position as string,
        positionDescription: hcData?.positionDescription as string,
        skills: hcData?.skills as string[],
        manuallyClosed:true,
        //status: "Closed" as string,
        sub: hcData?.sub,
        startDate: hcData?.startDate as string,
        endDate: hcData?.endDate as string,
        //timeline: hcData?.timeline as string,
        userid: hcData?.userid
      }

      mutate({id:id,data:hc})
      if (isSuccess){
        console.log('Success: ',hc);
        toast.success("Submitted!",{
          description: 'Hiring Campaign closed successfully',
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: '#a3e635'
          }
        })
        navigate({to:'/allCampaigns'})
      }
  
      if (isError){
        console.log('Error: ',error);
        toast.error("An error occured!",{
          description: `${error}`,
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: '#dc2626'
          }
        })
      }
  
    }
    
    return (
        <Dialog>
            <DialogTrigger asChild>
                {btn}
            </DialogTrigger>
            <DialogContent className="p-4 sm:max-w-[425px] bg-white">
                <DialogHeader>
                <DialogTitle>Close Campaign</DialogTitle>
                <DialogDescription>
                  Are you sure you want to close this campaign? This action cannot be undone.
                </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                <Button type="submit" onClick={onSubmit} className="bg-mint100 text-black hover:bg-teal100">Yes! Close this campaign</Button>
                </DialogFooter>
            </DialogContent>
            <Toaster/>
        </Dialog>
    )
}
