import { rootRoute } from "@/App";
import LinksContainer from "@/components/Navigation/LinkContainer";
import SideBar from "@/components/Navigation/SideBar";
import { AuthenticateUserResp } from "@/pages/Authentication/AuthUtils/Login";
import { Outlet, Route, redirect, useNavigate } from "@tanstack/react-router";
import { useQueryClient } from "@tanstack/react-query";
import logo3 from "../assets/logo3.png";
import { useGetUserHiringresource, useGetUserHiringresourceAccount } from "../../openapi/api/endpoints/default/default";
//import { LicenseForm } from "@/pages/LicenseID/LicenseForm"
//import { Card } from "@/components/ui/card"
import { Button } from "@/components/ui/button";

export const MainApp = () => {
  const navigate = useNavigate({ from: "/" });
  const queryClient = useQueryClient();
  const { data, isSuccess, isLoading } = useGetUserHiringresource();
  const { data:users, isSuccess:isUserSuccess } = useGetUserHiringresourceAccount()
  let write = false;
  
  let content = <Outlet />;

  if (!data?.licenseId || data?.licenseId === undefined) {
    if (isLoading) {
      content = (
        <div className="flex flex-col basis-1/3 bg-gray-100 min-h-screen items-center place-content-center border-red-300">
          <h1>Loading...</h1>
        </div>
      );
    }
    /* content = <div className="flex flex-col basis-1/3 bg-gray-100 min-h-screen items-center place-content-center border-red-300">
                <Card className="grid grid-col gap-4 p-4 bg-white w-[350px]">
                  <h1>Your account has not been associated to a license yet. Please contact your admin to get the license ID.</h1>
                  <LicenseForm 
                    firstName={data?.firstName}
                    lastName={data?.lastName}
                    position={data?.position}
                    company={data?.company}
                    phone={data?.phone}
                  />
                </Card>
              </div> */
  }

  if (isSuccess && data?.licenseId) {

    content = <Outlet />;
    if(isUserSuccess){
      write = users?.find((user)=>user.user?._id===data?._id)?.permissions[0].permissions.write as boolean
    }
  }
  
  const logOut=async ()=>{
    //queryClient.setQueryData(['Auth'],null) 
    queryClient.removeQueries()
    setTimeout(()=>{
      navigate({to:'/auth'})
    },1000)
  }
  return (
    <div>
      <div>
        <SideBar>
          <div className="h-24 w-42 overflow-hidden">
            <img
              src={logo3}
              alt="Logo"
              className="h-[70px] w-[110px] object-cover h-full w-full object-center "
            />
          </div>
          <div className="basis-10/12 items-center">
            <LinksContainer
              links={[
                //{to:"/dashboard",title:"Dashboard"},
                { to: "/allCampaigns", title: "All Campaigns" },
                //{to:"/hiringProcess",title:"Campaign Details"},
                { to: "/newCampaign", title: "New Campaign", disabled:!write },
                //{to:"/selectLicense",title:"Select License"}
              ]}
            />
          </div>
          <div className="basis-1/6">
            <Button
              onClick={logOut}
              className="bg-primary100 rounded-full text-black hover:bg-teal-200"
            >
              <p className="text-black">Log out</p>
            </Button>
          </div>
        </SideBar>
        <div className="sm:ml-44">{content}</div>
      </div>
    </div>
  );
};

export const MainAppRoute = new Route({
  component: MainApp,
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: ({ context: { queryClient } }) => {
    const resp = queryClient.getQueryData(["Auth"]) as
      | AuthenticateUserResp
      | undefined;
    if (!resp) {
      throw redirect({
        to: "/auth",
        replace: true,
      });
    }
    /* redirect({
        to:'/overview',
        replace: true
      }) */
    return {
      auth: resp, //making auth payload available accross all child pages
    };
  },
  /*
      load:()=>{
        console.log('loading time !!! @/') 
      }
      */
});
