import CustomPieChart from "@/components/ui/custom/Charts/CustomPieChart";
//import { values_To_NumArray } from "../Overview/utils/UtilFunctions";

export type MetScoreCriteriaProps={
  //children:React.ReactNode,
  //title?:string,
  values?:number[],
  //labels?:string[],
}

const MetScoreCriteria = ({data}:{data?:any}) => {
    let chartData={
        Passed:0,Failed:0
    }
  
    let empty = false
    if(data){
      empty = ((data.passed===null &&data.failed===null)  ||
      (data.passed===0 && data.failed===0) )
      chartData = data
      console.log(empty)
    }
    return (  
      <>
        {empty && <CustomPieChart title="Met Score Criteria"/>
        }
        {!empty && <CustomPieChart title="Met Score Criteria" chart={chartData}/>
        }
      </>
    ) 
}

export default MetScoreCriteria;
