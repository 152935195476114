import { Input } from '@/components/ui/input';
import React, { useState, useEffect } from 'react';

interface RangeFilterProps {
  valueName:string,
  columnId: string;
  currentFilter: [number | string, number | string] | null;
  maxColumnValue?: number | string;
  onFilterChange: (columnId: string, filterValue: [number | string, number | string]) => void;
}

const RangeFilter: React.FC<RangeFilterProps> = ({valueName, columnId,maxColumnValue, currentFilter, onFilterChange }) => {
  const [minValue, setMinValue] = useState<string>('');
  const [maxValue, setMaxValue] = useState<string>('');

  // useEffect hook to update component state when the currentFilter prop changes
  // This is useful for initializing the inputs with existing filter values when the component mounts or when the filters are externally changed
  useEffect(() => {
    if (currentFilter) {
      console.log('current filter value is',String(currentFilter[0]))
      setMinValue(String(currentFilter[0]));
      if(maxColumnValue){
        setMaxValue(String(currentFilter[1]));
      }
    }
  }, [currentFilter,maxColumnValue]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const min = event.target.value;
    setMinValue(min);
    onFilterChange(columnId, [min, maxColumnValue??maxValue]);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const max = event.target.value;
    setMaxValue(max);
    onFilterChange(columnId, [minValue, max]);
  };



  return (
    <div>
      <Input
        type="number"
        value={minValue}
        min={0}
        max={100}
        onChange={handleMinChange}
        placeholder={`${valueName}`}
      />
    {!maxColumnValue && 
      <Input
        type="number"
        value={maxValue}
        min={0}
        max={100}
        onChange={handleMaxChange}
        placeholder={`Max ${valueName} score`}
      />
    }
    </div>
  );
};

export default RangeFilter;
