import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import { DownloadIcon, SearchIcon, CheckCircle, XCircle} from 'lucide-react'
import { LoadingSpinner } from '@/components/ui/loadingSpinner'
import { useGetHiringCampaignsIdApplicants } from '../../../openapi/api/endpoints/default/default';
import PresignedLink from '@/components/Tables/Candidates/ViewResume'
import { Candidate } from 'openapi/api/model'
import SmallText from '@/components/ui/SmallText'




const ITEMS_PER_PAGE = 20

export const ApplicantsTable = ({hiringCampaignId}:{hiringCampaignId:string}) => {
  const {data,isLoading}=useGetHiringCampaignsIdApplicants(hiringCampaignId)
  const [searchTerm, setSearchTerm] = useState("")
  const [showOnlyQualified, setShowOnlyQualified] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)


  if(isLoading){
    return <LoadingSpinner/>
  }

  if(!data){
    return <>no data</>
  }

  const filteredApplicants = data.filter(applicant =>
    (showOnlyQualified ? applicant.qualified : true) &&
    Object.values(applicant).some(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const totalPages = Math.ceil((filteredApplicants).length / ITEMS_PER_PAGE)
  const paginatedApplicants = filteredApplicants.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const downloadCSV = () => {
    const headers = ["ID", "First Name", "Last Name", "Company Name", "Position", "User Name","password", "Email", "Is Qualified", "Resume URL", "hiring camapaign ID"]
    const csvContent = [
      headers.join(","),
      ...filteredApplicants.map(applicant => {
        if(!applicant.qualified)return
        return [applicant.id, applicant.firstName, applicant.lastName, applicant.companyName, applicant.position, applicant.userName,applicant.password, applicant.email, applicant.qualified, applicant.resumeUrl, hiringCampaignId].join(",")}
      )
    ].join("\n")

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement("a")
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", "applicants.csv")
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }


  const copyLinkHandler = (applicant:Candidate) => {
    navigator.clipboard.writeText(`https://app.thedevfactory.ca/resume?username=${applicant.userName}&access=${applicant.password}&email=${applicant.email}`);
  };

  return (
    <div className="max-w-[2000px] mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="relative w-full sm:w-auto flex-grow">
          <SearchIcon className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
          <Input
            type="text"
            placeholder="Search applicants..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
            className="pl-8 w-full dark:bg-gray-700 dark:text-white"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            id="qualified-filter"
            checked={showOnlyQualified}
            onCheckedChange={(checked) => {
              setShowOnlyQualified(checked)
              setCurrentPage(1)
            }}
          />
          <Label htmlFor="qualified-filter" className="dark:text-white">Show only qualified</Label>
        </div>
        <Button onClick={downloadCSV} className="dark:bg-blue-600 dark:hover:bg-blue-700">
          <DownloadIcon className="mr-2 h-4 w-4" /> Download CSV
        </Button>
      </div>
      <div className="border dark:border-gray-700 rounded-lg overflow-x-auto w-full">
        <Table>
          <TableHeader>
            <TableRow className="dark:bg-gray-800 dark:text-gray-300">
              <TableHead className="w-[150px]">First Name</TableHead>
              <TableHead className="w-[150px]">Last Name</TableHead>
              <TableHead className="w-[200px]">Company Name</TableHead>
              <TableHead className="w-[200px]">Position</TableHead>
              <TableHead className="w-[150px]">User Name</TableHead>
              <TableHead className="w-[200px]">Email</TableHead>
              <TableHead className="w-[100px]">Qualified</TableHead>
              <TableHead className="w-[100px]">Resume</TableHead>
              <TableHead className="w-[100px]">Sign Up Link</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedApplicants.map((applicant) => (
              <TableRow key={applicant.id} className="dark:bg-gray-800 dark:border-gray-700">
                <TableCell className="dark:text-white">{applicant.firstName}</TableCell>
                <TableCell className="dark:text-white">{applicant.lastName}</TableCell>
                <TableCell className="dark:text-white">{applicant.companyName}</TableCell>
                <TableCell className="dark:text-white">{applicant.position}</TableCell>
                <TableCell className="dark:text-white">{applicant.userName}</TableCell>
                <TableCell className="dark:text-white">{applicant.email}</TableCell>
                <TableCell>
                  {applicant.qualified ? 
                    <CheckCircle className="text-green-500" /> : 
                    <XCircle className="text-red-500" />
                  }
                </TableCell>
                <TableCell>
                  <PresignedLink hiringCampaignId={hiringCampaignId} resumeKey={applicant.resumeUrl??''}/>
                </TableCell>
                <TableCell className="dark:text-white">
                  <button
                  className="rounded-md p-1 bg-mint100 hover:bg-cyan-400 active:bg-cyan-500 focus:outline-none focus:ring focus:ring-cyan-100"
                  onClick={()=>copyLinkHandler(applicant)}>
                    <SmallText>Copy Link</SmallText>
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {filteredApplicants.length === 0 && (
        <p className="text-center text-gray-500 dark:text-gray-400 mt-4">No applicants found.</p>
      )}
      {filteredApplicants.length > 0 && (
        <div className="mt-4">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious 
                  href="#" 
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  isActive={!(currentPage === 1)}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, i) => (
                <PaginationItem key={i}>
                  <PaginationLink 
                    href="#" 
                    onClick={() => setCurrentPage(i + 1)}
                    isActive={currentPage === i + 1}
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem>
                <PaginationNext 
                  href="#" 
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  isActive={!(currentPage === totalPages)}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}
    </div>
  )
}
