import { Checkbox } from "@/components/ui/checkbox"
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Label } from "@/components/ui/label"
import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { cn } from '@/lib/utils'
import { DropdownButton } from "@/pages/HiringCampaign/components/Dropdown"
import { Skill, skills } from "@/pages/HiringCampaign/utils/AssessmentSkills"
import { Layers } from "lucide-react"
import { UseFormReturn } from 'react-hook-form'

export const SkillsCheckbox = ({skills}:{skills:string[]}) => {
    return (
        <div className="flex flex-cols">
          {skills.map((skill, index) => (
            <div key={index} className=" justify-self-center p-1">
              <Checkbox checked disabled />
              <label className="p-1"><i>{skill}</i></label>
            </div>
          ))}
        </div>
      );
}

export const FormSkillsCheckbox = ({form,disabled}:{form:UseFormReturn<any>,disabled?:boolean}) => {

  return (
    <FormField
    control={form.control}
    name="skills"
    render={({field}) => (
      <FormItem>
        <Label htmlFor="skills" className="text-base font-semibold flex items-center gap-2">
          <Layers className="w-5 h-5" />
          Skills
        </Label>
        <DropdownButton
          componentTitle={`${form.getValues("skills")}`}
          disabled={disabled}
          errors={
            form.formState.errors["skills"] && (
              <FormMessage className="text-red-500">{`${form.formState.errors["skills"].message}`}</FormMessage>
            )
          }
          Component={
            <Select onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger className={cn("border rounded-lg",form.formState.errors["skills"] && "border-red-500")} disabled={disabled}>
                  <SelectValue placeholder="Select the skills you will be evaluating" />
                </SelectTrigger>
              </FormControl>
              <SelectContent className="bg-white border rounded-lg">
                <div className="p-2">
                  {skills.map((skill:Skill)=>{
                    const {id,label}=skill;
                    return (
                      <div key={id} className="grid gap-2 grid-cols-2 py-2">
                        <Label htmlFor={id}>{label}</Label>
                        <input 
                          type='checkbox' 
                          {...form.register("skills")} 
                          value={id}
                          className="border justify-left"/>
                      </div>
                    )
                  })}
                </div>
              </SelectContent>
            </Select>
          }
        />
      </FormItem>
    )}
    />  
  );
}

