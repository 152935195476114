
import { useForm } from "react-hook-form";
import * as z from "zod";

import {
  AllCampaignDetailResponse,
  HiringCampaign,
  HrUser,
} from "openapi/api/model";
import {
  useGetHiringcampaignsSearch,
  //useGetLicensesId,
  useGetUserHiringresource,
  usePostHiringcampaigns,
} from "../../../openapi/api/endpoints/default/default";
import {
  Form
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { Toaster } from "@/components/ui/sonner";
import { queryClient } from "@/App";
import { values_To_NumArray } from "../Overview/utils/UtilFunctions";
import { HiringCampaignFormSchema } from "@/utils/FormUtils/FormSchema";
import { HiringCampaignSetup } from "./components/HiringCampaignSetup";
import { useNavigate } from "@tanstack/react-router";


type HiringCampaignFormValues = z.infer<typeof HiringCampaignFormSchema>;


export function HiringCampaignForm() {
  const navigate = useNavigate({from:'/'})
  const { data: user } = useGetUserHiringresource();
  const userData = user as HrUser;
  //const { data: license }=useGetLicensesId(userData.licenseId)
  const { data: HCdetail } = useGetHiringcampaignsSearch({
    HR_format: "AllCampaignDetail",
    licenseId:userData?.licenseId??'' 
  },
  {
    query:{
      enabled: !!userData && !!userData.licenseId
    }
  }
);
  const detail = HCdetail as AllCampaignDetailResponse;

  let usage = [0,0]
    if(detail){
      let usageDetail = {
        remaining: detail?.remaining_campaigns,
        used: detail?.campaigns_used,
      };
      const usageArr = values_To_NumArray(usageDetail)
      usage = usageArr
      console.log("Campaign Usage: ",usage)
    }
  

  const form = useForm<HiringCampaignFormValues>({
    defaultValues: {
      //position: "",
      positionDescription: "",
      companyName: "",
      experienceLevel: "",
      skills: [],
    },
    resolver: zodResolver(HiringCampaignFormSchema),
  });

  const {mutate, isSuccess, error}=usePostHiringcampaigns({
    mutation: {
      onSuccess:(data)=>{
        console.log('Mutation Success: ',data)
        
        toast.success("Submitted!",{
          description: 'New Hiring Campaign created successfully',
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: '#a3e635'
          }
        })
        navigate({to:'/allCampaigns'})
      },
      onError:()=>{
        console.log('Error: ',error);
        toast.error("An error occured!",{
          description: `${error}`,
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: "#dc2626",
          },
        });
      },
      onSettled:()=>{
        queryClient.invalidateQueries({queryKey:[`/hiringcampaigns`]})
      }
    }
  });

  const onSubmit = (data: HiringCampaignFormValues) => {
    console.log('sumbitted data: ',data)
    //const submittedData = data as HiringCampaignFormValues;
    const startDate = data?.startOfCampaignDate.toISOString(); //.split("T")[0]
    const endDate = data?.endOfCampaignDate.toISOString(); //.split("T")[0]

    console.log("Submitted!!", data);
    const campaignInfo: HiringCampaign = {
      //sub:user?.sub as string,
      //createdBy:{_id:user?.userName as string, username:user?.userName as string},
      position: data.position??'',
      positionDescription: data.positionDescription,
      skills: data.skills,
      experienceLevel: data.experienceLevel,
      companyName: data.companyName,
      devlimit: 150,
      companyId: userData.companyId as string,
      startDate: startDate,
      endDate: endDate,
      manuallyClosed: false,
      minimumScore: 80,
    };
    console.log("Payload: ", campaignInfo);

    mutate({data:campaignInfo})
      
  };

  return (
    <div className="pb-16">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-16">        
          <HiringCampaignSetup form={form} submitted={isSuccess} title={"Create your new campaign"} buttonText={"Generate Campaign"}/>
        </form>
      </Form>
      <Toaster/>
    </div>
  );
}
