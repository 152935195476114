import { Route } from '@tanstack/react-router';
//import { rootRoute } from "../../App"
import { MainAppRoute } from "@/rootRoutes/MainApp"
import { manageAccessToken } from "@/utils/Auth/Session"
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login"
//import { HiringCampaignForm } from './HiringCampaignForm';
import { Header } from '@/components/ui/Title';
import { Card, CardContent } from '@/components/ui/card';
import PricingCards from './PricingModels';
//import { useGetUser } from '../../../openapi/api/endpoints/default/default';
import { plans } from './PricingModelData/MockData';

const SelectLicense=()=>{
  
  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
        <div className="flex pl-4">
            <Header>Select your plan</Header>
        </div>
        <div className="flex flex-col m-4">
            <Card className="border-none bg-white">
                <CardContent className="p-4">
                  <div className='px-80 pb-4 mb-4'>
                    <h1 className='text-center'>
                      TheDevFactory provides 3 different pricing models to accomodate all tech companies.
                      Please select which plan you would like to setup for your business.
                    </h1>
                  </div>
                  <PricingCards plans={plans}/>
                </CardContent>
            </Card>
        </div>
    </div>
  )
}

const SelectLicenseRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "/selectLicense",
    component: SelectLicense,
    beforeLoad:async ({context:{auth,queryClient}})=>{
      const session=await manageAccessToken({AuthPayload:auth})
      if(!session){//no token means that token is still good
        return
      }
      const newAuthPayload={
        ...auth,
          session
        } as AuthenticateUserResp
      queryClient.setQueryData(['Auth'],newAuthPayload)
      return {
        auth:newAuthPayload
      }
    },
    })
export default SelectLicenseRoute;