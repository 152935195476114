//"use client"
//import Link from "next/link"
//import FormElements from "@/components/ui/FormElements";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
//import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Switch } from "@/components/ui/switch";
import { NewUserSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePutPermissionsId } from "../../../../openapi/api/endpoints/default/default";
import { Permission, AccessRights, AccessRightsPermissions, AccountUsers } from "openapi/api/model";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { Toaster } from "@/components/ui/sonner";
import { useEffect } from "react";
import DeleteUser from "./DeleteUser";

type EditUserFormValues = z.infer<typeof NewUserSchema>

export function EditUserForm({user,isUserSuccess}:{user?:AccountUsers,isUserSuccess:boolean}) {
    
    const {mutate, error} = usePutPermissionsId({
        mutation:{
            onSuccess:()=>{
                toast.success("Submitted!",{
                    description: 'User updated successfully',
                    action: {
                        label: "Dismiss",
                        onClick: () => {},
                    },
                    style: {
                        background: '#a3e635'
                    }
                })
            },
            onError:()=>{
                console.log('Error: ',error);
                toast.error("An error occured!",{
                    description: `${error}`,
                    action: {
                        label: "Dismiss",
                        onClick: () => {},
                    },
                    style: {
                        background: '#dc2626'
                    }
                })
            }
        }
    })
    //const {mutate:UpdateUser, isSuccess:isUpdateHrSuccess, isError:isUpdateHrError} = usePutUserHiringresourceUserId()
    const form = useForm<EditUserFormValues>({
        resolver: zodResolver(NewUserSchema),
        defaultValues:{
            //position:user?.user.position,
            //read:user?.permissions[0].permissions.read,
            write:user?.permissions[0].permissions.write
        }
        });
  
    const onSubmit=(data:any)=>{
        //post to db
        const submittedData = data as EditUserFormValues
        let permissions:AccessRights[] = [] 
        permissions[0] = {
            permissions:{
                read:true, 
                write:!!submittedData.write //|| disabled
            } as AccessRightsPermissions,
            resourceName:"hiringcampaigns"
        }

        const permission:Permission={
            email:submittedData?.email as string,
            isAdmin:true && !!submittedData.write,
            licenseId:user?.user.licenseId as string,
            permissions:permissions
        }
        console.log(user?._id,permission)

        //UpdateUser({userId:user?.user._id as string,data:hrUser})
        mutate({id:user?._id as string,data:permission})
        
    }

    useEffect(() => {
        if (isUserSuccess && user?.user) {//only rerender if these conditions are satisfied
          form.reset(user.user);
        }
      }, [form, user?.user, isUserSuccess]); // Only re-run if these dependencies change    
      

    return (
        <div className="flex flex-col w-full bg-white border rounded-md p-8">
            <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col items-center space-y-8 ">        
                <div className="grid grid-cols-2 w-full space-x-8">
                    <div className="grid grid-rows-3 pt-4 gap-8">
                        <FormField
                        control={form.control}
                        name="firstName"
                        render={() => (
                            <FormItem>
                            
                            <FormControl>
                                <Input 
                                placeholder="First Name"
                                readOnly={true}
                                {...form.register('firstName')}
                                className="bg-mint100 opacity-40"/>
                            </FormControl>
                            <FormMessage />
                            </FormItem>
                        )}
                        />
                        <FormField
                        control={form.control}
                        name="lastName"
                        render={() => (
                            <FormItem>
                            
                            <FormControl>
                                <Input 
                                placeholder="Last Name"
                                readOnly={true}
                                {...form.register('lastName')}
                                className="bg-mint100 opacity-40"/>
                            </FormControl>
                            <FormMessage />
                            </FormItem>
                        )}
                        />
                        <FormField
                        control={form.control}
                        name="email"
                        render={() => (
                            <FormItem>
                            
                            <FormControl>
                                <Input 
                                placeholder="Email"
                                readOnly={true}
                                {...form.register('email')}
                                className="bg-mint100 opacity-40"/>
                            </FormControl>
                            <FormMessage />
                            </FormItem>
                        )}
                        />
                        {/* <div>
                            <DeleteUser user={user} />
                        </div> */}
                    </div>
                    <div className="grid grid-rows-3">
                        <FormField
                        control={form.control}
                        name="write"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between p-2">
                                <div className="">
                                    <FormLabel className="text-base">Can create campaigns</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                    //disabled={disabled}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                        />
                        {/* <FormField
                        control={form.control}
                        name="read"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between p-2">
                                <div className="">
                                    <FormLabel className="text-base">Can see all campaigns</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                    disabled={disabled}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                        />
                        <FormField
                        control={form.control}
                        name="position"
                        render={({ field }) => (
                            <FormItem className="flex flex-col items-start justify-between p-2 mt-2">
                                <FormLabel className="text-base">Position:</FormLabel>
                                <FormControl>
                                    <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-row space-y-1">
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="Manager" onClick={()=>{
                                                    form.setValue('write',true)
                                                    form.setValue('read',true)
                                                    setDisabled(true)
                                                }}/>
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                Manager
                                            </FormLabel>
                                        </FormItem>
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="User" onClick={()=>{
                                                    //form.setValue('write',false)
                                                    //form.setValue('read',false)
                                                    //form.getFieldState('write')
                                                    setDisabled(false)
                                                    console.log('Form State: ',form.getValues('write'))
                                                }}/>
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                User
                                            </FormLabel>
                                        </FormItem>
                                    </RadioGroup>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                        /> */}
                    </div>
                </div>
                <Button type="submit" className="bg-mint100 text-black hover:bg-teal100">Update user</Button>
            </form>
            </Form>
            <div className="flex flex-col items-center mt-4">
                <DeleteUser user={user} />
            </div>
            <Toaster/>
        </div>
    )
}