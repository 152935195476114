export const keyVal_to_array=(obj:any)=>{
    return Object.keys(obj).map((key)=>{
        return {
          name:key, //labelArr[index],
          total:obj[key]
        } 
      }
    )
  }

export const values_To_NumArray=(data:any)=>{
    const valuesArray:number[] = [];

    // Iterate through the object and push the values into the array
    for (const key in data) {
        let value:number = data[key];
        //let val = parseFloat(value.toFixed(2))
        valuesArray.push(parseInt((value??0).toFixed()));
    }
    return valuesArray
}

/* export const arrays_To_Object=(arr1:number[],arr2:string[])=>{
    const valuesArray:number[] = [];
    
    return Object.keys(obj).map((key,index)=>{
        return {
          name:key, //labelArr[index],
          total:obj[key]
        } 
      }
    )
    for (const key in data) {
        const value:number = data[key];
        valuesArray.push(value);
    }
    return valuesArray
} */