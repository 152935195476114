import { Button } from "@/components/ui/button";
import { Share } from 'lucide-react';
import { useState } from "react";
import { AccountUsers } from "openapi/api/model";
import { EditPendingUserForm } from "./EditPendingUserForm";

export function EditPendingUser({user}:{user?:AccountUsers}) {
    //console.log('User editing: ', user)
    const [showForm,setShowForm] = useState(false)

    return (
        <div className="flex flex-col space-y-1">
            <Button onClick={()=>setShowForm(!showForm)} className="flex flex-row justify-start h-16 bg-white text-left hover:bg-gray-300">
                <div className="flex flex-row items-center h-full w-11/12 space-x-2">
                    <p className="flex text-black text-destructive space-x-8">{user?.email}</p>
                </div>
                <div className="flex flex-row items-center justify-center h-full w-1/12 space-x-2">
                    <p className="flex text-gray-500 text-2xl space-x-8">{!showForm && <Share/>}</p>
                </div>
            </Button>
            {showForm && <EditPendingUserForm user={user}/>}
        </div>
    )
}