//import React from 'react'
import { Input } from "@/components/ui/input"
import { Table } from '@tanstack/react-table'

export interface SearchFilterProps<TData>{
    table:Table<TData>,
    columnId:string,
    placeHolder:string
}

function SearchFilter<TData>({table,columnId,placeHolder}:SearchFilterProps<TData>){
  return (
    <div className="flex items-center py-4">
    <Input
      placeholder={placeHolder}
      value={(table.getColumn(columnId)?.getFilterValue() as string) ?? ""}
      onChange={(event) =>
        table.getColumn(columnId)?.setFilterValue(event.target.value)
      }
    />
  </div>
  )
}

export default SearchFilter