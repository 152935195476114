import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { ResponsiveContainer } from "recharts";

export type HalfGaugeProps = {
  width?: number;
  height?: number;
  value?: number;
};

export default function HalfGauge({ width, height, value }: HalfGaugeProps) {
  const w = width ?? 50;
  const h = height ?? 250;
  const settings = {
    //width: width??200,
    //height: height??250,
    value: value,
  };
  return (
    <ResponsiveContainer width={`${w}%`} height={h}>
      <Gauge
        {...settings}
        startAngle={-100}
        endAngle={100}
        cornerRadius="50%"
        sx={() => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 25,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: "#26A69A",
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: "#ccfbf1",
          },
        })}
        text={({ value }) => `${value}`}
      />
    </ResponsiveContainer>
  );
}
