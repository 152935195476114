import CustomBarChart from "../../components/ui/custom/Charts/CustomBarChart";
import { values_To_NumArray } from "../Overview/utils/UtilFunctions";
/* 
export type TotalApplicantsProps={
  data?:any,
}
 */
const TotalApplicants = ({data}:{data?:any}) => {
    //const totalAppsArr=values_To_NumArray(data)
    console.log("Total Apps data: ",data)
    let totatlApps = [0,0]
    const totatlAppsLabels = ["In Progress","Complete"]

    if(data){
      const totalAppsArr = values_To_NumArray(data)
      totatlApps = totalAppsArr
      console.log("Total Apps: ",totatlApps)
    }
    
    //const data=[]
    return (  
        <CustomBarChart title="Total Applicants" values={totatlApps} labels={totatlAppsLabels}/>     
    ) 
}

export default TotalApplicants;
