/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthenticateUserResp, SignIn } from "@/pages/Authentication/AuthUtils/Login";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { CognitoRefreshToken, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { executeWithin10Minutes } from "./SessionTime";
import { signUp } from "@/pages/Authentication/AuthUtils/Signup";
import { usePostUserHiringresource } from '../../../openapi/api/endpoints/default/default';
import { AddClientRedirect } from '@/pages/Authentication/AuthComponents/SignUp';
import { HrUser } from '../../../openapi/api/model/hrUser';
/**
 * @Session How it works =>
 * for a given parent and child route, if there is a match on a child route, the beforeLoad for 
 * both the parent AND the child routes will fire (parent then child's) THEN load fires for
 * both in that sequence
 * @usage Use beforeLoad of parent component to validate authentication, then the child 
 * beforeLoad to manage accessToken lifecycle (ensure API calls do not bounce), then parent load 
 * to relevant queryData then childs - this also promotes better separation of concerns
 * @param queryClient instance 
 */



export const useSignIn=({queryClient, onSuccess}:{queryClient:QueryClient,onSuccess?:()=>void})=>{
    //const [sessionStarted,setSessionStarted]=useState<boolean>(false)
    return useMutation({
        mutationFn:SignIn,
        mutationKey:['Auth'],
        onSuccess:async (data)=>{
            queryClient.setQueryData(['Auth'],data);
            //await setCookie({authData:data})
            //setSessionStarted(true)
            onSuccess&&onSuccess()
        },
    })
}
/**
 * Account creation logic for when user is added through sharable link
 * Normal SignIn does not require this as without a link, we create dev users on the purchase of a license
 * @1-Logs in in cognito and sets token in cache
 * @2-Creates HrUser when cognito login is successful
 */
export const useCreateAccount=({queryClient,redirect,HrUser,onSuccess}:{queryClient:QueryClient,redirect?:AddClientRedirect,HrUser?:HrUser,onSuccess:()=>void})=>{
    const {mutate,isPending,error,data:newUserId}=usePostUserHiringresource({
        mutation: {
            onSuccess,
            onSettled:()=>{
                queryClient.invalidateQueries({queryKey: [`/user-hiringresource`]})
            }
        }
    })
    const {mutate:createAccount,isPending:pendingSignIn,isSuccess:signedIn,error:signInError}=useSignIn({
        queryClient,
        onSuccess:()=>{//if this is a signup flow from redirect, create also an entry in HR user 
            const resp=queryClient.getQueryData(['Auth']) as AuthenticateUserResp
            const sub=resp.session?.getIdToken().payload.sub as string
            if(!redirect || !redirect.permission || !sub || !HrUser){
                console.log('failed attempt at accout creation')
                console.log({
                    permission:redirect?.permission,
                    sub,
                    HrUser
                })    
                return
            }
            console.log('successful HR user creation; lets create cognito account')
            mutate({data:HrUser})//this needs to run on 2 cases: user already exists or user has been created
        }})
    return {
        createAccount,
        loading:isPending && pendingSignIn,
        isSuccess: signedIn,
        errors:error || signInError ? {...error,...signInError}:null,
        newUserId
    }
}

export const renewToken=({cognitoUser,refreshToken}:{cognitoUser:CognitoUser,refreshToken:CognitoRefreshToken}):Promise<Error | CognitoUserSession>=>{
    return new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, session:CognitoUserSession) => {
          if (err) {
            reject(new Error('failed to refresh session'));
          } else {
            resolve(session);
          }
        });
      });
}

/**
 * Checks and refreshes token if needed to maintain session data based on signIn response
 * payload
 * @param AuthPayload signIn response payload
 * @todo Refresh token will eventually expire; if it does, sign the user out
 */
export const manageAccessToken=async ({AuthPayload}:{AuthPayload:AuthenticateUserResp})=>{
    const {session}=AuthPayload
    if (!session){
        throw Error('session data is missing')
    }
    const expirationTime=session.getAccessToken().getExpiration()
    const refreshToken=session.getRefreshToken()
    try{
        const newSessionData=await executeWithin10Minutes({
            callBack: async ()=> renewToken({
                cognitoUser:AuthPayload.cognitoUser,
                refreshToken:refreshToken
            }),
            deadLine:expirationTime
        }) 
        return newSessionData as CognitoUserSession | undefined
    }catch(e){
        throw Error ('Error renewing token')
    }}

type useSignUpProps={
    onFailureFunc?:(err: Error)=>void
}
    
export const useSignUp=(onFailure?:useSignUpProps)=>{
    return useMutation({
        mutationFn:signUp,
        onError:(err)=>{
            if(!onFailure || !onFailure.onFailureFunc)return
            onFailure.onFailureFunc(err)
            console.log({err})
        }
    })
}


