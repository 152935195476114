import { CardTitle, Card, CardHeader, CardContent } from "@/components/ui/card";
import { Title } from "@/components/ui/Title";
import InfoCard from "./InfoCard";

export type MacroProps={
  children:React.ReactNode,
  title?:string,
  definition?:string,
  benefit?:string,
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardContentStyle?:string
}

const MacroCard = ({
  children,
  title,
  definition,
  benefit,
  cardStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardContentStyle
}:MacroProps) => {
    return (          
      <Card className={`m-4 p-4 bg-white ${cardStyle}`}>
        <CardHeader className={`pt-2 pl-2 ${cardHeaderStyle}`}>
          <CardTitle className={`text-white ${cardTitleStyle}`}>
            <InfoCard definition={definition} benefit={benefit}>
              <Title>{title}</Title>
            </InfoCard>
          </CardTitle>
          {/* <CardDescription className={`flex ${cardDescriptionStyle}`}>
            <p className="truncate">{description}</p>
          </CardDescription> */}
        </CardHeader>
        <CardContent className={`grid grid-cols-1 justify-center items-center justify-items-center ${cardContentStyle}`}>
          {children}
        </CardContent>
      </Card>
    ) 
}

export default MacroCard;
