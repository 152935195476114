import CustomBarChart from "../../components/ui/custom/Charts/CustomBarChart";
import { values_To_NumArray } from "../Overview/utils/UtilFunctions";

export type CampaignUsageProps={
  //children:React.ReactNode,
  //title?:string,
  values?:number[],
  //labels?:string[],
}

const CampaignUsage = ({data}:{data?:any}) => {
    //replace with data from hook
    let usage = [0,0]
    const usageLabels = ["Remaining Campaigns","Campaigns Used"]
    if(data){
      const usageArr = values_To_NumArray(data)
      usage = usageArr
    }
    return (  
        <CustomBarChart title="Campaign Usage" values={usage} labels={usageLabels}/>     
    ) 
}

export default CampaignUsage;
