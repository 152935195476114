"use client"
//import Link from "next/link"
import TabsWrapper from "@/components/ui/TabsWrapper"
import { NotificationsForm } from "./Notifications/NotificationsForm"
import { UserDetailsForm } from "./UserDetails/UserDetailsForm"
//import { AddUsers } from "./AddUsers/AddUsers"
import { UserList } from "./ManageUsers/UserList"
import { AddUser } from "./ManageUsers/AddUser"
import BillingInfoForm from "./Billing/BillingInfoForm"
import { HrUser } from "openapi/api/model"
import { useGetUserHiringresourceAccount } from "../../../openapi/api/endpoints/default/default"
//import { useGetUserHiringresource } from "openapi/api/endpoints/default/default"

export function ProfileForm({user}:{user?:HrUser}) {
  //const { data: databcknd, isSuccess: isGetUserSuccess } = useGetUserHiringresource()
  //console.log(databcknd)
  const {data, isSuccess} = useGetUserHiringresourceAccount()
  const accountUser = data?.find((account)=>{return(account.user?._id===user?._id)})
  
  let permissionContent = <div></div>
  if(isSuccess){
    if(accountUser?.isAdmin===true){
      permissionContent = 
        <div className="space-y-8">
          <AddUser/>
          <UserList/>
        </div>
    } 
  }

  return (
    <div className="flex flex-col space-y-8 p-4">
      <TabsWrapper
        tabsComponents={[
          {
            tabsTitle:"USER DETAILS",
            Component: <UserDetailsForm/>
          },
          {
            tabsTitle:"BILLING INFO",
            Component: 
              <div className="flex h-[400px] w-full bg-white border border-primary100 rounded-md">
                <div className="grid grid-rows-2">
                      <BillingInfoForm/>
                </div>
              </div>

          },
          {
            tabsTitle:"NOTIFICATIONS",
            Component: <NotificationsForm/>
          }
        ]}
        defaultValue="USER DETAILS"
      />
      {permissionContent}
    </div>
  )
}