//import React from 'react'
import { DataTableProps, useTable } from '../useTable'
import TableComponent from '@/components/ui/custom/Table/TableComponent'
import HCToolBar from './HCToolBar'

function HiringCampaignsTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const {table,setColumnFilters}=useTable({columns,data})
  return (
    <div>
      <HCToolBar setColumnFilters={setColumnFilters} table={table}/>
      <TableComponent columns={columns} table={table}/>
    </div>
    
  )
}

export default HiringCampaignsTable