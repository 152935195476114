import logo from '../../../assets/logo2.png'
type LandingPageProps={
    sideImage:React.ReactNode,
    LandingPageComponent:() => JSX.Element
}

export const LandingPage = ({sideImage,LandingPageComponent}:LandingPageProps) => {
  return (
    <div className="flex flex-col h-dvh bg-white overflow-hidden">
        <div className="flex flex-col bg-blue100">
            <img src={logo} className="h-[100px] w-[300px] items-center rounded-lg p-4"/>
        </div>
            <div className="grid grid-cols-2" style={{height: "calc(100dvh - 100px)"}}>
                <div className="" style={{height: "calc(100dvh - 100px)"}}>
                   {sideImage}
                </div>
                <div className="col-start-2 justify-self-center content-center">
                    <div className="">
                        <div className="bg-white">
                            <LandingPageComponent/>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
