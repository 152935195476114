import CustomBarChart from "../../components/ui/custom/Charts/CustomBarChart";
import { values_To_NumArray } from "../Overview/utils/UtilFunctions";
/* 
export type AverageScoresProps={
  values?:number[],
} */

const AverageScores = ({dataAvg,dataMax}:{dataAvg?:any,dataMax?:any}) => {
  
    let avgScores = [0,0,0,0,0]
    const avgScoresLabels = ["Code Excellence","Precision","Simplicity","Uniqueness","Documentation"]

    if(dataAvg){
      const avgScoresArr = values_To_NumArray(dataAvg)
      avgScores = avgScoresArr
    }
    
    return (  
        <CustomBarChart title="Evaluation Results" values={avgScores} maxValues={dataMax} labels={avgScoresLabels}/>     
    ) 
}

export default AverageScores;
