import MacroCard from "./Components/MacroCard";
import { metricInfo } from "./utils/Text";
import PieChartWithCustomizedLabel from "@/components/ui/PieChart";

export type DocumentationClarityProps={
    documentationClarity:any
}

const DocumentationClarity = ({data}:{data?:any}) => {
    let chartData={
        documented:0,undocumented:0
    }
  
    let empty = false
    if(data){
      empty = ((data.documented===null &&data.undocumented===null)  ||
      (data.documented===0 && data.undocumented===0) )
      chartData = data
      console.log(empty)
    }

    return ( 
        <>
           {empty && 
            <MacroCard 
                title={"Documentation Clarity"} 
                cardContentStyle={"m-2 p-2"} 
                definition={metricInfo.DocumentationClarity.definition} 
                benefit={metricInfo.DocumentationClarity.benefit}>
                <div>No data</div>
            </MacroCard>}
            {!empty && 
            <MacroCard 
                title={"Documentation Clarity"} 
                cardContentStyle={"m-2 p-2"} 
                definition={metricInfo.DocumentationClarity.definition} 
                benefit={metricInfo.DocumentationClarity.benefit}>
                <PieChartWithCustomizedLabel chart={chartData}/>
            </MacroCard>}
            
        </> 
    ) 
}

export default DocumentationClarity;
