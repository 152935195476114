import { Button } from "@/components/ui/button"
import {
  //Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
//import PressableButton from "../ui/pressableButton"
import { LinearProgress } from "@mui/material"


export type AuthCardProps = {
  children: React.ReactNode,
  onSubmit: () => void,
  cardTitle: string,
  cardDescription?: string,
  buttonText?:string,
  loading?:boolean
}
export function AuthCard({
  children,
  onSubmit,
  cardTitle,
  cardDescription,
  buttonText,
  loading}:AuthCardProps) {


  return (
    <div className="w-[400px] bg-white border-none">
      <CardHeader>
        <CardTitle className="text-2xl text-center font-serif text-primary200">{cardTitle}</CardTitle> 
        <CardDescription className="text-center font-medium text-primary200">{cardDescription}</CardDescription>
      </CardHeader>
      <CardContent className="px-12">
        {children}
      </CardContent>
        <div>
          {loading && <LinearProgress/> }
        </div>
      <CardFooter className="flex justify-center">
        {/* <Button variant="outline">Cancel</Button> */}
        <Button onClick={onSubmit} className="rounded-full bg-primary200 px-16">{buttonText}</Button>
      </CardFooter>
      
    </div>
  )
}
