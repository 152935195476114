import * as z from "zod"

export const profileFormSchema = z.object({
    userName: z
      .string()
      .min(2, {
        message: "Username must be at least 2 characters.",
      })
      .max(30, {
        message: "Username must not be longer than 30 characters.",
      }),
    email: z
      .string({
        required_error: "Please enter an email.",
      })
      .email(),
    firstName:z.string().min(1, {
      message: 'First name must be at least 1 character long'
    }),
    lastName:z.string().min(1, {
      message: 'Last name must be at least 1 character long'
    }),
    phone:z.string()
      .min(10, {
        message: 'Phone number must be 10 characters long'
      }),
    company:z.string().min(2, 'Please enter your company name'),
    position: z.string(),
  })

export type ProfileFormValues = z.infer<typeof profileFormSchema>