import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
//import { Header } from "@/components/ui/Title"
import { Route } from "@tanstack/react-router";
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { manageAccessToken } from "@/utils/Auth/Session";
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login";
import HiringCampaignsTable from "@/components/Tables/HiringCampaigns/HiringCampaignsTable";
import { HC_Columns } from "@/components/Tables/HiringCampaigns/HC_Column";
//import { hiringProcesses } from "../EntrepriseOverview/utils/MockData"
import CampaignStatus from "./CampaignStatus";
import CampaignUsage from "./CampaignUsage";
import HeaderComponent from "@/components/Header/Header";
import {
  useGetHiringcampaignsSearch,
  useGetLicensesId,
  useGetUserHiringresource,
  useGetUserHiringresourceAccount,
} from "../../../openapi/api/endpoints/default/default";
import {
  AllCampaignDetailResponse,
  AllCampaignTableResponse,
  HrUser,
} from "openapi/api/model";
import { HiringProcessData } from "../EntrepriseOverview/utils/MockData";
import { RotateCcw } from "lucide-react";
import { NewCampaignButton } from "./NewCampaignButton";

const AllCampaigns = () => {
  const { data: user } = useGetUserHiringresource();
  const userData = user as HrUser;
  const { data: license }=useGetLicensesId(userData.licenseId)
  const { data:users } = useGetUserHiringresourceAccount()
  const write = users?.find((usr)=>usr.user?._id===user?._id)?.permissions[0].permissions.write
  const { data: HCdetail, refetch: refetchDetail } = useGetHiringcampaignsSearch({ 
    HR_format: "AllCampaignDetail",
    licenseId:userData?.licenseId??'' 
  },
  {
    query:{
      enabled: !!userData && !!userData.licenseId
    }
  }
);
  const detail = HCdetail as AllCampaignDetailResponse;

  const { data: HCTable, isSuccess, refetch: refetchTable } = useGetHiringcampaignsSearch({ 
    HR_format: "AllCampaignTable",
    licenseId:userData?.licenseId??'' 
  },
  {
    query:{
      enabled: !!userData && !!userData.licenseId
    }
  }
);

  const hc = HCTable as AllCampaignTableResponse;
  const table = hc as HiringProcessData[];
  console.log("Table: ", table);
  const draftCount = hc?.filter(
    (hiringCampaign) => hiringCampaign.statusUI == "draft"
  ).length;
  const liveCount = hc?.filter(
    (hiringCampaign) => hiringCampaign.statusUI == "inProgress"
  ).length;
  const completeCount = hc?.filter(
    (hiringCampaign) => hiringCampaign.statusUI == "completed"
  ).length;
  const status = {
    pending: draftCount,
    live: liveCount,
    complete: completeCount,
  };
  const usage = {
    remaining: detail?.remaining_campaigns??license?.limits.hiringcampaigns,
    used: detail?.campaigns_used,
  };

  const onReload = () => {
    refetchDetail();
    refetchTable();
  };

  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
      <div className="sm:ml-48">
        <HeaderComponent title="Campaigns" component={<NewCampaignButton disabled={!write} />} />
      </div>

      <div className="p-4">
        <Card className="bg-white">
          <CardHeader className="">
            <div className="grid grid-cols-2 gap-4">
              <CampaignStatus data={status} />
              <CampaignUsage data={usage} />
            </div>
          </CardHeader>
          <CardContent className="py-2">
            <CardDescription>
              Browse all your hiring campaigns
              <button className="pl-2" onClick={onReload}>
                <RotateCcw size={12} />
              </button>
            </CardDescription>
            {isSuccess && (
              <HiringCampaignsTable columns={HC_Columns} data={hc} />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const AllCampaignsRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/allCampaigns",
  component: AllCampaigns,

  beforeLoad: async ({ context: { auth, queryClient } }) => {
    const session = await manageAccessToken({ AuthPayload: auth });
    if (!session) {
      //no token means that token is still good
      return;
    }
    const newAuthPayload = {
      ...auth,
      session,
    } as AuthenticateUserResp;
    queryClient.setQueryData(["Auth"], newAuthPayload);
    return {
      auth: newAuthPayload,
    };
  },
});

export default AllCampaignsRoute;
