/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form';
import { useContext, useRef, useState } from 'react';
import * as z from 'zod';
import { SignupSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from '@hookform/resolvers/zod';
import { confirmSignUp, resendCode } from '../AuthUtils/Signup';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CardWithNoFooter } from "@/components/ui/CardWithNoFooter";
import { AuthCard } from '@/components/Auth/AuthCard';
import FormElements from "@/components/ui/FormElements";
import { useQueryClient } from '@tanstack/react-query';
import { useCreateAccount,  useSignUp } from '@/utils/Auth/Session';
import { LinearProgress } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { Toaster, toast } from "sonner"
import { AuthContext } from '@/store/auth-context';
import { Permission } from '../../../../openapi/api/model/permission';
import { HrUser } from '../../../../openapi/api/model/hrUser';
import { AuthenticateUserResp } from '../AuthUtils/Login';
import useOnAuth from '@/customMutators/useOnAuth';

//if 

export type AddClientRedirect={
  permission?:Permission
}
export const SignUp = (redirect?:AddClientRedirect) => {

  const email=redirect?.permission?.email
  const {setConfirmationStage} = useContext(AuthContext)
  const { register, handleSubmit, formState: {errors}, getValues } = useForm<z.infer<typeof SignupSchema>>({
    resolver: zodResolver(SignupSchema),
    defaultValues: {
      email: email??"",
      password: "",
      confirmPassword: "",
    }});
  const navigate=useNavigate({from:'/'})
  const [signupCodeStatus,setSignupCodeStatus]=useState<'idle' | 'in progress'>('idle')
  const queryClient=useQueryClient()
  const permission=redirect?.permission
  const resp=queryClient.getQueryData(['Auth']) as AuthenticateUserResp
  const sub=resp?.session?.getIdToken()?.payload.sub as string
  const hrUser:HrUser={
      email:permission?.email??'',
      licenseId:permission?.licenseId??'',
      sub:sub,
      userName:getValues('userName'),
      //add additional fields here
      firstName:getValues('firstName'),
      lastName:getValues('lastName'),
      position:'Hiring Manager',
      company:getValues('company'),
      phone:'+254712345678'
  }

  const {hrRefetch}=useOnAuth({onAuthSuccess:()=>{
    navigate({to:'/profile'})
  }})
   
  
  const {createAccount,errors:createAccountErrs}=useCreateAccount({
    queryClient,
    redirect,
    HrUser:hrUser,
    onSuccess:hrRefetch
  })
   
  const {mutate:SignUp,isSuccess:signupSuccess,isPending:signUpLoading}=useSignUp({
    onFailureFunc:(error)=>{
      if(!redirect || !redirect.permission) return //flow specific to redirect
        console.log('redirecting and creating account')
        console.log(hrUser)
        console.log({error})
        createAccount({
          userName:getValues('userName'),
          password:getValues('password')
        })}
      })
  if(createAccountErrs){
    console.log({createAccountErrs})
  }

  

  const submitRef = useRef(null) as any
  const _triggerSubmit = () => {
    submitRef?.current?.click(); // Programmatically click the hidden submit button
  };
  const { register:confirmationCodeRegister, handleSubmit:confirmSubmit  } = useForm()

  const onSubmit = async (data:any) => {
    console.log(data)
    SignUp({
      name:data.userName,
      email:data.email,
      password:data.password,
      phone_number:'+254712345678',
     })
  };



  


  const resendConfirmationCode=()=>{
    const userName=getValues('userName')
    try{
      resendCode({userName:userName})
    }catch(e){
      toast('Try again later', {
        description: e as string,
        action: {
          label: "Dismiss",
          onClick: () => console.log("Dismiss"),
        },
      });
    }
    
  }


  const confirmEmail = async (data:any) => {
    const userName=getValues('userName');
    const password=getValues('password');
    setSignupCodeStatus('in progress')
    try{
      await confirmSignUp({
          code:data.confirmationCode,
          userName
        })
      setSignupCodeStatus('idle')
      createAccount({userName,password})
    }catch(e){
      toast('Failed to authenticate', {
        description:'Wrong confirmation code, please try again',
        action: {
          label: "Dismiss",
          onClick: () => console.log("Dismiss"),
        },
      });
      return
    }

  
    

  }

  if(signupSuccess){
    setConfirmationStage(true)
    return (
      <>
      <CardWithNoFooter 
      cardTitle="Confirm e-mail"
      cardDescription="Enter the confirmation code sent to your email">
        <form onSubmit={confirmSubmit(confirmEmail)} className="flex flex-col space-y-1.5">
          <Input 
            id={'confirmEmail'} 
            type={'text'} 
            {...confirmationCodeRegister('confirmationCode')} 
            name={'confirmationCode'}
            className='border-primary100 border-2 rounded-xl text-center'/>
          <div className="min-h-40">
          {signupCodeStatus==='in progress' && <LinearProgress/> }
          </div>
          <Button type='submit' className="bg-primary200 rounded-full">Confirm</Button>
        </form>
        <Button
        variant={'link'}
        onClick={resendConfirmationCode}>
          Resend code
        </Button>
      </CardWithNoFooter>    
      <Toaster/>   
      </>

    )
  }


  return (
    <AuthCard 
    loading={signUpLoading}
    cardTitle="Let's create your account"
    buttonText='Continue'
    //cardDescription="Enter your email and password to create an account"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row space-x-2 py-3">
          <Input 
            id="firstName" 
            type="text" 
            {...register("firstName")} 
            name="firstName"
            placeholder="First Name"
            className='border-primary100 border-2 rounded-xl text-center' />
          <Input 
            id="lastName" 
            type="text" 
            {...register("lastName")} 
            name="lastName"
            placeholder="Last Name"
            className='border-primary100 border-2 rounded-xl text-center' />
        </div>
        
        <FormElements
        errors={errors}
        formMembers={[
          {id:"userName",label:"Username",type:"text",register,name:"userName"},
          //{id:"phone",label:"Phone Number",type:"tel",register,name:"phone"},
          {id:"company",label:"Company",type:"text",register,name:"company"},
          {id:"email",label:"Email",type:"email",register,name:"email",readOnly:!!email},
          {id:"password",label:"Password",type:"password",register,name:"password"},
          {id:"confirmPassword",label:"Confirm Password",type:"password",register, name:"confirmPassword"}
      ]}/>
        <p className="text-2xs text-center text-primary200 mt-2">
          By clicking Continue, you agree to the {' '} 
          <button className="underline">Terms of Service</button> and {' '}  
          <button className="underline">Privacy Policy</button>
        </p>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </AuthCard>
  )


}

