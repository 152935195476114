import { BarChart } from '@mui/x-charts/BarChart';
import { ResponsiveContainer } from 'recharts';

export type MetricData={
  //refactor this
    name:string,
    total:number
}

export type BasicChartProps = {
  data?:number[],
  labels?:string[],
  height?:number,
  width?:number
}

export default function BasicChart({data,labels,height,width}:BasicChartProps) {
  //const sizingProps = { width: 400, height: 200 };
  const h=height??250
  const w=width??110
  const uData = data??[0]

  const xLabels = labels??[""] 
  
  return (
    <ResponsiveContainer width={`${w}%`} height={h}>
      <BarChart
      //dataset={barData}
      xAxis={[{ 
        scaleType: 'band', 
        data: xLabels,
      }]}
      series={[
        { data: uData, },
      ]}
      width={200}
      height={200}
      //{...chartSetting}
    />
    </ResponsiveContainer>
  );
}