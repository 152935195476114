//"use client"
//import Link from "next/link"
import { Button } from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";
//import { PlusCircle } from 'lucide-react';
//import { useState } from "react";

export function NewCampaignButton({disabled}:{disabled?:boolean}) {
  const navigate = useNavigate({ from: "/" });

  const onClick = () => {
    navigate({ to: "/newCampaign" });
  };

  return (
    <div className="flex flex-col space-y-1">
      <Button
        onClick={onClick}
        className="flex h-12 bg-white text-black text-sm hover:bg-gray-100 space-x-4"
        disabled={disabled}
      >
        <p>+ Create New Campaign</p>
      </Button>
    </div>
  );
}
