import MacroCard from "./Components/MacroCard";
import { metricInfo } from "./utils/Text";
import BasicBarChart from "@/components/ui/MuiBarChart";
import { values_To_NumArray } from "./utils/UtilFunctions";

export type CodingPrecisionProps={
    codingPrecision:Object
}

const CodingPrecision = ({data}:{data?:any}) => {

    //const codingPrecision=values_To_NumArray(data)
    //console.log("CP: ",codingPrecisionArr);

    let codingPrecision=[0,0,0,0,0]
    let maxCodingPrecision=[0,0,0,0,0]
    const labels=["Blockers","Critical","Major","Minor","Info"]

    if(data){
        const codingPrecisionArr = values_To_NumArray(data[0])
        codingPrecision = codingPrecisionArr
        console.log("Simplicity: ",codingPrecision)
        const maxCodingPrecisionArr = values_To_NumArray(data[1])
        maxCodingPrecision = maxCodingPrecisionArr
    }

    return (  
        <MacroCard 
            title={"Coding Precision"} 
            cardContentStyle={"p-2"} 
            definition={metricInfo.CodingPrecision.definition} 
            benefit={metricInfo.CodingPrecision.benefit}>
            { data? <BasicBarChart data={codingPrecision} maxData={maxCodingPrecision} labels={labels} avgLabel="Candidates Score"/> : <h1>No data to be displayed</h1> }
        </MacroCard>
    ) 
}

export default CodingPrecision;
