import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { ResponsiveContainer } from 'recharts';

export type OverallScoresGaugeProps={
    width?: number,
    height?: number,
    value?: number,
    minValue?: number,
    maxValue?: number,
    //status?: string,
  }

export default function OverallScoresGauge({width,height,value,minValue,maxValue}:OverallScoresGaugeProps) {
    const w= width??75
    const h= height??180
    
    const settings = {
        // width: 280,
        // height: 200,
        value: value,
    };
    return (
      <><ResponsiveContainer width={`${w}%`} height={h}>
            <div className='flex h-full my-4'>
                <Gauge
                    {...settings}
                    startAngle={-95}
                    endAngle={95}
                    cornerRadius="50%"
                    sx={() => ({
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 0,
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#26A69A',
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                            fill: '#ccfbf1',
                        },
                    })} />


            </div>

        </ResponsiveContainer>
        <div className='grid grid-rows-1 w-full justify-items-center'>
        <div className='grid grid-cols-3 justify-items-center w-8/12'>
            <div className='text-3xl italic'>{(minValue ?? 0).toFixed()}</div>
            <div className='text-3xl italic'>{(value ?? 0).toFixed()}</div>
            <div className='text-3xl italic'>{(maxValue ?? 0).toFixed()}</div>
        </div>
        <div className='grid grid-cols-3 justify-items-center w-9/12'>
        <p className=' font- m-1 text-xs'>Lowest Score</p>
        <p className=' font- m-1 text-xs'>Median Score</p>
        <p className=' font- m-1 text-xs'>Highest Score</p>
        </div>
        </div>
        </>
  );
}