//import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { Button } from "@/components/ui/button"
import { useEffect, useState } from "react"
import { HrUser } from "openapi/api/model"
import { useGetUserHiringresource } from "../../../openapi/api/endpoints/default/default"
import FormElements from "@/components/ui/FormElements"

const LicenseFormSchema = z.object({
  companyName: z
    .string()
    .min(0, {
      message: "Position must be at least 10 characters.",
    })
    .max(50, {
      message: "Position must not be longer than 30 characters.",
    }),
})
type LicenseFormValues = z.infer<typeof LicenseFormSchema>
// This can come from your database or API.
/* const defaultValues: Partial<HiringCampaignFormValues> = {
  position: "",
  positionDescription: "",
  experienceLevel: "",
  skills: [],
} */

export function LicenseForm() {
  const [showPopup, setShowPopup] = useState(false);
  const{data:user}=useGetUserHiringresource();
  const userData=user as HrUser
  console.log("UserData: ", userData)
  console.log("UserData: ", userData?.licenseId)
  
  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      //companyId: string;
      companyName: "",
      hiringCampaignid: [],
      //HrUserid: [],
      limits: {
        hiringcampaigns: "",
        user: ""
      },
      //sub: string;
    }}
  )

  //const {mutate, isSuccess}=usePostHiringcampaigns();

  // UseEffect hook to hide the popup after 5 seconds
  
    useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);

      // Clear the timer when the component unmounts or when showPopup changes
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  const onSubmit=(data: any)=>{
    const submittedData=data as LicenseFormValues
    console.log("Submitted!!", submittedData)
    /* const campaignInfo:HiringCampaign ={
      sub:user?.sub as string,
      position:submittedData.position,
      companyId:user?.companyId as string,
    }
    console.log('Info Submitted:  ',campaignInfo);
    mutate({data:campaignInfo})
    if (isSuccess){
      setShowPopup(true);
    }
 */
    
    /* toast({
      title: "You've submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data)}</code>
        </pre>
      ),
    })  */
  }

  return (
    <>
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <FormElements
          errors={errors}
          formMembers={[
            {id:"companyName",label:"Company Name:",type:"text",register,name:"companyName"},
            //{id:"experienceLevel",label:"Experience Level:",type:"text",register,name:"experienceLevel"},
          ]}
        />
        <Button asChild>
          <input type="submit" value={'Submit'}/>
        </Button>
      </form>
      {showPopup && (
        <div className=" flex popup items-center justify-center place-items-center">
          <p>Your campaign has been created!</p>
          <button onClick={() => setShowPopup(false)}></button>
        </div>
        )
      }
    </>
  )
}