import { rootRoute } from "@/App"
import { Route } from "@tanstack/react-router"
import {SignUp} from "./AuthComponents/SignUp"
import { Login } from "./AuthComponents/Login"
import { useContext, useState } from "react"
import logo from "../../assets/logo2.png"
import login from "../../assets/login.png"
import signup from "../../assets/signup.png"
import { AuthContext } from "@/store/auth-context"
import { ForgotPassword } from "./AuthComponents/PasswordReset"

const Authentication = () => {
    //const [confirmationStage, setConfirmationStage] = useState(false);
    const [signUp, setSignUp] = useState(false)
    const [pwdReset, setPwdReset] = useState(false)
    const {confirmationStage} = useContext(AuthContext)

  return (
    <div className="flex flex-col h-dvh bg-white overflow-hidden">
        <div className="flex flex-col bg-blue100">
            <img src={logo} className="h-[100px] w-[300px] items-center rounded-lg p-4"/>
        </div>
        {pwdReset? 
            <div className="flex flex-col justify-center items-center" style={{height: "calc(100dvh - 100px)"}}>
                <ForgotPassword/>
                <p className="flex text-2xs text-center text-primary200">
                    Don't have an account yet? 
                    <button 
                        className="text-2xs underline" 
                        onClick={
                            ()=>{
                                setSignUp(true)
                                setPwdReset(false)
                            }
                        }>Sign up</button>
                </p>
            </div>
            :
            <div className="grid grid-cols-2" style={{height: "calc(100dvh - 100px)"}}>
                <div className="" style={{height: "calc(100dvh - 100px)"}}>
                    {!signUp? <img src={login} className="h-full w-full"/>:<img src={signup} className="h-full w-full"/>}
                </div>
                <div className="col-start-2 justify-self-center content-center">
                    <div className="">
                        {!signUp? 
                            <div className="bg-white">
                                <Login/>
                                <p className="text-2xs text-center text-primary200">
                                    Don't have an account yet? <button className="text-2xs underline" onClick={()=>setSignUp(true)}>Sign up</button>
                                </p>
                                <p className="text-xs text-center text-primary200 mt-2">
                                    <button className="text-2xs underline" onClick={()=>setPwdReset(true)}>Forgot Password?</button>
                                </p>
                            </div>
                            :
                            <div className="bg-white">
                                <SignUp/>
                                {!confirmationStage && <p className="text-2xs text-center text-primary200">
                                    Already have an account? <button className="text-2xs underline" onClick={()=>setSignUp(false)}>Log in</button>
                                </p>}
                            </div>
                        }
                    </div>
                </div>
            </div>}
    </div>
  )
}

const AuthRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/auth",
    component: Authentication,
})
export default AuthRoute