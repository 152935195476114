import { Route } from '@tanstack/react-router';
//import { rootRoute } from "../../App"
import { manageAccessToken } from '@/utils/Auth/Session';
import { AuthenticateUserResp } from '../Authentication/AuthUtils/Login';
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Card, CardContent } from '@/components/ui/card';
//import { Header } from '@/components/ui/Title';
import StatusCard from '../EntrepriseOverview/components/StatusCard';
import HeaderComponent from '@/components/Header/Header';

const Dashboard=()=>{
  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
        <HeaderComponent title="Dashboard"/>
        <div className="flex flex-col m-4">
            <Card className="border-none bg-white">
                <CardContent className="p-4">
                    <div className="grid grid-cols-6 justify-center basis-1/6 border-gray-500 m-8">
                        <div className="col-start-2">
                            <StatusCard title="Pending Campaigns" value={5}/>
                        </div>
                        <StatusCard title="Live Campaigns" value={35}/>
                        <StatusCard title="Complete Campaigns" value={0}/>
                        <StatusCard title="Avg. Days To Close" value={6}/>
                    </div>
                </CardContent>
            </Card>
        </div>
    </div>
  )
}

const DashboardRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "/dashboard",
    component: Dashboard,

    beforeLoad: async ({ context: { auth, queryClient } }) => {
        const session = await manageAccessToken({ AuthPayload: auth });
        if (!session) return;
    
        const newAuthPayload = { ...auth, session } as AuthenticateUserResp;
        queryClient.setQueryData(['Auth'], newAuthPayload);
        return {
          auth: newAuthPayload
        }
      },
    })

export default DashboardRoute;