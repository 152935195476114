import { BarChart } from '@mui/x-charts/BarChart';
import { ResponsiveContainer } from 'recharts';

export type MetricData={
  //refactor this
    name:string,
    total:number
}

export type BasicBarChartProps = {
  data?:number[],
  maxData?:number[],
  labels:string[],
  avgLabel?:string,
  height?:number,
  width?:number
}

export default function BasicBarChart({data,maxData,labels,avgLabel,height,width}:BasicBarChartProps) {
  //const sizingProps = { width: 400, height: 200 };
  const h=height??250
  const w=width??110
  const uData = data??[0]
  const mData = maxData??[0]
  let chartMaxData = []
  const xLabels = labels??[""] 
  for(let i=0; i < mData.length; i++){
    if(mData[i] > uData[i]){
      chartMaxData[i] = mData[i] - uData[i]
    }else{
      chartMaxData[i] = 0
    }
  } 
  /* const barchartData = {
    tooltipData:{
      min:
      max:
    },
    chartData:{
      min:
      max:
    }
  } */
  return (
    <ResponsiveContainer width={`${w}%`} height={h}>
      <BarChart
      //dataset={barData}
      xAxis={[{ 
        scaleType: 'band', 
        data: xLabels,
      }]}
      series={[
        { data: uData, label: avgLabel??'Average', stack: 'total' },
        { data: chartMaxData, label: 'Highest Score', stack: 'total', color: '#ccfbf1'}
      ]}
      width={200}
      height={200}
      tooltip={{trigger:"item"}}
      slots={{
        itemContent: (content)=>{
          return (
            <div className='grid grid-rows-3 bg-white border rounded h-32 w-56'>
              <div className='flex justify-center items-center mx-0 text-primary100 underline'>
                {labels[content.itemData.dataIndex??0]}
              </div>
              <div className='flex mx-2 space-x-4 items-center'>
                <div className='flex items-center border border-teal-400 rounded-full bg-teal-400 h-2 w-2'></div>
                <p className='text-primary100'>{(avgLabel as string)??`Average:`}</p>
                <p>{(uData[content.itemData.dataIndex??0]).toFixed()}</p>
              </div>
              <div className='flex mx-2 space-x-4 items-center'>
                <div className='flex border border-teal-100 rounded-full bg-teal-100 h-2 w-2'></div>
                <p className='text-primary100'>Highest Score:</p>
                <p>{(mData[content.itemData.dataIndex??0]).toFixed()}</p>
              </div>
            </div>)
            }
      }}
      //{...chartSetting}
    />
    
    </ResponsiveContainer>
  );
}