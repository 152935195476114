"use client"
//import Link from "next/link"
//import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  //FormDescription,
  FormField,
  FormItem,
  FormLabel,
  //FormMessage,
} from "@/components/ui/form"
//import { Input } from "@/components/ui/input"
import { toast } from "sonner"
import { usePutUserHiringresource } from '../../../../openapi/api/endpoints/default/default';
//import { useEffect } from 'react';
//import { HrUser } from "openapi/api/model"
//import Wrapper from "./Wrapper"
//import TabsWrapper from "@/components/ui/TabsWrapper"
import { Switch } from "@/components/ui/switch"


export function NotificationsForm() {
  /* const { data: databcknd, isSuccess: isGetUserSuccess } = useGetUserHiringresource() */

  const {isSuccess}=usePutUserHiringresource();
  const form = useForm<any>({
    //resolver: zodResolver(profileFormSchema),
  })

  const onSubmit=(data: any)=>{
    console.log("Notifications: ",data)
  }

  if(isSuccess){
    toast("Updated!",{
      description: 'Profile updated successfully',
      action: {
        label: "Dismiss",
        onClick: () => {},
      },
    })      
  }

    
    /* useEffect(() => {
      if (isGetUserSuccess && databcknd) {//only rerender if these conditions are satisfied
        form.reset(databcknd);
      }
    }, [form, databcknd, isGetUserSuccess]); // Only re-run if these dependencies change    
     */
   /*
    if (isGetUserSuccess && databcknd) {//wont work as the body will be evaluated on every rerender, and we trigger a rerender here
      //which reevaluates the expression, which causes another rerender -> an infinite loop
      form.reset(databcknd);
    }   
   */

  return (
    <div className="flex h-[400px] w-full  bg-white border border-primary100 rounded-md">
        <div className="flex w-full p-2">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col w-full space-y-2">
                    <FormLabel className="text-base">Notify me when:</FormLabel>
                    <FormField
                    control={form.control}
                    name="isNew"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between p-2">
                        <div className="space-y-0.5">
                            <FormLabel className="text-base">A new campaign is created</FormLabel>
                        </div>
                        <FormControl>
                            <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled
                            />
                        </FormControl>
                        </FormItem>
                    )}
                    />
                    <FormField
                    control={form.control}
                    name="isClosed"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between p-2">
                        <div className="space-y-0.5">
                            <FormLabel className="text-base">A campaign is closed</FormLabel>
                            {/* <FormDescription>
                                Receive emails about your account security.
                            </FormDescription> */}
                        </div>
                        <FormControl>
                            <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled
                            aria-readonly
                            />
                        </FormControl>
                        </FormItem>
                    )}
                    />
                    <FormField
                    control={form.control}
                    name="evalCompleted"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between p-2">
                        <div className="space-y-0.5">
                            <FormLabel className="text-base">An evaluation is completed</FormLabel>
                        </div>
                        <FormControl>
                            <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled
                            />
                        </FormControl>
                        </FormItem>
                    )}
                    />
                    <FormField
                    control={form.control}
                    name="isUsed"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between p-2">
                        <div className="space-y-0.5">
                            <FormLabel className="text-base">All campaigns have been used</FormLabel>
                        </div>
                        <FormControl>
                            <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled
                            //aria-readonly
                            />
                        </FormControl>
                        </FormItem>
                    )}
                    />
                    <FormField
                    control={form.control}
                    name="evalsUsed"
                    render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between p-2">
                        <div className="space-y-0.5">
                            <FormLabel className="text-base">All evaluations have been used</FormLabel>
                        </div>
                        <FormControl>
                            <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled
                            />
                        </FormControl>
                        </FormItem>
                    )}/>
                    <Button type="submit" className="flex w-16 self-center bg-mint100 text-black hover:bg-teal100">Save</Button>
                </form>
            </Form>
        </div>
    </div>
  )
}