import React from "react";

const SmallText = ({children, style, textStyle}:{children:React.ReactNode, style?:string, textStyle?:string}) => {
    return (  
        <div className={`flex flex-row justify-center ${style}`}>
            <p className={`text-sm tracking-tight break-words text-wrap ${textStyle}`}>{children}</p>
        </div>
    ) 
}

export default SmallText;
