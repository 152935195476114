import { CardTitle, Card, CardHeader, CardContent } from "@/components/ui/card";
import { TitleSmall } from "@/components/ui/Title";
import InfoCard from "./InfoCard";

export type MacroProps={
  metric?:number,
  title?:string,
  definition?:string,
  benefit?:string,
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardContentStyle?:string
}

const MicroCard = ({
  metric,
  title,
  definition,
  benefit,
  cardStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardContentStyle
}:MacroProps) => {
    return ( 
      <div className="  ">         
        <Card className={` m-4 p-4 bg-white border-none ${cardStyle}`}>
          <CardHeader className={`h-1/3 pt-2 pl-2 ${cardHeaderStyle}`}>
            <CardTitle className={`text-center ${cardTitleStyle}`}>
              <InfoCard definition={definition} benefit={benefit}>
                <TitleSmall>{title}</TitleSmall>
              </InfoCard>
            </CardTitle>
            {/* <CardDescription className={`${cardDescriptionStyle}`}>
              <p className="truncate">{description}</p>
            </CardDescription> */}
          </CardHeader>
          <CardContent className={`flex pt-2 justify-center items-center ${cardContentStyle}`}>
              <h1 className="text-3xl font-bold truncate">{metric}/10</h1>
          </CardContent>
        </Card>
      </div>
    ) 
}

export default MicroCard;
