import { CardTitle, Card, CardHeader, CardContent } from "@/components/ui/card";
import { Title } from "@/components/ui/Title";
import HalfGauge from "../../HalfGauge";
import OverallScoresGauge from "@/pages/EntrepriseOverview/components/OverallScoresGauge";

export type CustomHalfGaugeProps={
  //children:React.ReactNode,
  title?:string,
  //status?:string,
  value?:number,
  minValue?:number,
  maxValue?:number,
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardDescriptionStyle?:string,
  cardContentStyle?:string
}

const CustomHalfGauge = ({
  title,
  value,
  minValue,
  maxValue,
  cardStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardContentStyle
}:CustomHalfGaugeProps) => {

    return (  
    <div className="flex flex-col p-0">
        <Card className={`flex flex-col m-4 p-4 bg-white ${cardStyle}`}>
            <CardHeader className={`h-1/4 pt-2 pl-2 ${cardHeaderStyle}`}>
              <CardTitle className={`${cardTitleStyle}`}>
                <Title>{title}</Title>
              </CardTitle>
            </CardHeader>
            <CardContent className={`flex flex-col justify-center items-center space-y-4 justify-items-center ${cardContentStyle}`}>
              {(minValue||maxValue)?  <OverallScoresGauge value={value} minValue={minValue} maxValue={maxValue}/>:<HalfGauge value={value} width={100}/>}
            </CardContent>
        </Card>
    </div>      
    
    ) 
}

export default CustomHalfGauge;
