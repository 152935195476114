import CustomBarChart from "../../components/ui/custom/Charts/CustomBarChart";
import { values_To_NumArray } from "../Overview/utils/UtilFunctions";

export type CampaignStatusProps={
  //children:React.ReactNode,
  //title?:string,
  values?:number[],
  //labels?:string[],
}

const CampaignStatus = ({data}:{data?:any}) => {
    let status = [0,0,0]
    const statusLabels = ["Draft","In Progress","Complete"]
    if(data){
      const statusArr = values_To_NumArray(data)
      status = statusArr
      console.log("Campaign Status: ",status)
    }
    return (  
      <CustomBarChart title="Campaign Status" values={status} labels={statusLabels}/>     
    ) 
}

export default CampaignStatus;
