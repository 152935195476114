//"use client"
//import Link from "next/link"
import { Button } from "@/components/ui/button";
import { PencilIcon } from 'lucide-react';
import { useState } from "react";
//import { NewUserForm } from "./NewUserForm";
import { EditUserForm } from "./EditUserForm";
import { AccountUsers } from "openapi/api/model";

export function EditUser({user,isUserSuccess}:{user?:AccountUsers,isUserSuccess:boolean}) {
    //console.log('User editing: ', user)
    const [showForm,setShowForm] = useState(false)
  

    return (
        <div className="flex flex-col space-y-1">
            <Button onClick={()=>setShowForm(!showForm)} className="flex flex-row justify-start h-16 bg-white text-left hover:bg-gray-300">
                <div className="flex flex-row items-center h-full w-11/12 space-x-2">
                    <p className="flex text-black text-destructive space-x-8">{user?.user?.userName} |</p><p className="flex text-gray-500 text-destructive">{user?.user?.position}</p>
                </div>
                <div className="flex flex-row items-center justify-center h-full w-1/12 space-x-2">
                    <p className="flex text-gray-500 text-2xl space-x-8">{!showForm && <PencilIcon/>}</p>
                </div>
            </Button>
            {showForm && <EditUserForm user={user} isUserSuccess={isUserSuccess}/>}
        </div>
    )
}