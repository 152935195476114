import { DataTableFacetedFilter } from '@/components/ui/custom/Table/filters/FacetedFilter'
import RangeFilter from '@/components/ui/custom/Table/filters/RangeFilter'
import SearchFilter from '@/components/ui/custom/Table/filters/SearchFilter'
import { CheckCircledIcon, Cross2Icon, Pencil1Icon } from '@radix-ui/react-icons'
import React, { useState } from 'react'
import { ColumnFiltersState, Table as TableType } from '@tanstack/react-table'
import { Button } from '@/components/ui/button';

export interface CandidatesTableToolbarProps<TData>{
    setColumnFilters:React.Dispatch<React.SetStateAction<ColumnFiltersState>>,
    table:TableType<TData> 
}
type FilterValue = [string | number, string | number];
type FiltersType = {
    overallSolutionFitness: FilterValue;
    codingPrecision: FilterValue;
    relativeComplexity: FilterValue;
    uniquenessOfCode: FilterValue;
    documentationClarity: FilterValue;
  };

function CandidatesTableToolbar<TData>({table,setColumnFilters}:CandidatesTableToolbarProps<TData>) {
    const isFiltered = table.getState().columnFilters.length > 0
    const filterActionHandler=(columnId: string, filterValue: [number | string, number | string]) => {
        setColumnFilters((columnFilters)=>[...columnFilters,{id:columnId,value:filterValue}])
    };
    
    const [filters, setFilters] = useState<FiltersType>({
        overallSolutionFitness: ['', ''],
        codingPrecision: ['', ''],
        relativeComplexity: ['', ''],
        uniquenessOfCode: ['', ''],
        documentationClarity: ['', ''],
      });
    
      const handleFilterChange = (columnId:string, value:[number | string, number | string]) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [columnId]: value,
        }));
        filterActionHandler(columnId, value);
      };
    
      const handleReset = () => {
        table.resetColumnFilters(true);
        setFilters({
          overallSolutionFitness: ['', ''],
          codingPrecision: ['', ''],
          relativeComplexity: ['', ''],
          uniquenessOfCode: ['', ''],
          documentationClarity: ['', ''],
        });
        // Object.keys(filters).forEach((key) => {
        //   filterActionHandler(key, ['', '']);
        // });
      };

  return (
    <div className="grid grid-cols-8 items-center space-x-2">
        <SearchFilter columnId="username" table={table} placeHolder='Name'/>
        <DataTableFacetedFilter 
            column={table.getColumn("status")}
            title={"Status"}
            options={[
            {
                label: 'Completed',
                value: 'Completed',
                icon: CheckCircledIcon
            },
            {
                label: 'In Progress',
                value: 'In Progress',
                icon: Pencil1Icon
            }
            ]}/>
        <RangeFilter
        valueName="Overall Score"
        onFilterChange={handleFilterChange}
        columnId="overallSolutionFitness"
        maxColumnValue={100}
        currentFilter={filters.overallSolutionFitness}
      />
      <RangeFilter
        valueName="Precision"
        onFilterChange={handleFilterChange}
        columnId="CodingPrecision"
        maxColumnValue={100}
        currentFilter={filters.codingPrecision}
      />
      <RangeFilter
        valueName="Simplicity"
        onFilterChange={handleFilterChange}
        columnId="RelativeComplexity"
        maxColumnValue={100}
        currentFilter={filters.relativeComplexity}
      />
      <RangeFilter
        valueName="Uniqueness"
        onFilterChange={handleFilterChange}
        columnId="uniquenessOfCode"
        maxColumnValue={100}
        currentFilter={filters.uniquenessOfCode}
      />
      <RangeFilter
        valueName="Documentation"
        onFilterChange={handleFilterChange}
        columnId="documentationClarity"
        maxColumnValue={100}
        currentFilter={filters.documentationClarity}
      />
      {isFiltered && (
        <Button
          variant="ghost"
          onClick={handleReset}
          className="h-8 px-2 lg:px-3"
        >
          Reset
          <Cross2Icon className="ml-2 h-4 w-4" />
        </Button>
      )}
    </div>
  )
}

export default CandidatesTableToolbar