import { CardTitle, Card, CardHeader, CardContent } from "@/components/ui/card";
import { Title } from "@/components/ui/Title";
import PieChartWithCustomizedLabel from "../../PieChart";

export type CustomPieChartProps={
  //children:React.ReactNode,
  title?:string,
  chart?:any,
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardDescriptionStyle?:string,
  cardContentStyle?:string
}

const CustomPieChart = ({
  title,
  chart,
  cardStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardContentStyle
}:CustomPieChartProps) => {

    return (  
    <div className="flex flex-col p-0 h-full">
        <Card className={`flex flex-col h-full m-4 p-4 bg-white ${cardStyle}`}>
            <CardHeader className={`h-1/4 pt-2 pl-2 ${cardHeaderStyle}`}>
              <CardTitle className={`${cardTitleStyle}`}>
                <Title>{title}</Title>
              </CardTitle>
            </CardHeader>
            <CardContent className={`grid grid-cols-1 h-full justify-center items-center space-y-4 justify-items-center ${cardContentStyle}`}>
              {chart? <PieChartWithCustomizedLabel chart={chart}/> : <div className='text-center text-black pt-4 m-4'>No data</div>}
            </CardContent>
        </Card>
    </div>      
    
    ) 
}

export default CustomPieChart;
