import { ButtonProps } from "@mui/joy/Button"
import { CardProps } from "@mui/joy/Card"
import { ChipProps } from "@mui/joy/Chip"
import { ListProps } from "@mui/joy/List"

export type PricingModel={
    type: string,
    name: string,
    details: string[],
    price: string,
    cardProps?: CardProps,
    chipProps?: ChipProps,
    listProps?: ListProps,
    buttonProps?: ButtonProps
}

export const basic: PricingModel = {
    type: 'Basic',
    name: 'Base Package',
    details: [
        'Up to 5 Concurrent HR pros',
        'Up to 1 Hiring Campaigns (Concurrent)',
        'Up to 50 devs per hiring campaign'
    ],
    price: '499$ / month',
    cardProps: {
        size:"lg", 
        variant:"outlined"
    },
    chipProps: {
        size:"sm", 
        variant:"outlined", 
        color:"neutral"
    },
    buttonProps: {
        variant:"soft",
        color:"neutral"
    }
}

export const HrProfessional: PricingModel = {
    type: 'Most Popular',
    name: 'HR Professional',
    details: [
        'Up to 15 users (hiring campaigns)',
        'Up to 3 Hiring Campaigns (Concurrent)',
        'Up to 100 devs per hiring campaign'
    ],
    price: '1399$ / month',
    cardProps: {
        size:"lg",
        variant:"solid",
        color:"neutral",
        invertedColors:true,
        sx:{ bgcolor: 'neutral.900' }
    },
    chipProps: {
        size:"sm",
        variant:"outlined"
    }
}

export const Entreprise: PricingModel = {
    type: 'Exclusive',
    name: 'Entreprise',
    details: [
        'Unlimited',
        'Unlimited',
        'Unlimited'
    ],
    price: 'Contact Us',
    cardProps: {
        size:"lg",
        variant:"soft",
        color:"neutral",
        invertedColors:true,
        sx:{ background: 'linear-gradient(to right bottom, #facc15, #fef08a, #eab308)' }
    },
    chipProps: {
        size:"sm",
        variant:"outlined"
    }
}

export const plans = [basic, HrProfessional, Entreprise]