import { ColumnDef, Row } from "@tanstack/react-table"
import { Button } from "@/components/ui/button"
import { CaretSortIcon } from "@radix-ui/react-icons"
//import { HiringProcessData } from '../../../pages/EntrepriseOverview/utils/MockData';
import { Link } from "@tanstack/react-router";
import { AllCampaignTableResponseItem } from "openapi/api/model";
import { capitalizeAndSplit, dateFormat } from "@/utils/Functions/Functions";

export const HC_Columns:ColumnDef<AllCampaignTableResponseItem>[]=[
    {
        accessorKey:'startDate',
        header:({column}) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Start Date
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            return <div className="text-center font-medium">{dateFormat(row.getValue("startDate"))}</div>
        }
    },
    {
        accessorKey:'position',
        header:({column}) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Role
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            const position = row.original
            return (
                <div className="text-center font-medium">
                    <Link
                        to={'/allCampaigns/$hiringCampaignId'}
                        from={'/allCampaigns'}
                        className="underline text-blue-500 hover:text-blue-800"
                        params={{hiringCampaignId:position._id}}>
                        {row.getValue("position")}
                    </Link>
                </div>
            )
        }
    },
    {
        accessorKey:'statusUI',
        cell:({row})=>{
            return <div className="text-center font-medium">{capitalizeAndSplit(row.getValue("statusUI"))}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Status
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          },
        filterFn:(row: Row<AllCampaignTableResponseItem>, columnId: string, filterValues: string[]) =>{
            return filterValues.includes(row.getValue(columnId))
        }
    },
    {
        accessorKey:'companyName',
        cell:({row})=>{
            const position = row.original
            return (
                <div className="text-center font-medium">
                    <Link
                        to={'/allCampaigns/$hiringCampaignId'}
                        from={'/allCampaigns'}
                        className="underline text-blue-500 hover:text-blue-800"
                        params={{hiringCampaignId:position._id}}>
                        {row.getValue("companyName")}
                    </Link>
                </div>
            )
            //return <div className="text-center font-medium">{row.getValue("companyName")}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Company
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        accessorKey:'numberApplicants',
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("numberApplicants")}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        # of Candidates
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          },
        
    },
    /* {
        accessorKey:'percentageCompleted',
        header:({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Completion %
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("percentageCompleted")}</div>
        },
        filterFn:'inNumberRange'
    }, */
    /* {
        accessorKey:'metScoreCriteria',
        header:({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        # Met min. score
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("metScoreCriteria")}</div>
        },
        filterFn:'inNumberRange'
    }, */
    {
        accessorKey:'daysLeft',
        header:({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Days Left
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("daysLeft")}</div>
        },
        filterFn:'inNumberRange'
    },
    /* {
        accessorKey:'username',
        header:({column}) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Created By
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            const username = row.original
            return (
                <div className="text-center font-medium">
                    <Link
                        to={'/allCampaigns/$hiringCampaignId'}
                        from={'/allCampaigns'}
                        className="underline text-blue-500 hover:text-blue-800"
                        params={{hiringCampaignId:username}}>
                        {row.getValue("username")}
                    </Link>
                </div>)
        }
    }, */
    
]