"use client"

import { useState, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card"
import { BriefcaseIcon, CodeIcon, ChevronDownIcon, XIcon, ClockIcon } from "lucide-react"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Badge } from "@/components/ui/badge"
import { ScrollArea } from "@/components/ui/scroll-area"
import { skills, roles, frameworks, frameworkSkills, Skill } from "../utils/AssessmentSkills"
import { UseFormReturn } from 'react-hook-form';
import { EndOfCampaignDate, StartOfCampaignDate } from "./CampaignPeriodInputs"
import { CompanyNameInput } from "./CompanyNameInput"
import PositionDescriptionInput from "./PositionDescriptionInput"
import { PositionInput } from "./PositionInput"
import { Checkbox } from "@/components/ui/checkbox"
import ExperienceLevelSelector from "./ExperienceLevelSelector"

export const HiringCampaignSetup = ({form,title,buttonText,disabled}:
    {form:UseFormReturn<any>,submitted:boolean,title:string,buttonText:string,disabled?:boolean}) => {

  const [configType, setConfigType] = useState<string>('')
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([])
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [selectedFramework, setSelectedFramework] = useState<string>('')
  const [includedSkills, setIncludedSkills] = useState<Skill[]>([])
  //const [includeSystemsDesign, _setIncludeSystemsDesign] = useState<boolean>(false)
  const [isSkillsOpen, setIsSkillsOpen] = useState<boolean>(false)
  const [evaluationLength, setEvaluationLength] = useState<number>(0)

  useEffect(() => {
    if (configType === 'skills') {
        setEvaluationLength(selectedSkills.length * 30)
    } else if (configType === 'role' && selectedFramework) {
        setEvaluationLength(includedSkills.length * 30)
    } else {
        setEvaluationLength(0)
    }
  }, [configType, selectedSkills, selectedFramework, includedSkills])


  useEffect(()=>{
    console.log('Selected: ',selectedSkills)
    form.setValue("skills",selectedSkills.map((skill)=>skill.id))
  },[selectedSkills])

  useEffect(()=>{
    console.log('Included: ',includedSkills)
    form.setValue("skills",includedSkills.map((skill)=>skill.id))
  },[includedSkills])

  const handleSkillToggle = (skill: Skill) => {
    setSelectedSkills(prev =>
      prev.includes(skill)
        ? prev.filter(s => s !== skill)
        : [...prev, skill]
    )
    console.log('Skills: ',selectedSkills)
  }

  const removeSkill = (skill: Skill) => {
    if (configType === 'skills') {
      setSelectedSkills(prev => prev.filter(s => s !== skill))
      console.log('Skills: ',selectedSkills)
    } else {
      setIncludedSkills(prev => prev.filter(s => s !== skill))
      console.log('Skills: ',includedSkills)
    }
  }

  const handleFrameworkChange = (framework: string) => {
    setSelectedFramework(framework)
    setIncludedSkills(frameworkSkills[framework] || [])
    console.log('Skills: ',includedSkills)
  }


  return (
    <div className="min-h-screen sm:px-6 lg:px-8">
    
        <Card className="max-w-6xl mx-auto shadow-lg">
            <CardHeader className="text-center bg-[#1f2937] text-white p-6 rounded-t-lg">
                <CardTitle className="text-3xl font-bold">{title}</CardTitle>
            </CardHeader>
            <CardContent className="bg-white p-8">
                <div className="space-y-8">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
                        <CompanyNameInput form={form} disabled={disabled}/>
                        <PositionInput form={form} disabled={disabled}/>
                        <ExperienceLevelSelector form={form} disabled={disabled}/>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-4 gap-6">
                        <div className="xl:col-start-2">
                            <StartOfCampaignDate form={form} disabled={disabled}/>
                        </div>
                        <div>
                            <EndOfCampaignDate form={form}/>
                        </div>
                    </div>
                    <PositionDescriptionInput form={form} disabled={disabled}/>
                </div>

                <div>
                    <CardHeader className="px-0">
                        <CardTitle className="text-2xl font-bold flex items-center gap-2"> 
                            Configure Assessment
                        </CardTitle>
                        <CardDescription className="text-base">
                            Configure your hiring campaign assessment by skills or by role.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-8">
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Assessment Type:</h3>
                        <RadioGroup value={configType} onValueChange={setConfigType} className="space-y-4" disabled={disabled}>
                        <div className="flex items-start space-x-2">
                            <RadioGroupItem value="skills" id="skills" className="mt-1"/>
                            <div>
                            <Label htmlFor="skills" className="font-semibold flex items-center gap-2">
                                <CodeIcon className="w-4 h-4" />
                                Skills-based
                            </Label>
                            <p className="text-sm text-gray-600">Assess specific technologies crucial for the role. Ideal for custom or niche requirements.</p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-2">
                            <RadioGroupItem value="role" id="role" className="mt-1" />
                            <div>
                            <Label htmlFor="role" className="font-semibold flex items-center gap-2">
                                <BriefcaseIcon className="w-4 h-4" />
                                Role-based
                            </Label>
                            <p className="text-sm text-gray-600">Predefined evaluations based on common job titles. Best for structured assessments aligned with standard responsibilities.</p>
                            </div>
                        </div>
                        </RadioGroup>
                        <p className="mt-4 text-sm text-gray-600 italic">Not sure which to pick? Configure by Skills to focus on specific technologies, or by Role for a pre-defined assessment.</p>
                    </div>

                    {configType === 'skills' && (
                        <div>
                        <h2 className="text-2xl font-semibold mb-4">Select Skills:</h2>
                        <Collapsible
                            open={isSkillsOpen}
                            onOpenChange={setIsSkillsOpen}
                            className="w-full border rounded-md shadow-sm"
                        >
                            <CollapsibleTrigger className="flex w-full justify-between items-center p-4 hover:bg-gray-50 transition-colors">
                            <span>{selectedSkills.length ? `${selectedSkills.length} skills selected` : 'Select skills'}</span>
                            <ChevronDownIcon className={`w-4 h-4 transition-transform duration-200 ${isSkillsOpen ? 'transform rotate-180' : ''}`} />
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                            <ScrollArea className="h-72 w-full">
                                <div className="p-4 space-y-2">
                                {skills.map(skill => (
                                    <div key={skill.id} className="flex items-center space-x-2">
                                    <Checkbox
                                        id={skill.id}
                                        checked={selectedSkills.includes(skill)}
                                        onCheckedChange={() => handleSkillToggle(skill)}
                                    />
                                    <Label htmlFor={skill.id}>{skill.label}</Label>
                                    </div>
                                ))}
                                </div>
                            </ScrollArea>
                            </CollapsibleContent>
                        </Collapsible>
                        {selectedSkills.length > 0 && (
                            <div className="mt-4">
                            <h3 className="font-semibold mb-2">Selected Skills:</h3>
                            <div className="flex flex-wrap gap-2">
                                {selectedSkills.map(skill => (
                                <Badge key={skill.id} variant="secondary" className="px-2 py-1">
                                    {skill.label}
                                    <button onClick={() => removeSkill(skill)} className="ml-2 text-gray-500 hover:text-gray-700">
                                    <XIcon className="w-3 h-3" />
                                    </button>
                                </Badge>
                                ))}
                            </div>
                            </div>
                        )}
                        {selectedSkills.length > 0 && (
                            <p className="mt-4 text-sm text-gray-600">
                            Each selected skill will include a Q&A and an algorithm-based evaluation, lasting approximately 30 minutes.
                            </p>
                        )}
                        </div>
                    )}

                    {configType === 'role' && (
                        <div className="space-y-4">
                        <div>
                            <h2 className="text-2xl font-semibold mb-4">Select Role:</h2>
                            <Select value={selectedRole} onValueChange={setSelectedRole}>
                            <SelectTrigger className="w-full bg-white">
                                <SelectValue placeholder="Select a role" />
                            </SelectTrigger>
                            <SelectContent className="bg-white">
                                {roles.map(role => (
                                <SelectItem key={role} value={role}>{role}</SelectItem>
                                ))}
                            </SelectContent>
                            </Select>
                        </div>

                        {selectedRole && (
                            <div>
                            <h2 className="text-2xl font-semibold mb-4">Select Framework:</h2>
                            <Select value={selectedFramework} onValueChange={handleFrameworkChange}>
                                <SelectTrigger className="w-full bg-white">
                                    <SelectValue placeholder="Select a framework" />
                                </SelectTrigger>
                                <SelectContent className="bg-white">
                                {frameworks[selectedRole].map(framework => (
                                    <SelectItem key={framework} value={framework}>{framework}</SelectItem>
                                ))}
                                </SelectContent>
                            </Select>
                            </div>
                        )}

                        {selectedFramework && (
                            <div>
                            <h3 className="font-semibold mb-2">Included Skills:</h3>
                            <div className="flex flex-wrap gap-2">
                                {includedSkills.map(skill => (
                                <Badge key={skill.id} variant="secondary" className="px-2 py-1">
                                    {skill.label}
                                    <button onClick={() => removeSkill(skill)} className="ml-2 text-gray-500 hover:text-gray-700">
                                    <XIcon className="w-3 h-3" />
                                    </button>
                                </Badge>
                                ))}
                            </div>
                            </div>
                        )}

                        {selectedRole && selectedFramework && (
                            <p className="mt-4 text-sm text-gray-600">
                            This role and framework combination will include a Q&A and an algorithm-based evaluation for each included skill, lasting approximately 30 minutes per skill.
                            </p>
                        )}
                        </div>
                    )}

                    {/* <div className="mt-4">
                        <div className="flex items-center space-x-2">
                        <Checkbox
                            id="systemsDesign"
                            checked={includeSystemsDesign}
                            onCheckedChange={(checked) => setIncludeSystemsDesign(checked as boolean)}
                        />
                        <Label htmlFor="systemsDesign">Include systems design evaluation (adds 60 minutes)</Label>
                        </div>
                    </div>  */}

                    {evaluationLength > 0 && (
                        <div className="mt-4 p-4 bg-blue-50 rounded-md flex items-center space-x-2">
                        <ClockIcon className="w-5 h-5 text-blue-500" />
                        <div>
                            <h3 className="font-semibold text-blue-700">Estimated Assessment Duration:</h3>
                            <p className="text-blue-600">Approximately {evaluationLength} minutes</p>
                        </div>
                        </div>
                    )}

                    <Button 
                        type="submit"
                        //onClick={() => console.log('Configuration:', { configType, selectedSkills, selectedRole, selectedFramework, includedSkills, includeSystemsDesign })}
                        className="w-full"
                        disabled={!configType || (configType === 'skills' && selectedSkills.length === 0) || (configType === 'role' && (!selectedRole || !selectedFramework))}
                    >
                        {buttonText}
                    </Button>
                    </CardContent>
                </div>  
             
            </CardContent>
        </Card>
      
    </div>
  )
}