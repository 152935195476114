import { Route } from '@tanstack/react-router';
//import { rootRoute } from "../../App"
import { MainAppRoute } from "@/rootRoutes/MainApp"
import { manageAccessToken } from "@/utils/Auth/Session"
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login"
//import { HiringCampaignForm } from './HiringCampaignForm';
import { Header } from '@/components/ui/Title';
import { Card, CardContent } from '@/components/ui/card';
//import PricingCards from './PricingModels';
//import { useGetUser } from '../../../openapi/api/endpoints/default/default';
//import { plans } from './PricingModelData/MockData';
import { LicenseForm } from './LicenseForm';

const ConfirmLicense=()=>{
  
  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
        <div className="flex pl-4">
            <Header>Confirm your plan</Header>
        </div>
        <div className="flex flex-col m-4">
            <Card className="border-none bg-white">
                <CardContent className="p-4">
                    <LicenseForm/>
                </CardContent>
            </Card>
        </div>
    </div>
  )
}

const ConfirmLicenseRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "/confirmLicense",
    component: ConfirmLicense,
    beforeLoad:async ({context:{auth,queryClient}})=>{
      const session=await manageAccessToken({AuthPayload:auth})
      if(!session){//no token means that token is still good
        return
      }
      const newAuthPayload={
        ...auth,
          session
        } as AuthenticateUserResp
      queryClient.setQueryData(['Auth'],newAuthPayload)
      return {
        auth:newAuthPayload
      }
    },
    })
export default ConfirmLicenseRoute;