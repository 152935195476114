//import createTheme from '@mui/material/styles/createTheme';
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { ResponsiveContainer } from "recharts";

export type StatusGaugeProps = {
  width?: number;
  height?: number;
  value?: number;
  status?: string;
};

export default function StatusGauge({
  width,
  status,
  height,
  value,
}: StatusGaugeProps) {
  const w = width ?? 50;
  const h = height ?? 250;

  const settings = {
    //width: 100,
    //height: 200,
    value: value,
  };

  /* const theme = createTheme({
      breakpoints: {
        values: {
          //xxs: 0, // small phone
          xs: 300, // phone
          sm: 600, // tablets
          md: 900, // small laptop
          lg: 1200, // desktop
          xl: 1536 // large screens
        }
      }
    }); */

  return (
    <ResponsiveContainer width={`${w}%`} height={h}>
      <Gauge
        {...settings}
        startAngle={-100}
        endAngle={100}
        cornerRadius="50%"
        sx={() => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 25,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: "#26A69A",
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: "#ccfbf1",
          },
        })}
        text={`${status}`}
      />
    </ResponsiveContainer>
  );
}
