import { Outlet, Router, rootRouteWithContext } from '@tanstack/react-router'
import AboutRoute from './pages/About/About'
import './index.css'
import '../app/globals.css'
import { QueryClient } from '@tanstack/react-query';
import AuthRoute from './pages/Authentication/Authentication'
import { MainAppRoute } from './rootRoutes/MainApp'
import HiringProcessTableRoute from './pages/EntrepriseOverview/Page'
import CandidateDetailseRoute from './pages/CandidateDetails/CandidateDetails'
import NewCampaignRoute from './pages/HiringCampaign/NewCampaign'
//import NewLicenseRoute from './pages/License/NewLicense'
//import SelectPlanRoute from './pages/License/NewLicense'
import SelectLicenseRoute from './pages/License/SelectLicense'
import ConfirmLicenseRoute from './pages/License/ConfirmLicense'
import AllCampaignsRoute from './pages/AllCampaigns/AllCampaigns'
import DashboardRoute from './pages/Dashboard/Dashboard'
import AuthContextProvider from './store/auth-context'
import ProfileRoute from './pages/Profile/Profile'
import AddUserAuthRoute from './pages/Authentication/AddUserAuth'

export const queryClient=new QueryClient()
queryClient.setMutationDefaults(['Auth'],{
  gcTime:60*60*1000 //1h before cache is garbage collected
});
queryClient.setQueryDefaults(['Auth'],{
  gcTime:60*60*1000 //1h before cache is garbage collected
})
queryClient.setMutationDefaults([`/permissionsHeaderCustom/`],{
  gcTime:60*60*1000 //1h before cache is garbage collected
});
queryClient.setQueryDefaults([`/permissionsHeaderCustom/`],{
  gcTime:60*60*1000 //1h before cache is garbage collected
})

export const rootRoute = rootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: ()=> <AuthContextProvider><Outlet /></AuthContextProvider>

})

const routeTree = rootRoute.addChildren([
  AuthRoute,
  MainAppRoute.addChildren([
    AboutRoute,
    HiringProcessTableRoute, AllCampaignsRoute,
    CandidateDetailseRoute, NewCampaignRoute,
    SelectLicenseRoute, ConfirmLicenseRoute, DashboardRoute,
    ProfileRoute, 
  ]),
  AddUserAuthRoute
])
export const router = new Router({ 
  routeTree,
  context: {
    queryClient: queryClient
  },

})
