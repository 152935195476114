import React, { useState } from "react";
import { ExternalLink } from "lucide-react";
import { getHiringCampaignsIdResume } from '../../../../openapi/api/endpoints/default/default';



type PresignedLinkProps = {
    hiringCampaignId: string;
    resumeKey: string;
    children?: React.ReactNode;
  };
  
const PresignedLink: React.FC<PresignedLinkProps> = ({
    hiringCampaignId,
    resumeKey,
    children,
  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
  
    const handleClick = async (e: React.MouseEvent) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      try {
        const presignedUrl = await getHiringCampaignsIdResume(hiringCampaignId, {
          resumeKey,
        });
  
        if (presignedUrl) {
          window.open(presignedUrl, "_blank", "noopener,noreferrer");
        }
      } catch (err) {
        console.error("Failed to fetch presigned URL:", err);
        setError("Failed to load the resume. Please try again.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <a
        href="#"
        onClick={handleClick}
        className="flex items-center text-blue-500 hover:underline dark:text-blue-400"
      >
        {loading ? (
          <span className="flex items-center">
            <span className="spinner mr-1" /> Loading...
          </span>
        ) : error ? (
          <span className="text-red-500">{error}</span>
        ) : (
          <>
            {children || "View"}
            <ExternalLink className="ml-1 h-4 w-4" />
          </>
        )}
      </a>
    );
  };
  
export default PresignedLink;


