export const TitleSmall = ({children}:{children:React.ReactNode}) => {
    return (
        <h1 className="text-black text-bold text-l break-words max-xl:truncate">{children}</h1>
    )
}

export const Title = ({children}:{children:React.ReactNode}) => {
    return (
        <h1 className="text-black text-bold text-xl break-words truncate">{children}</h1>
    )
}

export const Header = ({children}:{children:React.ReactNode}) => {
    return (
        <h1 className="p-1 text-black font-bold text-3xl break-words truncate">{children}</h1>
    )
}
