import CustomHalfGauge from "@/components/ui/custom/Charts/CustomHalfGauge";

export type OverallScoresProps={
  value?:number,
  minValue?:number,
  maxValue?:number
  //status?:string,
}

const OverallScores = ({
  value,
  minValue,
  maxValue,
}:OverallScoresProps) => {
    return (  
      <CustomHalfGauge title="Overall Scores" value={value??0} minValue={minValue??0} maxValue={maxValue??0} cardContentStyle=""/>     
    ) 
}

export default OverallScores;
