
import { Route, useParams } from "@tanstack/react-router"
import signup from "../../assets/signup.png"
import { LandingPage } from "./AuthComponents/LandingPage"
import { SignUp } from "./AuthComponents/SignUp"
import { rootRoute } from "@/App"
import { toast } from "sonner"
import { useGetPermissionsIdUnAuth } from '../../customMutators/useGetPermissionIdUnAuth';

const AddUserAuth = () => {
  //http://localhost:5173/newUser/663fceff3b1169c49ae3aee1

    const {registrationID}=useParams({
        from:'/'
    }) //registrationID is permissionID
    //fetch permission to prefil signUp page
    const {data,isError,isFetched,error }=useGetPermissionsIdUnAuth({permissionId:registrationID})
    const permission=data?.data
    console.log(data)
    console.log(error)
    const RedirectComponent=()=><SignUp permission={permission} />

    if(isError||!data && isFetched){
        toast.error('Non-existent license',{
            description:'Failed to resolve invite link',
            action: {
                label: "Dismiss",
                onClick: () => {},
              },
              style: {
                background: '#a3e635'
              }
        })
    }


    
  return (
    <LandingPage
    sideImage={<img src={signup} className="h-full w-full"/>}
    LandingPageComponent={RedirectComponent}
    />
  )
}
const AddUserAuthRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/newUser/$registrationID",
    component: AddUserAuth,
})
export default AddUserAuthRoute