/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BackendEntryPoint
 * API for managing users, tracks, and projects for a code evaluation platform.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AccountUsers,
  Candidate,
  Company,
  DevUser,
  GetHiringCampaignsIdResumeParams,
  GetHiringcampaignsSearch200,
  GetHiringcampaignsSearchParams,
  GetHiringresultsSearch200,
  GetHiringresultsSearchParams,
  GetProjectsSearchParams,
  GetTracksSearchApiParams,
  GetTracksSearchParams,
  GetUserDeveloperSearchApiParams,
  GetUserDeveloperSearchParams,
  GetUserHiringresourceSearchApiParams,
  GetUserHiringresourceSearchParams,
  HiringCampaign,
  HiringResult,
  HrUser,
  License,
  Performance,
  Permission,
  PostTracksBody,
  Project,
  ProjectContext,
  PutHiringresultsParams,
  Track
} from '../../model'
import { customClient } from '../../../mutator/custom-client';
import type { ErrorType, BodyType } from '../../../mutator/custom-client';



/**
 * @summary Get a dev users by sub
 */
export const getUserDeveloper = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<DevUser>(
      {url: `/user-developer`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserDeveloperQueryKey = () => {
    return [`/user-developer`] as const;
    }

    
export const getGetUserDeveloperQueryOptions = <TData = Awaited<ReturnType<typeof getUserDeveloper>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloper>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDeveloperQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeveloper>>> = ({ signal }) => getUserDeveloper(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloper>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserDeveloperQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDeveloper>>>
export type GetUserDeveloperQueryError = ErrorType<void>

/**
 * @summary Get a dev users by sub
 */
export const useGetUserDeveloper = <TData = Awaited<ReturnType<typeof getUserDeveloper>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloper>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserDeveloperQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new dev user
 */
export const postUserDeveloper = (
    devUser: BodyType<DevUser>,
 ) => {
      
      
      return customClient<DevUser>(
      {url: `/user-developer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: devUser
    },
      );
    }
  


export const getPostUserDeveloperMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserDeveloper>>, {data: BodyType<DevUser>}> = (props) => {
          const {data} = props ?? {};

          return  postUserDeveloper(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostUserDeveloperMutationResult = NonNullable<Awaited<ReturnType<typeof postUserDeveloper>>>
    export type PostUserDeveloperMutationBody = BodyType<DevUser>
    export type PostUserDeveloperMutationError = ErrorType<void>

    /**
 * @summary Create a new dev user
 */
export const usePostUserDeveloper = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postUserDeveloper>>,
        TError,
        {data: BodyType<DevUser>},
        TContext
      > => {

      const mutationOptions = getPostUserDeveloperMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a dev user
 */
export const putUserDeveloper = (
    devUser: BodyType<DevUser>,
 ) => {
      
      
      return customClient<DevUser>(
      {url: `/user-developer`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: devUser
    },
      );
    }
  


export const getPutUserDeveloperMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserDeveloper>>, {data: BodyType<DevUser>}> = (props) => {
          const {data} = props ?? {};

          return  putUserDeveloper(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserDeveloperMutationResult = NonNullable<Awaited<ReturnType<typeof putUserDeveloper>>>
    export type PutUserDeveloperMutationBody = BodyType<DevUser>
    export type PutUserDeveloperMutationError = ErrorType<void>

    /**
 * @summary Update a dev user
 */
export const usePutUserDeveloper = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloper>>, TError,{data: BodyType<DevUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putUserDeveloper>>,
        TError,
        {data: BodyType<DevUser>},
        TContext
      > => {

      const mutationOptions = getPutUserDeveloperMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a dev users by using a querry
 */
export const getUserDeveloperSearchApi = (
    params?: GetUserDeveloperSearchApiParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<DevUser[]>(
      {url: `/user-developer/search/api`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserDeveloperSearchApiQueryKey = (params?: GetUserDeveloperSearchApiParams,) => {
    return [`/user-developer/search/api`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserDeveloperSearchApiQueryOptions = <TData = Awaited<ReturnType<typeof getUserDeveloperSearchApi>>, TError = ErrorType<void>>(params?: GetUserDeveloperSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearchApi>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDeveloperSearchApiQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeveloperSearchApi>>> = ({ signal }) => getUserDeveloperSearchApi(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearchApi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserDeveloperSearchApiQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDeveloperSearchApi>>>
export type GetUserDeveloperSearchApiQueryError = ErrorType<void>

/**
 * @summary Get a dev users by using a querry
 */
export const useGetUserDeveloperSearchApi = <TData = Awaited<ReturnType<typeof getUserDeveloperSearchApi>>, TError = ErrorType<void>>(
 params?: GetUserDeveloperSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearchApi>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserDeveloperSearchApiQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a dev users by using a querry
 */
export const getUserDeveloperSearch = (
    params?: GetUserDeveloperSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<DevUser[]>(
      {url: `/user-developer/search/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserDeveloperSearchQueryKey = (params?: GetUserDeveloperSearchParams,) => {
    return [`/user-developer/search/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserDeveloperSearchQueryOptions = <TData = Awaited<ReturnType<typeof getUserDeveloperSearch>>, TError = ErrorType<void>>(params?: GetUserDeveloperSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDeveloperSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeveloperSearch>>> = ({ signal }) => getUserDeveloperSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserDeveloperSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDeveloperSearch>>>
export type GetUserDeveloperSearchQueryError = ErrorType<void>

/**
 * @summary Get a dev users by using a querry
 */
export const useGetUserDeveloperSearch = <TData = Awaited<ReturnType<typeof getUserDeveloperSearch>>, TError = ErrorType<void>>(
 params?: GetUserDeveloperSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserDeveloperSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a dev user by ID
 */
export const getUserDeveloperUserId = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<DevUser>(
      {url: `/user-developer/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserDeveloperUserIdQueryKey = (userId: string,) => {
    return [`/user-developer/${userId}`] as const;
    }

    
export const getGetUserDeveloperUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserDeveloperUserId>>, TError = ErrorType<void>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperUserId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDeveloperUserIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDeveloperUserId>>> = ({ signal }) => getUserDeveloperUserId(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserDeveloperUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDeveloperUserId>>>
export type GetUserDeveloperUserIdQueryError = ErrorType<void>

/**
 * @summary Get a dev user by ID
 */
export const useGetUserDeveloperUserId = <TData = Awaited<ReturnType<typeof getUserDeveloperUserId>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDeveloperUserId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserDeveloperUserIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a dev user by ID
 */
export const putUserDeveloperUserId = (
    userId: string,
    devUser: BodyType<DevUser>,
 ) => {
      
      
      return customClient<void>(
      {url: `/user-developer/${userId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: devUser
    },
      );
    }
  


export const getPutUserDeveloperUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloperUserId>>, TError,{userId: string;data: BodyType<DevUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloperUserId>>, TError,{userId: string;data: BodyType<DevUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserDeveloperUserId>>, {userId: string;data: BodyType<DevUser>}> = (props) => {
          const {userId,data} = props ?? {};

          return  putUserDeveloperUserId(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserDeveloperUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putUserDeveloperUserId>>>
    export type PutUserDeveloperUserIdMutationBody = BodyType<DevUser>
    export type PutUserDeveloperUserIdMutationError = ErrorType<void>

    /**
 * @summary Update a dev user by ID
 */
export const usePutUserDeveloperUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserDeveloperUserId>>, TError,{userId: string;data: BodyType<DevUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putUserDeveloperUserId>>,
        TError,
        {userId: string;data: BodyType<DevUser>},
        TContext
      > => {

      const mutationOptions = getPutUserDeveloperUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a dev user by ID
 */
export const deleteUserDeveloperUserId = (
    userId: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/user-developer/${userId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteUserDeveloperUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserDeveloperUserId>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserDeveloperUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserDeveloperUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteUserDeveloperUserId(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserDeveloperUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserDeveloperUserId>>>
    
    export type DeleteUserDeveloperUserIdMutationError = ErrorType<void>

    /**
 * @summary Delete a dev user by ID
 */
export const useDeleteUserDeveloperUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserDeveloperUserId>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserDeveloperUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeleteUserDeveloperUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a hr users by sub
 */
export const getUserHiringresource = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<HrUser>(
      {url: `/user-hiringresource`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserHiringresourceQueryKey = () => {
    return [`/user-hiringresource`] as const;
    }

    
export const getGetUserHiringresourceQueryOptions = <TData = Awaited<ReturnType<typeof getUserHiringresource>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresource>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserHiringresourceQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHiringresource>>> = ({ signal }) => getUserHiringresource(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresource>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserHiringresourceQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHiringresource>>>
export type GetUserHiringresourceQueryError = ErrorType<void>

/**
 * @summary Get a hr users by sub
 */
export const useGetUserHiringresource = <TData = Awaited<ReturnType<typeof getUserHiringresource>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresource>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserHiringresourceQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new hr user
 */
export const postUserHiringresource = (
    hrUser: BodyType<HrUser>,
 ) => {
      
      
      return customClient<HrUser>(
      {url: `/user-hiringresource`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hrUser
    },
      );
    }
  


export const getPostUserHiringresourceMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postUserHiringresource>>, {data: BodyType<HrUser>}> = (props) => {
          const {data} = props ?? {};

          return  postUserHiringresource(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostUserHiringresourceMutationResult = NonNullable<Awaited<ReturnType<typeof postUserHiringresource>>>
    export type PostUserHiringresourceMutationBody = BodyType<HrUser>
    export type PostUserHiringresourceMutationError = ErrorType<void>

    /**
 * @summary Create a new hr user
 */
export const usePostUserHiringresource = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postUserHiringresource>>,
        TError,
        {data: BodyType<HrUser>},
        TContext
      > => {

      const mutationOptions = getPostUserHiringresourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a hr user
 */
export const putUserHiringresource = (
    hrUser: BodyType<HrUser>,
 ) => {
      
      
      return customClient<HrUser>(
      {url: `/user-hiringresource`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hrUser
    },
      );
    }
  


export const getPutUserHiringresourceMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserHiringresource>>, {data: BodyType<HrUser>}> = (props) => {
          const {data} = props ?? {};

          return  putUserHiringresource(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserHiringresourceMutationResult = NonNullable<Awaited<ReturnType<typeof putUserHiringresource>>>
    export type PutUserHiringresourceMutationBody = BodyType<HrUser>
    export type PutUserHiringresourceMutationError = ErrorType<void>

    /**
 * @summary Update a hr user
 */
export const usePutUserHiringresource = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresource>>, TError,{data: BodyType<HrUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putUserHiringresource>>,
        TError,
        {data: BodyType<HrUser>},
        TContext
      > => {

      const mutationOptions = getPutUserHiringresourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a hr users by using a querry
 */
export const getUserHiringresourceSearchApi = (
    params?: GetUserHiringresourceSearchApiParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<HrUser[]>(
      {url: `/user-hiringresource/search/api`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserHiringresourceSearchApiQueryKey = (params?: GetUserHiringresourceSearchApiParams,) => {
    return [`/user-hiringresource/search/api`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserHiringresourceSearchApiQueryOptions = <TData = Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>, TError = ErrorType<void>>(params?: GetUserHiringresourceSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserHiringresourceSearchApiQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>> = ({ signal }) => getUserHiringresourceSearchApi(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserHiringresourceSearchApiQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>>
export type GetUserHiringresourceSearchApiQueryError = ErrorType<void>

/**
 * @summary Get a hr users by using a querry
 */
export const useGetUserHiringresourceSearchApi = <TData = Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>, TError = ErrorType<void>>(
 params?: GetUserHiringresourceSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearchApi>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserHiringresourceSearchApiQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a hr users by using a id querry
 */
export const getUserHiringresourceSearch = (
    params?: GetUserHiringresourceSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<HrUser[]>(
      {url: `/user-hiringresource/search/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserHiringresourceSearchQueryKey = (params?: GetUserHiringresourceSearchParams,) => {
    return [`/user-hiringresource/search/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserHiringresourceSearchQueryOptions = <TData = Awaited<ReturnType<typeof getUserHiringresourceSearch>>, TError = ErrorType<void>>(params?: GetUserHiringresourceSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserHiringresourceSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHiringresourceSearch>>> = ({ signal }) => getUserHiringresourceSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserHiringresourceSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHiringresourceSearch>>>
export type GetUserHiringresourceSearchQueryError = ErrorType<void>

/**
 * @summary Get a hr users by using a id querry
 */
export const useGetUserHiringresourceSearch = <TData = Awaited<ReturnType<typeof getUserHiringresourceSearch>>, TError = ErrorType<void>>(
 params?: GetUserHiringresourceSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserHiringresourceSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a user by ID
 */
export const getUserHiringresourceUserId = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<HrUser>(
      {url: `/user-hiringresource/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserHiringresourceUserIdQueryKey = (userId: string,) => {
    return [`/user-hiringresource/${userId}`] as const;
    }

    
export const getGetUserHiringresourceUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserHiringresourceUserId>>, TError = ErrorType<void>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceUserId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserHiringresourceUserIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHiringresourceUserId>>> = ({ signal }) => getUserHiringresourceUserId(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceUserId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserHiringresourceUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHiringresourceUserId>>>
export type GetUserHiringresourceUserIdQueryError = ErrorType<void>

/**
 * @summary Get a user by ID
 */
export const useGetUserHiringresourceUserId = <TData = Awaited<ReturnType<typeof getUserHiringresourceUserId>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceUserId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserHiringresourceUserIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a user by ID
 */
export const putUserHiringresourceUserId = (
    userId: string,
    hrUser: BodyType<HrUser>,
 ) => {
      
      
      return customClient<void>(
      {url: `/user-hiringresource/${userId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hrUser
    },
      );
    }
  


export const getPutUserHiringresourceUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresourceUserId>>, TError,{userId: string;data: BodyType<HrUser>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresourceUserId>>, TError,{userId: string;data: BodyType<HrUser>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putUserHiringresourceUserId>>, {userId: string;data: BodyType<HrUser>}> = (props) => {
          const {userId,data} = props ?? {};

          return  putUserHiringresourceUserId(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUserHiringresourceUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putUserHiringresourceUserId>>>
    export type PutUserHiringresourceUserIdMutationBody = BodyType<HrUser>
    export type PutUserHiringresourceUserIdMutationError = ErrorType<void>

    /**
 * @summary Update a user by ID
 */
export const usePutUserHiringresourceUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putUserHiringresourceUserId>>, TError,{userId: string;data: BodyType<HrUser>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putUserHiringresourceUserId>>,
        TError,
        {userId: string;data: BodyType<HrUser>},
        TContext
      > => {

      const mutationOptions = getPutUserHiringresourceUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a user by ID
 */
export const deleteUserHiringresourceUserId = (
    userId: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/user-hiringresource/${userId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteUserHiringresourceUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteUserHiringresourceUserId(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserHiringresourceUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>>
    
    export type DeleteUserHiringresourceUserIdMutationError = ErrorType<void>

    /**
 * @summary Delete a user by ID
 */
export const useDeleteUserHiringresourceUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserHiringresourceUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeleteUserHiringresourceUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getUserHiringresourceAccount = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<AccountUsers[]>(
      {url: `/user-hiringresource/account`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserHiringresourceAccountQueryKey = () => {
    return [`/user-hiringresource/account`] as const;
    }

    
export const getGetUserHiringresourceAccountQueryOptions = <TData = Awaited<ReturnType<typeof getUserHiringresourceAccount>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceAccount>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserHiringresourceAccountQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserHiringresourceAccount>>> = ({ signal }) => getUserHiringresourceAccount(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceAccount>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserHiringresourceAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getUserHiringresourceAccount>>>
export type GetUserHiringresourceAccountQueryError = ErrorType<void>

export const useGetUserHiringresourceAccount = <TData = Awaited<ReturnType<typeof getUserHiringresourceAccount>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserHiringresourceAccount>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserHiringresourceAccountQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a list of all tracks using querries
 */
export const getTracks = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<Track[]>(
      {url: `/tracks`, method: 'GET', signal
    },
      );
    }
  

export const getGetTracksQueryKey = () => {
    return [`/tracks`] as const;
    }

    
export const getGetTracksQueryOptions = <TData = Awaited<ReturnType<typeof getTracks>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracks>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTracksQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTracks>>> = ({ signal }) => getTracks(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTracks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTracksQueryResult = NonNullable<Awaited<ReturnType<typeof getTracks>>>
export type GetTracksQueryError = ErrorType<void>

/**
 * @summary Get a list of all tracks using querries
 */
export const useGetTracks = <TData = Awaited<ReturnType<typeof getTracks>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracks>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTracksQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new track
 */
export const postTracks = (
    postTracksBody: BodyType<PostTracksBody>,
 ) => {
      
      
      return customClient<Track>(
      {url: `/tracks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postTracksBody
    },
      );
    }
  


export const getPostTracksMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTracks>>, TError,{data: BodyType<PostTracksBody>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postTracks>>, TError,{data: BodyType<PostTracksBody>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTracks>>, {data: BodyType<PostTracksBody>}> = (props) => {
          const {data} = props ?? {};

          return  postTracks(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostTracksMutationResult = NonNullable<Awaited<ReturnType<typeof postTracks>>>
    export type PostTracksMutationBody = BodyType<PostTracksBody>
    export type PostTracksMutationError = ErrorType<void>

    /**
 * @summary Create a new track
 */
export const usePostTracks = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTracks>>, TError,{data: BodyType<PostTracksBody>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postTracks>>,
        TError,
        {data: BodyType<PostTracksBody>},
        TContext
      > => {

      const mutationOptions = getPostTracksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a tracks by using a querry ID
 */
export const getTracksSearch = (
    params?: GetTracksSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<Track[]>(
      {url: `/tracks/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTracksSearchQueryKey = (params?: GetTracksSearchParams,) => {
    return [`/tracks/search`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTracksSearchQueryOptions = <TData = Awaited<ReturnType<typeof getTracksSearch>>, TError = ErrorType<void>>(params?: GetTracksSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTracksSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTracksSearch>>> = ({ signal }) => getTracksSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTracksSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTracksSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getTracksSearch>>>
export type GetTracksSearchQueryError = ErrorType<void>

/**
 * @summary Get a tracks by using a querry ID
 */
export const useGetTracksSearch = <TData = Awaited<ReturnType<typeof getTracksSearch>>, TError = ErrorType<void>>(
 params?: GetTracksSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTracksSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a tracks by using a querry string
 */
export const getTracksSearchApi = (
    params?: GetTracksSearchApiParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<Track[]>(
      {url: `/tracks/search/api`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTracksSearchApiQueryKey = (params?: GetTracksSearchApiParams,) => {
    return [`/tracks/search/api`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTracksSearchApiQueryOptions = <TData = Awaited<ReturnType<typeof getTracksSearchApi>>, TError = ErrorType<void>>(params?: GetTracksSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksSearchApi>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTracksSearchApiQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTracksSearchApi>>> = ({ signal }) => getTracksSearchApi(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTracksSearchApi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTracksSearchApiQueryResult = NonNullable<Awaited<ReturnType<typeof getTracksSearchApi>>>
export type GetTracksSearchApiQueryError = ErrorType<void>

/**
 * @summary Get a tracks by using a querry string
 */
export const useGetTracksSearchApi = <TData = Awaited<ReturnType<typeof getTracksSearchApi>>, TError = ErrorType<void>>(
 params?: GetTracksSearchApiParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksSearchApi>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTracksSearchApiQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a track by ID
 */
export const getTracksTrackId = (
    trackId: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<Track>(
      {url: `/tracks/${trackId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetTracksTrackIdQueryKey = (trackId: string,) => {
    return [`/tracks/${trackId}`] as const;
    }

    
export const getGetTracksTrackIdQueryOptions = <TData = Awaited<ReturnType<typeof getTracksTrackId>>, TError = ErrorType<void>>(trackId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksTrackId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTracksTrackIdQueryKey(trackId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTracksTrackId>>> = ({ signal }) => getTracksTrackId(trackId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(trackId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTracksTrackId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTracksTrackIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTracksTrackId>>>
export type GetTracksTrackIdQueryError = ErrorType<void>

/**
 * @summary Get a track by ID
 */
export const useGetTracksTrackId = <TData = Awaited<ReturnType<typeof getTracksTrackId>>, TError = ErrorType<void>>(
 trackId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTracksTrackId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTracksTrackIdQueryOptions(trackId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a track by ID
 */
export const putTracksTrackId = (
    trackId: string,
    track: BodyType<Track>,
 ) => {
      
      
      return customClient<Track>(
      {url: `/tracks/${trackId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: track
    },
      );
    }
  


export const getPutTracksTrackIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTracksTrackId>>, TError,{trackId: string;data: BodyType<Track>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putTracksTrackId>>, TError,{trackId: string;data: BodyType<Track>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putTracksTrackId>>, {trackId: string;data: BodyType<Track>}> = (props) => {
          const {trackId,data} = props ?? {};

          return  putTracksTrackId(trackId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutTracksTrackIdMutationResult = NonNullable<Awaited<ReturnType<typeof putTracksTrackId>>>
    export type PutTracksTrackIdMutationBody = BodyType<Track>
    export type PutTracksTrackIdMutationError = ErrorType<void>

    /**
 * @summary Update a track by ID
 */
export const usePutTracksTrackId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putTracksTrackId>>, TError,{trackId: string;data: BodyType<Track>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putTracksTrackId>>,
        TError,
        {trackId: string;data: BodyType<Track>},
        TContext
      > => {

      const mutationOptions = getPutTracksTrackIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a track by ID
 */
export const deleteTracksTrackId = (
    trackId: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/tracks/${trackId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTracksTrackIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTracksTrackId>>, TError,{trackId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTracksTrackId>>, TError,{trackId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTracksTrackId>>, {trackId: string}> = (props) => {
          const {trackId} = props ?? {};

          return  deleteTracksTrackId(trackId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTracksTrackIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTracksTrackId>>>
    
    export type DeleteTracksTrackIdMutationError = ErrorType<void>

    /**
 * @summary Delete a track by ID
 */
export const useDeleteTracksTrackId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTracksTrackId>>, TError,{trackId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTracksTrackId>>,
        TError,
        {trackId: string},
        TContext
      > => {

      const mutationOptions = getDeleteTracksTrackIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a list of all projects by using the sub
 */
export const getProjects = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<Project[]>(
      {url: `/projects`, method: 'GET', signal
    },
      );
    }
  

export const getGetProjectsQueryKey = () => {
    return [`/projects`] as const;
    }

    
export const getGetProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getProjects>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjects>>> = ({ signal }) => getProjects(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjects>>>
export type GetProjectsQueryError = ErrorType<void>

/**
 * @summary Get a list of all projects by using the sub
 */
export const useGetProjects = <TData = Awaited<ReturnType<typeof getProjects>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new project
 */
export const postProjects = (
    project: BodyType<Project>,
 ) => {
      
      
      return customClient<Project>(
      {url: `/projects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: project
    },
      );
    }
  


export const getPostProjectsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: BodyType<Project>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: BodyType<Project>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjects>>, {data: BodyType<Project>}> = (props) => {
          const {data} = props ?? {};

          return  postProjects(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsMutationResult = NonNullable<Awaited<ReturnType<typeof postProjects>>>
    export type PostProjectsMutationBody = BodyType<Project>
    export type PostProjectsMutationError = ErrorType<void>

    /**
 * @summary Create a new project
 */
export const usePostProjects = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProjects>>, TError,{data: BodyType<Project>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postProjects>>,
        TError,
        {data: BodyType<Project>},
        TContext
      > => {

      const mutationOptions = getPostProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a Project by using a querry ID
 */
export const getProjectsSearch = (
    params?: GetProjectsSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<Project[]>(
      {url: `/projects/search/`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetProjectsSearchQueryKey = (params?: GetProjectsSearchParams,) => {
    return [`/projects/search/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetProjectsSearchQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsSearch>>, TError = ErrorType<void>>(params?: GetProjectsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsSearch>>> = ({ signal }) => getProjectsSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsSearch>>>
export type GetProjectsSearchQueryError = ErrorType<void>

/**
 * @summary Get a Project by using a querry ID
 */
export const useGetProjectsSearch = <TData = Awaited<ReturnType<typeof getProjectsSearch>>, TError = ErrorType<void>>(
 params?: GetProjectsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a project by ID
 */
export const getProjectsProjectId = (
    projectId: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<Project>(
      {url: `/projects/${projectId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetProjectsProjectIdQueryKey = (projectId: string,) => {
    return [`/projects/${projectId}`] as const;
    }

    
export const getGetProjectsProjectIdQueryOptions = <TData = Awaited<ReturnType<typeof getProjectsProjectId>>, TError = ErrorType<void>>(projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsProjectIdQueryKey(projectId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsProjectId>>> = ({ signal }) => getProjectsProjectId(projectId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsProjectIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectsProjectId>>>
export type GetProjectsProjectIdQueryError = ErrorType<void>

/**
 * @summary Get a project by ID
 */
export const useGetProjectsProjectId = <TData = Awaited<ReturnType<typeof getProjectsProjectId>>, TError = ErrorType<void>>(
 projectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectsProjectId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectsProjectIdQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a project by ID
 */
export const putProjectsProjectId = (
    projectId: string,
    project: BodyType<Project>,
 ) => {
      
      
      return customClient<Project>(
      {url: `/projects/${projectId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: project
    },
      );
    }
  


export const getPutProjectsProjectIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsProjectId>>, TError,{projectId: string;data: BodyType<Project>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putProjectsProjectId>>, TError,{projectId: string;data: BodyType<Project>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putProjectsProjectId>>, {projectId: string;data: BodyType<Project>}> = (props) => {
          const {projectId,data} = props ?? {};

          return  putProjectsProjectId(projectId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof putProjectsProjectId>>>
    export type PutProjectsProjectIdMutationBody = BodyType<Project>
    export type PutProjectsProjectIdMutationError = ErrorType<void>

    /**
 * @summary Update a project by ID
 */
export const usePutProjectsProjectId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putProjectsProjectId>>, TError,{projectId: string;data: BodyType<Project>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putProjectsProjectId>>,
        TError,
        {projectId: string;data: BodyType<Project>},
        TContext
      > => {

      const mutationOptions = getPutProjectsProjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a project by ID
 */
export const deleteProjectsProjectId = (
    projectId: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/projects/${projectId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteProjectsProjectIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectsProjectId>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteProjectsProjectId(projectId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectsProjectId>>>
    
    export type DeleteProjectsProjectIdMutationError = ErrorType<void>

    /**
 * @summary Delete a project by ID
 */
export const useDeleteProjectsProjectId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectsProjectId>>, TError,{projectId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProjectsProjectId>>,
        TError,
        {projectId: string},
        TContext
      > => {

      const mutationOptions = getDeleteProjectsProjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Create a new company
 */
export const postCompanies = (
    company: BodyType<Company>,
 ) => {
      
      
      return customClient<Company>(
      {url: `/companies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: company
    },
      );
    }
  


export const getPostCompaniesMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanies>>, TError,{data: BodyType<Company>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postCompanies>>, TError,{data: BodyType<Company>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCompanies>>, {data: BodyType<Company>}> = (props) => {
          const {data} = props ?? {};

          return  postCompanies(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof postCompanies>>>
    export type PostCompaniesMutationBody = BodyType<Company>
    export type PostCompaniesMutationError = ErrorType<void>

    /**
 * @summary Create a new company
 */
export const usePostCompanies = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCompanies>>, TError,{data: BodyType<Company>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postCompanies>>,
        TError,
        {data: BodyType<Company>},
        TContext
      > => {

      const mutationOptions = getPostCompaniesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a project by ID
 */
export const getCompanyCompanyID = (
    companyID: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<Company>(
      {url: `/Company/${companyID}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCompanyCompanyIDQueryKey = (companyID: string,) => {
    return [`/Company/${companyID}`] as const;
    }

    
export const getGetCompanyCompanyIDQueryOptions = <TData = Awaited<ReturnType<typeof getCompanyCompanyID>>, TError = ErrorType<void>>(companyID: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyCompanyID>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompanyCompanyIDQueryKey(companyID);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyCompanyID>>> = ({ signal }) => getCompanyCompanyID(companyID, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(companyID), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompanyCompanyID>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCompanyCompanyIDQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyCompanyID>>>
export type GetCompanyCompanyIDQueryError = ErrorType<void>

/**
 * @summary Get a project by ID
 */
export const useGetCompanyCompanyID = <TData = Awaited<ReturnType<typeof getCompanyCompanyID>>, TError = ErrorType<void>>(
 companyID: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyCompanyID>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCompanyCompanyIDQueryOptions(companyID,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a project by ID
 */
export const putCompanyCompanyID = (
    companyID: string,
    company: BodyType<Company>,
 ) => {
      
      
      return customClient<Company>(
      {url: `/Company/${companyID}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: company
    },
      );
    }
  


export const getPutCompanyCompanyIDMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyCompanyID>>, TError,{companyID: string;data: BodyType<Company>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putCompanyCompanyID>>, TError,{companyID: string;data: BodyType<Company>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCompanyCompanyID>>, {companyID: string;data: BodyType<Company>}> = (props) => {
          const {companyID,data} = props ?? {};

          return  putCompanyCompanyID(companyID,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutCompanyCompanyIDMutationResult = NonNullable<Awaited<ReturnType<typeof putCompanyCompanyID>>>
    export type PutCompanyCompanyIDMutationBody = BodyType<Company>
    export type PutCompanyCompanyIDMutationError = ErrorType<void>

    /**
 * @summary Update a project by ID
 */
export const usePutCompanyCompanyID = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCompanyCompanyID>>, TError,{companyID: string;data: BodyType<Company>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putCompanyCompanyID>>,
        TError,
        {companyID: string;data: BodyType<Company>},
        TContext
      > => {

      const mutationOptions = getPutCompanyCompanyIDMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a company by ID
 */
export const deleteCompanyCompanyID = (
    companyID: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/Company/${companyID}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCompanyCompanyIDMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyCompanyID>>, TError,{companyID: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyCompanyID>>, TError,{companyID: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyCompanyID>>, {companyID: string}> = (props) => {
          const {companyID} = props ?? {};

          return  deleteCompanyCompanyID(companyID,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCompanyCompanyIDMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyCompanyID>>>
    
    export type DeleteCompanyCompanyIDMutationError = ErrorType<void>

    /**
 * @summary Delete a company by ID
 */
export const useDeleteCompanyCompanyID = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyCompanyID>>, TError,{companyID: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCompanyCompanyID>>,
        TError,
        {companyID: string},
        TContext
      > => {

      const mutationOptions = getDeleteCompanyCompanyIDMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Create a new license
 */
export const postLicenses = (
    license: BodyType<License>,
 ) => {
      
      
      return customClient<License>(
      {url: `/licenses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: license
    },
      );
    }
  


export const getPostLicensesMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLicenses>>, TError,{data: BodyType<License>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postLicenses>>, TError,{data: BodyType<License>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postLicenses>>, {data: BodyType<License>}> = (props) => {
          const {data} = props ?? {};

          return  postLicenses(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostLicensesMutationResult = NonNullable<Awaited<ReturnType<typeof postLicenses>>>
    export type PostLicensesMutationBody = BodyType<License>
    export type PostLicensesMutationError = ErrorType<void>

    /**
 * @summary Create a new license
 */
export const usePostLicenses = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLicenses>>, TError,{data: BodyType<License>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postLicenses>>,
        TError,
        {data: BodyType<License>},
        TContext
      > => {

      const mutationOptions = getPostLicensesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a licenses by ID
 */
export const getLicensesId = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<License>(
      {url: `/licenses/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetLicensesIdQueryKey = (id: string,) => {
    return [`/licenses/${id}`] as const;
    }

    
export const getGetLicensesIdQueryOptions = <TData = Awaited<ReturnType<typeof getLicensesId>>, TError = ErrorType<void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLicensesId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLicensesIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLicensesId>>> = ({ signal }) => getLicensesId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLicensesId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLicensesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLicensesId>>>
export type GetLicensesIdQueryError = ErrorType<void>

/**
 * @summary Get a licenses by ID
 */
export const useGetLicensesId = <TData = Awaited<ReturnType<typeof getLicensesId>>, TError = ErrorType<void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLicensesId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLicensesIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a project by ID
 */
export const putLicensesId = (
    id: string,
    license: BodyType<License>,
 ) => {
      
      
      return customClient<License>(
      {url: `/licenses/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: license
    },
      );
    }
  


export const getPutLicensesIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putLicensesId>>, TError,{id: string;data: BodyType<License>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putLicensesId>>, TError,{id: string;data: BodyType<License>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putLicensesId>>, {id: string;data: BodyType<License>}> = (props) => {
          const {id,data} = props ?? {};

          return  putLicensesId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutLicensesIdMutationResult = NonNullable<Awaited<ReturnType<typeof putLicensesId>>>
    export type PutLicensesIdMutationBody = BodyType<License>
    export type PutLicensesIdMutationError = ErrorType<void>

    /**
 * @summary Update a project by ID
 */
export const usePutLicensesId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putLicensesId>>, TError,{id: string;data: BodyType<License>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putLicensesId>>,
        TError,
        {id: string;data: BodyType<License>},
        TContext
      > => {

      const mutationOptions = getPutLicensesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a License by ID
 */
export const deleteLicensesId = (
    id: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/licenses/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteLicensesIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLicensesId>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLicensesId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLicensesId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteLicensesId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteLicensesIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLicensesId>>>
    
    export type DeleteLicensesIdMutationError = ErrorType<void>

    /**
 * @summary Delete a License by ID
 */
export const useDeleteLicensesId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLicensesId>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteLicensesId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteLicensesIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Create a new Hiring Campaign
 */
export const postHiringcampaigns = (
    hiringCampaign: BodyType<HiringCampaign>,
 ) => {
      
      
      return customClient<HiringCampaign>(
      {url: `/hiringcampaigns`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiringCampaign
    },
      );
    }
  


export const getPostHiringcampaignsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postHiringcampaigns>>, TError,{data: BodyType<HiringCampaign>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postHiringcampaigns>>, TError,{data: BodyType<HiringCampaign>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postHiringcampaigns>>, {data: BodyType<HiringCampaign>}> = (props) => {
          const {data} = props ?? {};

          return  postHiringcampaigns(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostHiringcampaignsMutationResult = NonNullable<Awaited<ReturnType<typeof postHiringcampaigns>>>
    export type PostHiringcampaignsMutationBody = BodyType<HiringCampaign>
    export type PostHiringcampaignsMutationError = ErrorType<void>

    /**
 * @summary Create a new Hiring Campaign
 */
export const usePostHiringcampaigns = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postHiringcampaigns>>, TError,{data: BodyType<HiringCampaign>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postHiringcampaigns>>,
        TError,
        {data: BodyType<HiringCampaign>},
        TContext
      > => {

      const mutationOptions = getPostHiringcampaignsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a list of all Hiring Campaign by using the sub
 */
export const getHiringcampaignsSearch = (
    params?: GetHiringcampaignsSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<GetHiringcampaignsSearch200>(
      {url: `/hiringcampaigns/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetHiringcampaignsSearchQueryKey = (params?: GetHiringcampaignsSearchParams,) => {
    return [`/hiringcampaigns/search`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHiringcampaignsSearchQueryOptions = <TData = Awaited<ReturnType<typeof getHiringcampaignsSearch>>, TError = ErrorType<void>>(params?: GetHiringcampaignsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringcampaignsSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringcampaignsSearch>>> = ({ signal }) => getHiringcampaignsSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringcampaignsSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringcampaignsSearch>>>
export type GetHiringcampaignsSearchQueryError = ErrorType<void>

/**
 * @summary Get a list of all Hiring Campaign by using the sub
 */
export const useGetHiringcampaignsSearch = <TData = Awaited<ReturnType<typeof getHiringcampaignsSearch>>, TError = ErrorType<void>>(
 params?: GetHiringcampaignsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringcampaignsSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a Hiring Campaign by ID
 */
export const getHiringcampaignsId = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<HiringCampaign>(
      {url: `/hiringcampaigns/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetHiringcampaignsIdQueryKey = (id: string,) => {
    return [`/hiringcampaigns/${id}`] as const;
    }

    
export const getGetHiringcampaignsIdQueryOptions = <TData = Awaited<ReturnType<typeof getHiringcampaignsId>>, TError = ErrorType<void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringcampaignsIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringcampaignsId>>> = ({ signal }) => getHiringcampaignsId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringcampaignsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringcampaignsId>>>
export type GetHiringcampaignsIdQueryError = ErrorType<void>

/**
 * @summary Get a Hiring Campaign by ID
 */
export const useGetHiringcampaignsId = <TData = Awaited<ReturnType<typeof getHiringcampaignsId>>, TError = ErrorType<void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringcampaignsId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringcampaignsIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a Hiring Campaign by ID
 */
export const putHiringcampaignsId = (
    id: string,
    hiringCampaign: BodyType<HiringCampaign>,
 ) => {
      
      
      return customClient<HiringCampaign>(
      {url: `/hiringcampaigns/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hiringCampaign
    },
      );
    }
  


export const getPutHiringcampaignsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringcampaignsId>>, TError,{id: string;data: BodyType<HiringCampaign>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putHiringcampaignsId>>, TError,{id: string;data: BodyType<HiringCampaign>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putHiringcampaignsId>>, {id: string;data: BodyType<HiringCampaign>}> = (props) => {
          const {id,data} = props ?? {};

          return  putHiringcampaignsId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutHiringcampaignsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putHiringcampaignsId>>>
    export type PutHiringcampaignsIdMutationBody = BodyType<HiringCampaign>
    export type PutHiringcampaignsIdMutationError = ErrorType<void>

    /**
 * @summary Update a Hiring Campaign by ID
 */
export const usePutHiringcampaignsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringcampaignsId>>, TError,{id: string;data: BodyType<HiringCampaign>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putHiringcampaignsId>>,
        TError,
        {id: string;data: BodyType<HiringCampaign>},
        TContext
      > => {

      const mutationOptions = getPutHiringcampaignsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete a Hiring Campaign by ID
 */
export const deleteHiringcampaignsId = (
    id: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/hiringcampaigns/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteHiringcampaignsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHiringcampaignsId>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteHiringcampaignsId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHiringcampaignsId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteHiringcampaignsId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteHiringcampaignsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHiringcampaignsId>>>
    
    export type DeleteHiringcampaignsIdMutationError = ErrorType<void>

    /**
 * @summary Delete a Hiring Campaign by ID
 */
export const useDeleteHiringcampaignsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHiringcampaignsId>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteHiringcampaignsId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteHiringcampaignsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the URL of a resume for a specific hiring campaign.
 * @summary Retrieve resume URL
 */
export const getHiringCampaignsIdResume = (
    id: string,
    params: GetHiringCampaignsIdResumeParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<string>(
      {url: `/hiringCampaigns/${id}/resume`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetHiringCampaignsIdResumeQueryKey = (id: string,
    params: GetHiringCampaignsIdResumeParams,) => {
    return [`/hiringCampaigns/${id}/resume`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHiringCampaignsIdResumeQueryOptions = <TData = Awaited<ReturnType<typeof getHiringCampaignsIdResume>>, TError = ErrorType<void>>(id: string,
    params: GetHiringCampaignsIdResumeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdResume>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringCampaignsIdResumeQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringCampaignsIdResume>>> = ({ signal }) => getHiringCampaignsIdResume(id,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdResume>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringCampaignsIdResumeQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringCampaignsIdResume>>>
export type GetHiringCampaignsIdResumeQueryError = ErrorType<void>

/**
 * @summary Retrieve resume URL
 */
export const useGetHiringCampaignsIdResume = <TData = Awaited<ReturnType<typeof getHiringCampaignsIdResume>>, TError = ErrorType<void>>(
 id: string,
    params: GetHiringCampaignsIdResumeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdResume>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringCampaignsIdResumeQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Fetches a list of candidates associated with a specific hiring campaign.
 * @summary Retrieve candidates for a hiring campaign
 */
export const getHiringCampaignsIdApplicants = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<Candidate[]>(
      {url: `/hiringCampaigns/${id}/applicants`, method: 'GET', signal
    },
      );
    }
  

export const getGetHiringCampaignsIdApplicantsQueryKey = (id: string,) => {
    return [`/hiringCampaigns/${id}/applicants`] as const;
    }

    
export const getGetHiringCampaignsIdApplicantsQueryOptions = <TData = Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>, TError = ErrorType<void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringCampaignsIdApplicantsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>> = ({ signal }) => getHiringCampaignsIdApplicants(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringCampaignsIdApplicantsQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>>
export type GetHiringCampaignsIdApplicantsQueryError = ErrorType<void>

/**
 * @summary Retrieve candidates for a hiring campaign
 */
export const useGetHiringCampaignsIdApplicants = <TData = Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>, TError = ErrorType<void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsIdApplicants>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringCampaignsIdApplicantsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new hiring result
 */
export const postHiringresults = (
    hiringResult: BodyType<HiringResult>,
 ) => {
      
      
      return customClient<HiringResult>(
      {url: `/hiringresults`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hiringResult
    },
      );
    }
  


export const getPostHiringresultsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postHiringresults>>, TError,{data: BodyType<HiringResult>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postHiringresults>>, TError,{data: BodyType<HiringResult>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postHiringresults>>, {data: BodyType<HiringResult>}> = (props) => {
          const {data} = props ?? {};

          return  postHiringresults(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostHiringresultsMutationResult = NonNullable<Awaited<ReturnType<typeof postHiringresults>>>
    export type PostHiringresultsMutationBody = BodyType<HiringResult>
    export type PostHiringresultsMutationError = ErrorType<void>

    /**
 * @summary Create a new hiring result
 */
export const usePostHiringresults = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postHiringresults>>, TError,{data: BodyType<HiringResult>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postHiringresults>>,
        TError,
        {data: BodyType<HiringResult>},
        TContext
      > => {

      const mutationOptions = getPostHiringresultsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update hiring results by hiring campaign ID and sub
 */
export const putHiringresults = (
    hiringResult: BodyType<HiringResult>,
    params?: PutHiringresultsParams,
 ) => {
      
      
      return customClient<HiringResult>(
      {url: `/hiringresults`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: hiringResult,
        params
    },
      );
    }
  


export const getPutHiringresultsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringresults>>, TError,{data: BodyType<HiringResult>;params?: PutHiringresultsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putHiringresults>>, TError,{data: BodyType<HiringResult>;params?: PutHiringresultsParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putHiringresults>>, {data: BodyType<HiringResult>;params?: PutHiringresultsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  putHiringresults(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutHiringresultsMutationResult = NonNullable<Awaited<ReturnType<typeof putHiringresults>>>
    export type PutHiringresultsMutationBody = BodyType<HiringResult>
    export type PutHiringresultsMutationError = ErrorType<void>

    /**
 * @summary Update hiring results by hiring campaign ID and sub
 */
export const usePutHiringresults = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringresults>>, TError,{data: BodyType<HiringResult>;params?: PutHiringresultsParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putHiringresults>>,
        TError,
        {data: BodyType<HiringResult>;params?: PutHiringresultsParams},
        TContext
      > => {

      const mutationOptions = getPutHiringresultsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a list of all Hiring hiringresults with different query
 */
export const getHiringresultsSearch = (
    params?: GetHiringresultsSearchParams,
 signal?: AbortSignal
) => {
      
      
      return customClient<GetHiringresultsSearch200>(
      {url: `/hiringresults/search`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetHiringresultsSearchQueryKey = (params?: GetHiringresultsSearchParams,) => {
    return [`/hiringresults/search`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHiringresultsSearchQueryOptions = <TData = Awaited<ReturnType<typeof getHiringresultsSearch>>, TError = ErrorType<void>>(params?: GetHiringresultsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsSearch>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringresultsSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringresultsSearch>>> = ({ signal }) => getHiringresultsSearch(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringresultsSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringresultsSearch>>>
export type GetHiringresultsSearchQueryError = ErrorType<void>

/**
 * @summary Get a list of all Hiring hiringresults with different query
 */
export const useGetHiringresultsSearch = <TData = Awaited<ReturnType<typeof getHiringresultsSearch>>, TError = ErrorType<void>>(
 params?: GetHiringresultsSearchParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsSearch>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringresultsSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get hiring results by hiring campaign ID
 */
export const getHiringCampaignsResultsHiringCampaignId = (
    hiringCampaignId: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<HiringResult[]>(
      {url: `/hiringCampaigns/results/${hiringCampaignId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetHiringCampaignsResultsHiringCampaignIdQueryKey = (hiringCampaignId: string,) => {
    return [`/hiringCampaigns/results/${hiringCampaignId}`] as const;
    }

    
export const getGetHiringCampaignsResultsHiringCampaignIdQueryOptions = <TData = Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>, TError = ErrorType<void>>(hiringCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringCampaignsResultsHiringCampaignIdQueryKey(hiringCampaignId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>> = ({ signal }) => getHiringCampaignsResultsHiringCampaignId(hiringCampaignId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(hiringCampaignId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringCampaignsResultsHiringCampaignIdQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>>
export type GetHiringCampaignsResultsHiringCampaignIdQueryError = ErrorType<void>

/**
 * @summary Get hiring results by hiring campaign ID
 */
export const useGetHiringCampaignsResultsHiringCampaignId = <TData = Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>, TError = ErrorType<void>>(
 hiringCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringCampaignsResultsHiringCampaignId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringCampaignsResultsHiringCampaignIdQueryOptions(hiringCampaignId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get hiring result by ID
 */
export const getHiringresultsId = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<HiringResult>(
      {url: `/hiringresults/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetHiringresultsIdQueryKey = (id: string,) => {
    return [`/hiringresults/${id}`] as const;
    }

    
export const getGetHiringresultsIdQueryOptions = <TData = Awaited<ReturnType<typeof getHiringresultsId>>, TError = ErrorType<void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHiringresultsIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHiringresultsId>>> = ({ signal }) => getHiringresultsId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHiringresultsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getHiringresultsId>>>
export type GetHiringresultsIdQueryError = ErrorType<void>

/**
 * @summary Get hiring result by ID
 */
export const useGetHiringresultsId = <TData = Awaited<ReturnType<typeof getHiringresultsId>>, TError = ErrorType<void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHiringresultsId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetHiringresultsIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update hiring result by ID
 */
export const putHiringresultsId = (
    id: string,
    performance: BodyType<Performance>,
 ) => {
      
      
      return customClient<void>(
      {url: `/hiringresults/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: performance
    },
      );
    }
  


export const getPutHiringresultsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringresultsId>>, TError,{id: string;data: BodyType<Performance>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putHiringresultsId>>, TError,{id: string;data: BodyType<Performance>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putHiringresultsId>>, {id: string;data: BodyType<Performance>}> = (props) => {
          const {id,data} = props ?? {};

          return  putHiringresultsId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutHiringresultsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putHiringresultsId>>>
    export type PutHiringresultsIdMutationBody = BodyType<Performance>
    export type PutHiringresultsIdMutationError = ErrorType<void>

    /**
 * @summary Update hiring result by ID
 */
export const usePutHiringresultsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putHiringresultsId>>, TError,{id: string;data: BodyType<Performance>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putHiringresultsId>>,
        TError,
        {id: string;data: BodyType<Performance>},
        TContext
      > => {

      const mutationOptions = getPutHiringresultsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete hiring result by ID
 */
export const deleteHiringresultsId = (
    id: string,
 ) => {
      
      
      return customClient<void>(
      {url: `/hiringresults/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteHiringresultsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHiringresultsId>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteHiringresultsId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHiringresultsId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteHiringresultsId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteHiringresultsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHiringresultsId>>>
    
    export type DeleteHiringresultsIdMutationError = ErrorType<void>

    /**
 * @summary Delete hiring result by ID
 */
export const useDeleteHiringresultsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHiringresultsId>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteHiringresultsId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteHiringresultsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Project Context with templateID in the body
 */
export const getProjectContext = (
    
 signal?: AbortSignal
) => {
      
      
      return customClient<ProjectContext>(
      {url: `/projectContext`, method: 'GET', signal
    },
      );
    }
  

export const getGetProjectContextQueryKey = () => {
    return [`/projectContext`] as const;
    }

    
export const getGetProjectContextQueryOptions = <TData = Awaited<ReturnType<typeof getProjectContext>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectContext>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectContextQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectContext>>> = ({ signal }) => getProjectContext(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectContext>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectContextQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectContext>>>
export type GetProjectContextQueryError = ErrorType<void>

/**
 * @summary Get Project Context with templateID in the body
 */
export const useGetProjectContext = <TData = Awaited<ReturnType<typeof getProjectContext>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectContext>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectContextQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Add performances of a Project Object with project ID
 */
export const putEvaluationEnvironment = (
    performance: BodyType<Performance>,
 ) => {
      
      
      return customClient<Performance>(
      {url: `/evaluationEnvironment`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: performance
    },
      );
    }
  


export const getPutEvaluationEnvironmentMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putEvaluationEnvironment>>, TError,{data: BodyType<Performance>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putEvaluationEnvironment>>, TError,{data: BodyType<Performance>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putEvaluationEnvironment>>, {data: BodyType<Performance>}> = (props) => {
          const {data} = props ?? {};

          return  putEvaluationEnvironment(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutEvaluationEnvironmentMutationResult = NonNullable<Awaited<ReturnType<typeof putEvaluationEnvironment>>>
    export type PutEvaluationEnvironmentMutationBody = BodyType<Performance>
    export type PutEvaluationEnvironmentMutationError = ErrorType<void>

    /**
 * @summary Add performances of a Project Object with project ID
 */
export const usePutEvaluationEnvironment = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putEvaluationEnvironment>>, TError,{data: BodyType<Performance>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putEvaluationEnvironment>>,
        TError,
        {data: BodyType<Performance>},
        TContext
      > => {

      const mutationOptions = getPutEvaluationEnvironmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new permission record.
 * @summary Create a new permission
 */
export const postPermissions = (
    permission: BodyType<Permission>,
 ) => {
      
      
      return customClient<string>(
      {url: `/permissions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: permission
    },
      );
    }
  


export const getPostPermissionsMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPermissions>>, TError,{data: BodyType<Permission>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postPermissions>>, TError,{data: BodyType<Permission>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPermissions>>, {data: BodyType<Permission>}> = (props) => {
          const {data} = props ?? {};

          return  postPermissions(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostPermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof postPermissions>>>
    export type PostPermissionsMutationBody = BodyType<Permission>
    export type PostPermissionsMutationError = ErrorType<void>

    /**
 * @summary Create a new permission
 */
export const usePostPermissions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPermissions>>, TError,{data: BodyType<Permission>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postPermissions>>,
        TError,
        {data: BodyType<Permission>},
        TContext
      > => {

      const mutationOptions = getPostPermissionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Retrieve a specific permission record by ID.
 * @summary Get a specific permission
 */
export const getPermissionsId = (
    id: string,
 signal?: AbortSignal
) => {
      
      
      return customClient<Permission>(
      {url: `/permissions/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetPermissionsIdQueryKey = (id: string,) => {
    return [`/permissions/${id}`] as const;
    }

    
export const getGetPermissionsIdQueryOptions = <TData = Awaited<ReturnType<typeof getPermissionsId>>, TError = ErrorType<void>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissionsId>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPermissionsIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissionsId>>> = ({ signal }) => getPermissionsId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPermissionsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPermissionsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionsId>>>
export type GetPermissionsIdQueryError = ErrorType<void>

/**
 * @summary Get a specific permission
 */
export const useGetPermissionsId = <TData = Awaited<ReturnType<typeof getPermissionsId>>, TError = ErrorType<void>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPermissionsId>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPermissionsIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update a permission record by ID.
 * @summary Update a specific permission
 */
export const putPermissionsId = (
    id: string,
    permission: BodyType<Permission>,
 ) => {
      
      
      return customClient<void>(
      {url: `/permissions/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: permission
    },
      );
    }
  


export const getPutPermissionsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putPermissionsId>>, TError,{id: string;data: BodyType<Permission>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putPermissionsId>>, TError,{id: string;data: BodyType<Permission>}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putPermissionsId>>, {id: string;data: BodyType<Permission>}> = (props) => {
          const {id,data} = props ?? {};

          return  putPermissionsId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutPermissionsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putPermissionsId>>>
    export type PutPermissionsIdMutationBody = BodyType<Permission>
    export type PutPermissionsIdMutationError = ErrorType<void>

    /**
 * @summary Update a specific permission
 */
export const usePutPermissionsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putPermissionsId>>, TError,{id: string;data: BodyType<Permission>}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putPermissionsId>>,
        TError,
        {id: string;data: BodyType<Permission>},
        TContext
      > => {

      const mutationOptions = getPutPermissionsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a permission record by ID.
 * @summary Delete a specific permission
 */
export const deletePermissionsId = (
    id: string,
 ) => {
      
      
      return customClient<string>(
      {url: `/permissions/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeletePermissionsIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePermissionsId>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePermissionsId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePermissionsId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deletePermissionsId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePermissionsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePermissionsId>>>
    
    export type DeletePermissionsIdMutationError = ErrorType<void>

    /**
 * @summary Delete a specific permission
 */
export const useDeletePermissionsId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePermissionsId>>, TError,{id: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deletePermissionsId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeletePermissionsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    