"use client"
//import Link from "next/link"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  //FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { toast } from "sonner"
import { useGetUserHiringresource, usePutUserHiringresource } from '../../../../openapi/api/endpoints/default/default';
import { useEffect } from 'react';
import { ProfileFormValues, profileFormSchema } from "../ProfileFormSchema"
import { HrUser } from "openapi/api/model"
import { Toaster } from "@/components/ui/sonner"


export function UserDetailsForm() {
  const { data: databcknd, isSuccess: isGetUserSuccess } = useGetUserHiringresource()
  if(!databcknd){return null}
    
    const {mutate,isSuccess,error}=usePutUserHiringresource({
      mutation:{
        onSuccess:()=>{
          console.log(isSuccess)
          toast.success("Profile updated!",{
            description: 'Profile updated successfully',
            action: {
              label: "Dismiss",
              onClick: () => {},
            },
            style: {
              background: '#a3e635'
            }
          })
        },
        onError:()=>{
          toast.error("An error occured!",{
            description: `${error}`,
            action: {
              label: "Dismiss",
              onClick: () => {},
            },
            style: {
              background: '#dc2626'
            }
          })
        }
      }
    });
    const form = useForm<ProfileFormValues>({
        resolver: zodResolver(profileFormSchema),
    })

    const onSubmit=(data: ProfileFormValues)=>{
        const profile:HrUser={
            email:data.email,
            sub:databcknd?.sub??'',
            userName:databcknd.userName,
            company:data.company,
            firstName:data.firstName??'',
            lastName:data.lastName??'',
            position:data.position,
            phone:data.phone??'',
            licenseId:databcknd?.licenseId??'',
            companyId:databcknd?.companyId
        }
        mutate({data:profile})
    }
    
    useEffect(() => {
      if (isGetUserSuccess && databcknd) {//only rerender if these conditions are satisfied
        form.reset(databcknd);
      }
    }, [form, databcknd, isGetUserSuccess]); // Only re-run if these dependencies change    
    
   /*
    if (isGetUserSuccess && databcknd) {//wont work as the body will be evaluated on every rerender, and we trigger a rerender here
      //which reevaluates the expression, which causes another rerender -> an infinite loop
      form.reset(databcknd);
    }   
   */

  return (
    <div className="grid grid-rows-4 h-[400px] w-full bg-white border border-primary100 rounded-md p-4">
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">        
                <div className="grid grid-cols-2 w-full space-x-8">
                    <FormField
                    control={form.control}
                    name="firstName"
                    render={() => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input placeholder="First Name"
                            {...form.register('firstName')}  />
                          </FormControl>
                          {form.formState.errors["firstName"] && (
                            <FormMessage className="text-red-500">
                              {form.formState.errors["firstName"].message}
                            </FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                    <FormField
                    control={form.control}
                    name="lastName"
                    render={() => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Last Name"
                            {...form.register('lastName')}  />
                          </FormControl>
                          {form.formState.errors["lastName"] && (
                            <FormMessage className="text-red-500">
                              {form.formState.errors["lastName"].message}
                            </FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                  </div>
                   
                  <div className="grid grid-cols-2 w-full space-x-8">
                    <FormField
                      control={form.control}
                      name="company"
                      render={() => (
                        <FormItem>
                          <FormLabel>Company</FormLabel>
                          <FormControl>
                            <Input placeholder="Company"
                            {...form.register('company')}  />
                          </FormControl>
                          {form.formState.errors["company"] && (
                            <FormMessage className="text-red-500">
                              {form.formState.errors["company"].message}
                            </FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="position"
                      render={() => (
                        <FormItem>
                          <FormLabel>Position</FormLabel>
                          <FormControl>
                            <Input placeholder="Position"
                            {...form.register('position')}  
                            //readOnly={true}
                            //className="bg-mint100 opacity-40"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div> 

                  <div className="grid grid-cols-2 w-full space-x-8">
                    
                  <FormField
                    control={form.control}
                    name="email"
                    render={() => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="Email"
                          {...form.register('email')}  />
                        </FormControl>
                        {form.formState.errors["email"] && (
                          <FormMessage className="text-red-500">
                            {form.formState.errors["email"].message}
                          </FormMessage>
                        )}
                      </FormItem>
                    )}
                  />
                  <FormField
                      control={form.control}
                      name="phone"
                      render={() => (
                        <FormItem>
                          <FormLabel>Phone Number</FormLabel>
                          <FormControl>
                            <Input placeholder="Phone Number"
                            {...form.register('phone')}  />
                          </FormControl>
                          {form.formState.errors["phone"] && (
                            <FormMessage className="text-red-500">
                              {form.formState.errors["phone"].message}
                            </FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                </div>
                <div className="flex flex-row justify-center items-center">
                  <Button type="submit" className="bg-mint100 text-black hover:bg-teal100">Update profile</Button>
                </div>
            </form>
        </Form>
        <Toaster/>
    </div>
    )
}