import { AxiosResponse } from 'axios';
import {  useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Permission } from '../../openapi/api/model/permission';
import { queryClient } from '@/App';
import { AuthenticateUserResp } from '@/pages/Authentication/AuthUtils/Login';


export const useGetPermissionsIdUnAuth=({permissionId}:{permissionId?:string})=>{
    return useQuery({
        queryFn:()=>axios.get(`https://api.apisdevf.net/permissions/${permissionId}`) as Promise<AxiosResponse<Permission>>,
        queryKey:[`/permissions/${permissionId}`],
        enabled:!!permissionId,
        refetchOnWindowFocus:false
        

    })
}

export const useGetPermissionsHeader=({licenseId}:{licenseId?:string})=>{
    const resp=queryClient.getQueryData(['Auth']) as AuthenticateUserResp
    const session=resp?.session
    const token=session?.getIdToken()
    return useQuery({
        queryFn:()=>{
            if(!!licenseId){
            return axios({url:`https://api.apisdevf.net/permissionsHeader/${licenseId}`, method:'GET',
                headers:{
                    Authorization:`Bearer ${token?.getJwtToken()}`
                }
            }) as Promise<AxiosResponse<Permission>>}
        },
        queryKey:[`/permissionsHeaderCustom/`],

        

    })
}
