import { Route } from '@tanstack/react-router';
//import { rootRoute } from "../../App"
import { MainAppRoute } from "@/rootRoutes/MainApp"
import { manageAccessToken } from "@/utils/Auth/Session"
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login"
import { ProfileForm } from './ProfileForm';
import { useGetUserHiringresource } from '../../../openapi/api/endpoints/default/default';
import { Toaster } from "sonner"
import HeaderComponent from '@/components/Header/Header';


const Profile=()=>{
  const {data,isSuccess} = useGetUserHiringresource() 

  if(isSuccess){
    console.log('success')
  }
  
  return (
    <>
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        <HeaderComponent title='Profile'/>
        <div className="flex flex-col m-4 ml-8">
          <ProfileForm user={data}/>
        </div>
      </div>
      <Toaster/>
    </>

  )
}

const ProfileRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "/profile",
    component: Profile,
    beforeLoad:async ({context:{auth,queryClient}})=>{
      const session=await manageAccessToken({AuthPayload:auth})
      if(!session){//no token means that token is still good
        return
      }
      const newAuthPayload={
        ...auth,
          session
        } as AuthenticateUserResp
      queryClient.setQueryData(['Auth'],newAuthPayload)
      return {
        auth:newAuthPayload
      }
    },
    })
export default ProfileRoute;