import { Candidate } from '../../../components/Tables/Candidates/CandidatesTable';
import { MoreCandidateDetailData } from './MoreCandidateMockData';

export const candidates: Candidate[] = [
  { userName: "AliceJones", email: "alice.jones@example.com", sub: "dbveubeubnbntfni", hiringCampaignId:'61f43baa6e03d4971e17a0341', overallRatingData: 87, Code_Maintainability: 75, Security_Compliance: 80, overallSolutionFitness: 90, completedEval: true, moreDetail: MoreCandidateDetailData[0] },
  { userName: "BobSmith", email: "bob.smith@example.com", sub: "dshmnrbuvtcrtscd", hiringCampaignId:'61f43baa6e03d4971e17a0341', overallRatingData: 92, Code_Maintainability: 88, Security_Compliance: 84, overallSolutionFitness: 91, completedEval: false, moreDetail: MoreCandidateDetailData[1] },
  { userName: "CarolWhite", email: "carol.white@example.com", sub: "hr6oi9nrsljhrbfweik3ucx", hiringCampaignId:'61f43baa6e03d4971e17a0342', overallRatingData: 78, Code_Maintainability: 82, Security_Compliance: 77, overallSolutionFitness: 85, completedEval: true, moreDetail: MoreCandidateDetailData[2] },
  { userName: "DavidBrown", email: "david.brown@example.com", sub: "gvsw;ikgychbrewou", hiringCampaignId:'61f43baa6e03d4971e17a0342', overallRatingData: 81, Code_Maintainability: 79, Security_Compliance: 75, overallSolutionFitness: 88, completedEval: false, moreDetail: MoreCandidateDetailData[3] },
  { userName: "EvaGreen", email: "eva.green@example.com", sub: "gswljbhwvkvbkjw", hiringCampaignId:'61f43baa6e03d4971e17a0343', overallRatingData: 90, Code_Maintainability: 91, Security_Compliance: 89, overallSolutionFitness: 93,  completedEval: true, moreDetail: MoreCandidateDetailData[4] },
  { userName: "FrankHarris", email: "frank.harris@example.com", sub: "gwgnwerongv", hiringCampaignId:'61f43baa6e03d4971e17a0343', overallRatingData: 85, Code_Maintainability: 80, Security_Compliance: 82, overallSolutionFitness: 87, completedEval: false, moreDetail: MoreCandidateDetailData[5] },
  { userName: "GraceClark", email: "grace.clark@example.com", sub: "avftcgech86444h8ertybve", hiringCampaignId:'61f43baa6e03d4971e17a0344', overallRatingData: 88, Code_Maintainability: 85, Security_Compliance: 86, overallSolutionFitness: 89, completedEval: true, moreDetail: MoreCandidateDetailData[6] },
  { userName: "HenryLewis", email: "henry.lewis@example.com", sub: "wtghvtuvgjuvvje", hiringCampaignId:'61f43baa6e03d4971e17a0344', overallRatingData: 79, Code_Maintainability: 76, Security_Compliance: 74, overallSolutionFitness: 80, completedEval: false, moreDetail: MoreCandidateDetailData[7] },
  { userName: "IslaYoung", email: "isla.young@example.com", sub: "vejheyjhetcexe", hiringCampaignId:'61f43baa6e03d4971e17a0345', overallRatingData: 93, Code_Maintainability: 92, Security_Compliance: 91, overallSolutionFitness: 94, completedEval: true, moreDetail: MoreCandidateDetailData[8] },
  { userName: "JackKing", email: "jack.king@example.com", sub: "eyjeyjueytjbeyev", hiringCampaignId:'61f43baa6e03d4971e17a0346', overallRatingData: 82, Code_Maintainability: 81, Security_Compliance: 83, overallSolutionFitness: 84, completedEval: false, moreDetail: MoreCandidateDetailData[9] },
  { userName: "KaraScott", email: "kara.scott@example.com", sub: "esrtygrbvbnudtbntnub", hiringCampaignId:'61f43baa6e03d4971e17a0347', overallRatingData: 94, Code_Maintainability: 90, Security_Compliance: 95, overallSolutionFitness: 96, completedEval: true, moreDetail: MoreCandidateDetailData[10] },
  { userName: "LeoTurner", email: "leo.turner@example.com", sub: "euhgre57hugerhj89o8r6", hiringCampaignId:'61f43baa6e03d4971e17a0348', overallRatingData: 77, Code_Maintainability: 74, Security_Compliance: 73, overallSolutionFitness: 76, completedEval: false, moreDetail: MoreCandidateDetailData[11] },
  { userName: "MiaHill", email: "mia.hill@example.com", sub: "ghr7ir7nbbdrverfvce", hiringCampaignId:'61f43baa6e03d4971e17a0349', overallRatingData: 84, Code_Maintainability: 83, Security_Compliance: 81, overallSolutionFitness: 85, completedEval: true, moreDetail: MoreCandidateDetailData[12] },
  { userName: "NoahAdams", email: "noah.adams@example.com", sub: "etrujrkj78ewr5f5w34", hiringCampaignId:'61f43baa6e03d4971e17a0350', overallRatingData: 76, Code_Maintainability: 70, Security_Compliance: 72, overallSolutionFitness: 74, completedEval: false, moreDetail: MoreCandidateDetailData[13] },
  { userName: "OliviaBaker", email: "olivia.baker@example.com", sub: "3e568he6547hej598", hiringCampaignId:'61f43baa6e03d4971e17a0351', overallRatingData: 89, Code_Maintainability: 87, Security_Compliance: 85, overallSolutionFitness: 90, completedEval: true, moreDetail: MoreCandidateDetailData[5] },
  { userName: "ParkerCarter", email: "parker.carter@example.com", sub: "367ghernbrnbrin", hiringCampaignId:'61f43baa6e03d4971e17a0352', overallRatingData: 80, Code_Maintainability: 78, Security_Compliance: 76, overallSolutionFitness: 81, completedEval: false, moreDetail: MoreCandidateDetailData[8] },
  { userName: "QuinnDiaz", email: "quinn.diaz@example.com", sub: "ujhedjh9h8mnragvts", hiringCampaignId:'61f43baa6e03d4971e17a0353', overallRatingData: 91, Code_Maintainability: 89, Security_Compliance: 87, overallSolutionFitness: 92, completedEval: true, moreDetail: MoreCandidateDetailData[4] },
  { userName: "RyanEvans", email: "ryan.evans@example.com", sub: "y5uu457bghv46u7inb", hiringCampaignId:'61f43baa6e03d4971e17a0354', overallRatingData: 73, Code_Maintainability: 71, Security_Compliance: 70, overallSolutionFitness: 72, completedEval: false, moreDetail: MoreCandidateDetailData[2] },
  { userName: "SophiaFoster", email: "sophia.foster@example.com", sub: "475i4bcf45vhsgtn", hiringCampaignId:'61f43baa6e03d4971e17a0344', overallRatingData: 86, Code_Maintainability: 84, Security_Compliance: 82, overallSolutionFitness: 87, completedEval: true, moreDetail: MoreCandidateDetailData[7] },
  { userName: "TylerGarcia", email: "tyler.garcia@example.com", sub: "rtuynhueryggyygtbnuyd", hiringCampaignId:'61f43baa6e03d4971e17a0344', overallRatingData: 74, Code_Maintainability: 72, Security_Compliance: 70, overallSolutionFitness: 73, completedEval: false, moreDetail: MoreCandidateDetailData[1] }
];

export type HiringProcessData = {
  startdate: string;
  position: string;
  positionDescription: string;
  experienceLevel: string;
  skills: string[];
  companyName?: string;
  status: 'Draft' | 'Closed' | 'In Progress' | 'Complete';
  numberApplicants?: number;
  percentageCompleted?: number;
  metScoreCriteria?: number;
  dayslive?: number;
  hiringCampaignId: string;
  username?:string;
};

export const hiringProcesses: HiringProcessData[] = [
  {
    startdate: "2024-01-01",
    position: "DevOps Engineer",
    positionDescription:"Build, test and maintain infrastructure and tools so that software can be developed and released",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "TechCorp",
    status: "Draft",
    numberApplicants: 120,
    percentageCompleted: 80,
    metScoreCriteria: 15,
    dayslive: 10,
    hiringCampaignId: "61f43baa6e03d4971e17a0341"
  },
  {
    startdate: "2024-01-02",
    position: "Frontend Developer",
    positionDescription:"Developing new user-facing features, determining the structure and design of web pages, building reusable codes, optimizing page loading times, and using a variety of markup languages to create the web pages.",
    experienceLevel:"Entry-Level",
    skills:["JavaScript","React"],
    companyName: "Innovatech",
    status: "In Progress",
    numberApplicants: 85,
    percentageCompleted: 40,
    metScoreCriteria: 20,
    dayslive: 14,
    hiringCampaignId: "61f43baa6e03d4971e17a0342"
  },
  {
    startdate: "2024-01-03",
    position: "Data Scientist",
    positionDescription:"Use statistical methods to collect and organize data. Devise solutions to the problems encountered in data collection and cleaning and in developing statistical models and algorithms.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","C++","Node.js"],
    companyName: "DataGenius",
    status: "Complete",
    numberApplicants: 65,
    percentageCompleted: 100,
    metScoreCriteria: 25,
    dayslive: 20,
    hiringCampaignId: "61f43baa6e03d4971e17a0343"
  },
  {
    startdate: "2024-01-04",
    position: "UX/UI Designer",
    positionDescription:"developing intuitive and engaging user interfaces for digital products",
    experienceLevel:"Mid-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "DesignPro",
    status: "Closed",
    numberApplicants: 45,
    percentageCompleted: 70,
    metScoreCriteria: 30,
    dayslive: 7,
    hiringCampaignId: "61f43baa6e03d4971e17a0344"
  },
  {
    startdate: "2024-01-05",
    position: "Cloud Solutions Architect",
    positionDescription:"Work with cloud technology to develop cloud adoption plans, determine cloud application design, and create systems for managing, monitoring, and maintaining the cloud system.",
    experienceLevel:"Junior-Level",
    skills:["JavaScript","Node.js"],
    companyName: "CloudNet",
    status: "Draft",
    numberApplicants: 50,
    percentageCompleted: 90,
    metScoreCriteria: 18,
    dayslive: 12,
    hiringCampaignId: "61f43baa6e03d4971e17a0345"
  },
  {
    startdate: "2024-01-06",
    position: "Full Stack Developer",
    positionDescription:"performing both front- and back-end operations on websites and computer systems. Design front-end website architecture and work closely with the Graphic Designers in the company to ensure website applications collaborate well with the design elements.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "FullTech",
    status: "In Progress",
    numberApplicants: 110,
    percentageCompleted: 50,
    metScoreCriteria: 12,
    dayslive: 9,
    hiringCampaignId: "61f43baa6e03d4971e17a0346"
  },
  {
    startdate: "2024-01-07",
    position: "Cybersecurity Analyst",
    positionDescription:"Monitor, analyze, and respond to security incidents. Detect, identify, and alert to possible attacks, anomalous activities, and misuse activities.",
    experienceLevel:"Senior-Level",
    skills:["SQL","python","Node.js"],
    companyName: "SecureIT",
    status: "Complete",
    numberApplicants: 90,
    percentageCompleted: 30,
    metScoreCriteria: 10,
    dayslive: 16,
    hiringCampaignId: "61f43baa6e03d4971e17a0347"
  },
  {
    startdate: "2024-01-08",
    position: "AI Specialist",
    positionDescription:"We are seeking a talented AI Specialist to join our team. As an AI Specialist, you will be responsible for developing, implementing, and managing AI solutions that drive innovation and efficiency within our organization. You will work closely with cross-functional teams to identify business needs, design AI models, and deploy scalable solutions. The ideal candidate will have a strong background in machine learning, deep learning, and natural language processing, along with excellent problem-solving skills and a passion for AI technologies.",
    experienceLevel:"Mid-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "AI Innovations",
    status: "Closed",
    numberApplicants: 75,
    percentageCompleted: 100,
    metScoreCriteria: 28,
    dayslive: 24,
    hiringCampaignId: "61f43baa6e03d4971e17a0348"
  },
  {
    startdate: "2024-01-09",
    position: "Network Engineer",
    positionDescription:"We are looking for a skilled Network Engineer to design, implement, and maintain our organization's network infrastructure. As a Network Engineer, you will be responsible for configuring routers, switches, firewalls, and other network devices to ensure optimal performance and security. You will also troubleshoot network issues, analyze network traffic, and implement solutions to improve network efficiency. The ideal candidate will have a solid understanding of networking protocols, experience with network monitoring tools, and the ability to work in a fast-paced environment.",
    experienceLevel:"Mid-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "NetSolutions",
    status: "Draft",
    numberApplicants: 40,
    percentageCompleted: 60,
    metScoreCriteria: 22,
    dayslive: 11,
    hiringCampaignId: "61f43baa6e03d4971e17a0349"
  },
  {
    startdate: "2024-01-10",
    position: "Blockchain Developer",
    positionDescription:"We are seeking a talented Blockchain Developer to join our team and help us build innovative blockchain-based solutions. As a Blockchain Developer, you will be responsible for designing, developing, and deploying blockchain applications and smart contracts. You will collaborate with cross-functional teams to identify business requirements and implement scalable and secure blockchain solutions. The ideal candidate will have a strong understanding of blockchain technology, experience with blockchain platforms such as Ethereum or Hyperledger, and proficiency in programming languages like Solidity or Go.",
    experienceLevel:"Junior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "BlockTech",
    status: "In Progress",
    numberApplicants: 30,
    percentageCompleted: 20,
    metScoreCriteria: 8,
    dayslive: 5,
    hiringCampaignId: "61f43baa6e03d4971e17a034a"
  },
  {
    startdate: "2024-01-11",
    position: "Mobile Developer",
    positionDescription:"We are looking for a skilled Mobile Developer to design, develop, and maintain our organization's mobile applications. As a Mobile Developer, you will collaborate with cross-functional teams to translate business requirements into high-quality mobile solutions. You will be responsible for building user interfaces, implementing functionality, and optimizing mobile app performance. The ideal candidate will have experience with mobile development frameworks such as React Native or Flutter, a strong understanding of mobile UI/UX principles, and a passion for creating intuitive and engaging mobile experiences.",
    experienceLevel:"Junior-Level",
    skills:["JavaScript","React Native","Node.js"],
    companyName: "AppTech",
    status: "Complete",
    numberApplicants: 70,
    percentageCompleted: 85,
    metScoreCriteria: 26,
    dayslive: 18,
    hiringCampaignId: "61f43baa6e03d4971e17a034b"
  },
  {
    startdate: "2024-01-12",
    position: "Software Architect",
    positionDescription:"We are seeking a talented Software Architect to lead the design and implementation of our organization's software systems. As a Software Architect, you will work closely with stakeholders to understand business requirements, define system architecture, and guide the development team in building scalable and maintainable software solutions. You will be responsible for making strategic design decisions, defining coding standards, and ensuring architectural consistency across projects. The ideal candidate will have a strong background in software engineering, experience with cloud technologies, and excellent leadership and communication skills.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "CodeCraft",
    status: "Closed",
    numberApplicants: 95,
    percentageCompleted: 45,
    metScoreCriteria: 19,
    dayslive: 22,
    hiringCampaignId: "61f43baa6e03d4971e17a034c"
  },
  {
    startdate: "2024-01-13",
    position: "Product Manager",
    positionDescription:"We are looking for an experienced Product Manager to lead the development and launch of our organization's products. As a Product Manager, you will be responsible for defining product vision and strategy, gathering and prioritizing product requirements, and working closely with cross-functional teams to deliver successful product releases. You will analyze market trends, identify customer needs, and define product roadmaps to drive product innovation and growth. The ideal candidate will have a proven track record of product management success, strong analytical and problem-solving skills, and the ability to thrive in a dynamic and fast-paced environment.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "InnovatePro",
    status: "Draft",
    numberApplicants: 60,
    percentageCompleted: 65,
    metScoreCriteria: 12,
    dayslive: 19,
    hiringCampaignId: "61f43baa6e03d4971e17a034d"
  },
  {
    startdate: "2024-01-14",
    position: "System Administrator",
    positionDescription:"We are seeking a skilled System Administrator to manage and maintain our organization's IT infrastructure. As a System Administrator, you will be responsible for installing, configuring, and monitoring servers, networks, and other IT systems to ensure uptime and reliability. You will troubleshoot hardware and software issues, perform regular system backups, and implement security measures to protect against cybersecurity threats. The ideal candidate will have a solid understanding of IT systems and networks, experience with system administration tools, and the ability to work independently and as part of a team.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "SysAdmin Ltd.",
    status: "In Progress",
    numberApplicants: 52,
    percentageCompleted: 40,
    metScoreCriteria: 16,
    dayslive: 21,
    hiringCampaignId: "61f43baa6e03d4971e17a034e"
  },
  {
    startdate: "2024-01-15",
    position: "Quality Assurance Engineer",
    positionDescription:"We are looking for a detail-oriented Quality Assurance Engineer to ensure the quality and reliability of our organization's software products. As a Quality Assurance Engineer, you will be responsible for designing and executing test plans, identifying and documenting defects, and verifying software functionality against business requirements. You will collaborate with development teams to address issues and improve product quality throughout the software development lifecycle. The ideal candidate will have a strong understanding of QA methodologies, experience with automated testing tools, and excellent problem-solving and communication skills.",
    experienceLevel:"Mid-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "QATech",
    status: "Complete",
    numberApplicants: 48,
    percentageCompleted: 100,
    metScoreCriteria: 24,
    dayslive: 25,
    hiringCampaignId: "61f43baa6e03d4971e17a034f"
  },
  {
    startdate: "2024-01-16",
    position: "Technical Writer",
    positionDescription:"We are seeking a talented Technical Writer to create clear and concise documentation for our organization's products and services. As a Technical Writer, you will work closely with subject matter experts to gather information and translate complex technical concepts into user-friendly documentation, including user manuals, guides, and tutorials. You will ensure that documentation meets industry standards and complies with branding and style guidelines. The ideal candidate will have excellent writing and editing skills, a strong attention to detail, and the ability to communicate technical information effectively to diverse audiences.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "WriteTech",
    status: "Closed",
    numberApplicants: 33,
    percentageCompleted: 55,
    metScoreCriteria: 14,
    dayslive: 13,
    hiringCampaignId: "61f43baa6e03d4971e17a0350"
  },
  {
    startdate: "2024-01-17",
    position: "Database Administrator",
    positionDescription:"We are looking for an experienced Database Administrator to manage and optimize our organization's databases. As a Database Administrator, you will be responsible for designing and implementing database schemas, configuring and maintaining database servers, and ensuring data security and integrity. You will monitor database performance, troubleshoot issues, and implement backup and recovery procedures to minimize downtime. The ideal candidate will have a solid understanding of database management systems, experience with SQL and NoSQL databases, and the ability to optimize database performance and scalability.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "DataCorp",
    status: "Draft",
    numberApplicants: 77,
    percentageCompleted: 30,
    metScoreCriteria: 9,
    dayslive: 8,
    hiringCampaignId: "61f43baa6e03d4971e17a0351"
  },
  {
    startdate: "2024-01-18",
    position: "Business Analyst",
    positionDescription:"We are seeking a dynamic Business Analyst to drive business process improvement and innovation within our organization. As a Business Analyst, you will work closely with stakeholders to identify business needs, gather and analyze requirements, and develop solutions to meet strategic objectives. You will conduct market research, perform data analysis, and create business cases and proposals to support decision-making. The ideal candidate will have strong analytical and problem-solving skills, experience with business analysis methodologies, and the ability to communicate effectively with stakeholders at all levels.",
    experienceLevel:"Mid-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "BizTech",
    status: "In Progress",
    numberApplicants: 66,
    percentageCompleted: 80,
    metScoreCriteria: 21,
    dayslive: 17,
    hiringCampaignId: "61f43baa6e03d4971e17a0352"
  },
  {
    startdate: "2024-01-19",
    position: "Sales Engineer",
    positionDescription:"We are looking for a motivated Sales Engineer to support our sales team and drive revenue growth through technical expertise and customer engagement. As a Sales Engineer, you will work closely with sales representatives to understand customer requirements, deliver product demonstrations and presentations, and provide technical solutions and recommendations. You will serve as a technical liaison between customers and internal teams, helping to identify opportunities and address customer needs. The ideal candidate will have a strong technical background, excellent communication and presentation skills, and the ability to thrive in a fast-paced sales environment.",
    experienceLevel:"Junior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "SalesSolutions",
    status: "Complete",
    numberApplicants: 88,
    percentageCompleted: 10,
    metScoreCriteria: 7,
    dayslive: 4,
    hiringCampaignId: "61f43baa6e03d4971e17a0353"
  },
  {
    startdate: "2024-01-20",
    position: "DevOps Consultant",
    positionDescription:"We are seeking a knowledgeable DevOps Consultant to help us optimize our organization's development and operations processes. As a DevOps Consultant, you will work closely with cross-functional teams to implement DevOps best practices, automate deployment pipelines, and improve infrastructure scalability and reliability. You will collaborate with developers, system administrators, and other stakeholders to streamline workflows and drive continuous integration and delivery. The ideal candidate will have a strong background in DevOps methodologies, experience with cloud technologies and automation tools, and the ability to drive cultural and organizational change.",
    experienceLevel:"Senior-Level",
    skills:["Kubernetes","Node.js"],
    companyName: "ConsultingTech",
    status: "Closed",
    numberApplicants: 59,
    percentageCompleted: 45,
    metScoreCriteria: 11,
    dayslive: 23,
    hiringCampaignId: "61f43baa6e03d4971e17a0354"
  },
  {
    startdate: "2024-01-21",
    position: "IT Support Specialist",
    positionDescription:"We are looking for a proactive IT Support Specialist to provide technical assistance and support to our organization's end users. As an IT Support Specialist, you will troubleshoot hardware and software issues, install and configure software applications, and provide training and guidance to users as needed. You will also maintain IT documentation, track support tickets, and escalate complex issues to the appropriate teams. The ideal candidate will have strong customer service skills, a solid understanding of IT fundamentals, and the ability to prioritize and multitask in a fast-paced environment.",
    experienceLevel:"Senior-Level",
    skills:["JavaScript","React","Node.js"],
    companyName: "SupportNow",
    status: "Draft",
    numberApplicants: 41,
    percentageCompleted: 50,
    metScoreCriteria: 10,
    dayslive: 30,
    hiringCampaignId: "61f43baa6e03d4971e17a0355"
  }
];

export type CampaignStatus={
  pending:number,
  live:number,
  complete:number
}

export type CampaignUsage={
  remaining:number,
  used:number
}

/* export type CampaignState={
  hiringCampaignId:string,
  campaignStatus:CampaignStatus,
  campaignUsage:CampaignUsage
}
 */
/* export const State:CampaignState = {

} */