//"use client"

import { useGetUserHiringresource, useGetUserHiringresourceAccount } from "../../../../openapi/api/endpoints/default/default"
import { EditPendingUser } from "./EditPendingUser"
import { EditUser } from "./EditUser"


export function UserList() {
    const {data:loggedUser} = useGetUserHiringresource()
    const {data, isSuccess} = useGetUserHiringresourceAccount()

    let existingUsers = data?.filter((user)=>user.isValidated===true) 
    let pendingUsers = data?.filter((user)=>user.isValidated===false) 
  
    return (
        <>
            <p className="text-center text-black text-lg">EXISTING USERS</p>
            <div className="flex flex-col w-full">
                <p className="flex text-gray-500 text-destructive pb-2">User Name | Profile Type</p>
                <div>
                    {existingUsers?.map((user)=>{
                        if(user.user?._id !== loggedUser?._id){
                            return (
                                <div className="my-2">
                                    <EditUser user={user} isUserSuccess={isSuccess}/>
                                </div>
                            )
                        }else{
                            return <div></div>
                        }
                    })}
                </div>
            </div>
            <p className="text-center text-black text-lg">PENDING USERS</p>
            <div className="flex flex-col w-full">
                <p className="flex text-gray-500 text-destructive pb-2">Email</p>
                <div>
                    {pendingUsers?.map((user)=>{
                        if(user.user?._id !== loggedUser?._id){
                            return (
                                <div className="my-2">
                                    <EditPendingUser user={user}/>
                                </div>
                            )
                        }else{
                            return <div></div>
                        }
                    })}
                </div>
            </div>
        </>
    )
}