import { ColumnDef, Row } from "@tanstack/react-table"
import { MoreHorizontal } from "lucide-react"
import { Link } from "@tanstack/react-router"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { FilterCampaignDetailReponse } from "openapi/api/model"

export const columns:ColumnDef<FilterCampaignDetailReponse>[]=[
    {
        accessorKey:'username',
        header:({column}) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Name
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
        },
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("username")}</div>
        }
    },
    {
        accessorKey:'status',
        cell:({row})=>{
            return <div className="text-center font-medium">{row.getValue("status")}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Status
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          },
        //filterFn:'inNumberRange'
        filterFn:(row: Row<FilterCampaignDetailReponse>, columnId: string, filterValues: string[]) =>{
            return filterValues.includes(row.getValue(columnId))
        }
        },
    {
        accessorKey:'overallSolutionFitness',
        cell:({row})=>{
            return <div className="text-center font-medium">{(row.getValue("overallSolutionFitness") as number).toFixed()}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Overall Score
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        accessorKey:'CodingPrecision',
        cell:({row})=>{
            return <div className="text-center font-medium">{(row.getValue("CodingPrecision") as number).toFixed()}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Precision
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        accessorKey:'RelativeComplexity',
        cell:({row})=>{
            return <div className="text-center font-medium">{(row.getValue("RelativeComplexity") as number).toFixed()}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Simplicity
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        accessorKey:'uniquenessOfCode',
        cell:({row})=>{
            return <div className="text-center font-medium">{(row.getValue("uniquenessOfCode") as number).toFixed()}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Uniqueness
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        accessorKey:'documentationClarity',
        cell:({row})=>{
            return <div className="text-center font-medium">{(row.getValue("documentationClarity") as number).toFixed()}</div>
        },
        header: ({ column }) => {
            return (
                <div className="flex justify-center">
                    <Button
                        variant="ghost"
                        className="justify-between"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        >
                        Documentation
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </div>
              
            )
          }
    },
    {
        id:"actions",
        cell:({row})=>{
            const candidate = row.original
            return (
                <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="bg-white">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        {/* <DropdownMenuItem
                        onClick={() => navigator.clipboard.writeText(candidate.data?.email as string)}
                        >
                        Copy Candidate Email
                        </DropdownMenuItem> */}
                    <DropdownMenuSeparator /> 
                    <DropdownMenuItem>
                        <Link 
                        to={'/allCampaigns/$hiringCampId/$candidateSub'}
                        from={'/allCampaigns/$hiringCampId'}
                        params={{candidateSub:candidate.sub, hiringCampId: candidate.hiringCampaignId}}>
                        Go to profile
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>Mark as Favorite</DropdownMenuItem>
                </DropdownMenuContent>
                </DropdownMenu>
            )
        }
    }
]