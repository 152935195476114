import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button";
import { useDeletePermissionsId, useDeleteUserHiringresourceUserId } from "../../../../openapi/api/endpoints/default/default";
import { AccountUsers } from "openapi/api/model";
import { toast } from "sonner";
import { queryClient } from "@/App";

  
const DeleteUser = (user:{user?:AccountUsers}) => {

    const {mutate:deleteUserMutate, isSuccess:isUserDeleted, isError:userNotDeleted, error:userError } = useDeleteUserHiringresourceUserId({
      mutation: {
        onSettled: ()=>{
          queryClient.invalidateQueries({queryKey:  [`/user-hiringresource/account`]})
        }
      }
    })
    const {mutate:deletePermissionMutate, isSuccess:isPermissionDeleted, isError:permissionNotDeleted, error:permissionError } = useDeletePermissionsId()

    const deleteUser =() =>{
        deleteUserMutate({userId:user.user?.user?._id as string})
        deletePermissionMutate({id:user.user?._id as string})
        if (isUserDeleted&&isPermissionDeleted){
            console.log('Success');
            toast.success("Submitted!",{
              description: 'User deleted successfully',
              action: {
                label: "Dismiss",
                onClick: () => {},
              },
              style: {
                background: '#a3e635'
              }
            })
          }
          if (userNotDeleted||permissionNotDeleted){
            if(userNotDeleted){
                console.log('Error: ',userError);
                toast.error("An error occured!",{
                description: `${userError}`,
                action: {
                    label: "Dismiss",
                    onClick: () => {},
                },
                style: {
                    background: '#dc2626'
                }
                })
            }
            else {
                console.log('Error: ',permissionError);
                toast.error("An error occured!",{
                description: `${permissionError}`,
                action: {
                    label: "Dismiss",
                    onClick: () => {},
                },
                style: {
                    background: '#dc2626'
                }
                })
            }
            
          }
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
            <Button type="submit" className="bg-mint100 text-black hover:bg-teal100">Delete user</Button>
            </AlertDialogTrigger>
            <AlertDialogContent className=" bg-gray-100 text-black">
                <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription className=" text-black">
                Are you sure you want to delete this User? 
                This action cannot be undone.
                </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                <AlertDialogCancel asChild className="text-black">
                <Button className=" hover:bg-stone-800 hover:text-white">Cancel</Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild >
                    <Button onClick={deleteUser} className="bg-mint100 text-black hover:bg-teal100">Continue</Button>
                </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>

    )
  }

export default DeleteUser;