import SmallText from "@/components/ui/SmallText";
import { Button } from "@/components/ui/button"

const BillingInfoForm =() =>{


    return (
        <div className="flex flex-col m-2 p-4 space-y-4 items-start">
              <SmallText textStyle="flex text-start space-x-1 pb-2">
                <p className="font-bold text-destructive">Subscription Type:</p> <p className="text-destructive pl-2 pr-4">Professional</p> <Button size={'sm'} className="bg-mint100 text-black hover:bg-teal100">Change</Button> 
              </SmallText>
              <SmallText textStyle="flex space-x-3 pb-2">
                <p className="text-destructive">{} Users |</p> <p className="text-destructive">{} Campaigns |</p> <p className="text-destructive">{} Evaluations</p> <Button size={'sm'} className="bg-mint100 text-black hover:bg-teal100">Purchase More</Button> 
              </SmallText>
              <SmallText textStyle="flex space-x-3 text-lg pb-2">
                <p className="text-destructive">Billing Info: </p> <Button size={'sm'} className="bg-mint100 text-black hover:bg-teal100">Update Card On File</Button> 
              </SmallText>
              <SmallText textStyle="flex space-x-3 text-lg">
                <p className="text-destructive">Name on card: </p> 
              </SmallText>
              <SmallText textStyle="flex space-x-3 text-lg">
                <p className="text-destructive">Last 4 digits on card: </p> 
              </SmallText>
        </div>
    )
}

export default BillingInfoForm;