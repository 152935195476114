//"use client"
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { NewUserSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetUserHiringresource, usePostPermissions } from "../../../../openapi/api/endpoints/default/default";
import { HrUser, Permission, AccessRights, AccessRightsPermissions } from "openapi/api/model";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { Toaster } from "@/components/ui/sonner";
import { Label } from "@/components/ui/label";

type NewUserFormValues = z.infer<typeof NewUserSchema>

export function NewUserForm() {
    //const [disabled,setDisabled]=useState(false)
    const{data:user}=useGetUserHiringresource()
    const userData=user as HrUser
    console.log(userData)
    const {mutate, isSuccess, error, data } = usePostPermissions({
        mutation:{
            onSuccess:()=>{
                console.log('Success: ',isSuccess);
                toast.success("Submitted!",{
                    description: 'New user created successfully',
                    action: {
                        label: "Dismiss",
                        onClick: () => {},
                    },
                    style: {
                        background: '#a3e635'
                    }
                })
            },
            onError:()=>{
                console.log('Error: ',error);
                toast.error("An error occured!",{
                    description: `${error}`,
                    action: {
                        label: "Dismiss",
                        onClick: () => {},
                    },
                    style: {
                        background: '#dc2626'
                    }
                })
            }
        }
    })
    isSuccess && console.log('Permission id: ',data)
    const permissionID = (data as any)?.permissionId as string
    const currentUrl = window.location.href;
    //const {} = useGetPermissionsId()
    const form = useForm<NewUserFormValues>({
        resolver: zodResolver(NewUserSchema),
    });
  
    const onSubmit=(data:any)=>{
        //post to db
        const submittedData = data as NewUserFormValues
        const permissions:AccessRights[] = [
            {
                permissions:{
                    read:true, 
                    write:!!submittedData.write //|| disabled
                } as AccessRightsPermissions,
                resourceName:"hiringcampaigns"
            }
        ] 
        
        const permission:Permission={
            email:submittedData?.email as string,
            isAdmin:true && !!submittedData.write,
            licenseId:user?.licenseId as string,
            permissions:permissions
        }

        mutate({data:permission})
        
    }

    return (
        <div className="flex flex-col w-full bg-white border rounded-md p-8">
            <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col items-center space-y-4 ">        
                <div className="grid grid-cols-2 w-full space-x-16">
                    <div className="grid grid-rows-2 pt-4">
                        <FormField
                        control={form.control}
                        name="email"
                        render={() => (
                            <FormItem>
                                <FormLabel>New user email:</FormLabel>
                                <FormControl>
                                    <Input placeholder="Email"
                                    {...form.register('email')}  />
                                </FormControl>
                                {form.formState.errors["email"] && (
                                    <FormMessage className="text-red-500">
                                        {form.formState.errors["email"].message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        )}
                        />
                        {/* <FormField
                        control={form.control}
                        name="position"
                        render={({ field }) => (
                            <FormItem className="flex flex-col items-start justify-between p-2 mt-2">
                                <FormLabel>Position:</FormLabel>
                                <FormControl>
                                    <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex flex-row space-y-1">
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="Manager" onClick={()=>{
                                                    form.setValue('write',true)
                                                    form.setValue('read',true)
                                                    setDisabled(true)
                                                }}/>
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                Manager
                                            </FormLabel>
                                        </FormItem>
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="User" onClick={()=>{
                                                    //form.setValue('write',false)
                                                    //form.setValue('read',false)
                                                    //form.getFieldState('write')
                                                    setDisabled(false)
                                                    console.log('Form State: ',form.getValues('write'))
                                                }}/>
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                User
                                            </FormLabel>
                                        </FormItem>
                                    </RadioGroup>
                                </FormControl>
                                {form.formState.errors["position"] && (
                                    <FormMessage className="text-red-500">
                                        {form.formState.errors["position"].message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        )}
                        /> */}
                    </div>
                    <div className="grid grid-rows-2 pt-4">
                        <FormField
                        control={form.control}
                        name="write"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between p-2">
                                <div className="">
                                    <FormLabel>Can create campaigns</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                    //disabled={disabled}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                        />
                        {/* <FormField
                        control={form.control}
                        name="read"
                        render={({ field }) => (
                            <FormItem className="flex flex-row items-center justify-between p-2">
                                <div className="">
                                    <FormLabel>Can see all campaigns</FormLabel>
                                </div>
                                <FormControl>
                                    <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                    disabled={disabled}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                        /> */}
                        
                    </div>
                </div>
                <Button type="submit" className="bg-mint100 text-black hover:bg-teal100">Save</Button>
            </form>
            </Form>
            {isSuccess && 
                <div className="py-8">
                    <Label className="text-destructive">Share the following link to your new user:</Label>
                    <Input value={`${currentUrl.split("/profile")[0]}/newUser/${permissionID}`}/>
                </div>
            }
            <Toaster/>
        </div>
    )
}