import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { FileText } from 'lucide-react'
import { UseFormReturn } from 'react-hook-form'


const PositionDescriptionInput = ({form,disabled}:{form:UseFormReturn<any>,disabled?:boolean}) => {
    return (
        <FormField
            control={form.control}
            name="positionDescription"
            render={() => (
                <FormItem className="space-y-2">
                    <Label htmlFor="positionDescription" className="text-base font-semibold flex items-center gap-2">
                        <FileText className="w-5 h-5" />
                        Position Description
                    </Label>
                    <FormControl>
                        <Textarea
                            className={`border rounded-lg ${
                                form.formState.errors["positionDescription"] ? "border-red-500" : "border"
                            }`}
                            placeholder="Provide the details of the role that the candidates will see"
                            {...form.register("positionDescription")}
                            disabled={disabled}
                            />
                    </FormControl>
                    {form.formState.errors["positionDescription"] && (
                        <FormMessage className="text-red-500"/>      
                    )}
                    {/* <div className="text-sm text-muted-foreground text-right">
                        {form.getValues("positionDescription").length}/500 characters
                    </div> */}
                </FormItem>
            )}
        />
    )
}

export default PositionDescriptionInput