
export const skills = [
    /* {
      id: "python",
      label: "Python",
    }, */
    /* {
      id: "java",
      label: "Java",
    }, */
    {
      id: "backend-typescript",
      label: "Backend-Typescript",
    },
    {
      id: "frontend-react",
      label: "Frontend-React",
    },
    {
      id: "frontend-angular",
      label: "Frontend-Angular",
    },
    {
      id: "frontend-vue",
      label: "Frontend-Vue",
    },
    /* {
      id: "devops",
      label: "DevOps-DevOps",
    },
    {
      id: "docker",
      label: "DevOps-Docker",
    },
    {
      id: "jenkins",
      label: "DevOps-Jenkins",
    },
    {
      id: "terraform",
      label: "DevOps-TerraForm",
    }, 
    {
      id: "git",
      label: "DevOps-Git",
    },
    {
      id: "grafana",
      label: "DevOps-Grafana",
    },
    {
      id: "kubernetes",
      label: "DevOps-Kubernetes",
    },
    {
      id: "linux",
      label: "DevOps-Linux",
    }, 
    {
      id: "prometheus",
      label: "DevOps-Prometheus",
    },
    {
      id: "python",
      label: "DevOps-Python",
    },  */
    /* {
      id: "golang",
      label: "Golang",
    } */
] 
  
export const roles = [
    "Front-End Developer", 
    "Back-End Developer", 
    //"DevOps Engineer",
    /* "Full-Stack Developer", 
    "Solutions Architect", 
    "DevOps Engineer" */
]
  
export const levels = [
    "Entry-level (0-1 years)",
    "Junior-level (1-3 years)",
    "Mid-level (3-5 years)",
    "Senior-level (5+ years)",
];
  