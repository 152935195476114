/**
 * Hooks that run after cognito login
 */

import { useEffect } from "react"
import { useGetUserHiringresource } from "../../openapi/api/endpoints/default/default"
import { useGetPermissionsHeader } from "./useGetPermissionIdUnAuth"
/**
 * 1-Gets Hr
 * 2-Gets permissions
 */
const useOnAuth = ({onAuthSuccess}:{onAuthSuccess?:()=>void}) => {
    const {data, isSuccess, refetch:hrRefetch}=useGetUserHiringresource({
        query:{
            enabled:false,
            staleTime: 5 * 60 * 1000, // Example: 5 minutes
        }
    })
    const {isSuccess:permissionSuccess,isLoading:permissionLoading,refetch,error}=useGetPermissionsHeader({licenseId:data?.licenseId??''})
    
    console.log({permissionsError:error})
    useEffect(()=>{
        console.log('Trying to trigger permissions header',{isSuccess,data})
        if(isSuccess && data){
            refetch()
        }
    },[isSuccess,data])
    useEffect(()=>{
        if(permissionSuccess&&onAuthSuccess){
            onAuthSuccess()
        }
    },[permissionSuccess])
    return {
        permissionLoading,permissionSuccess,hrRefetch
    }
}

export default useOnAuth