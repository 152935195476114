import { Card, CardContent } from "@/components/ui/card";
//import InfoCard from "./InfoCard";

export type StatusProps={
  //children:React.ReactNode,
  title?:string,
  value?:number,
  cardStyle?:string,
  cardContentStyle?:string
}

const StatusCard = ({
  title,
  value,
  cardStyle,
  cardContentStyle
}:StatusProps) => {
  
    return (  
    <div className="flex flex-col p-4">
        <Card className={`flex justify-center content-center w-full p-2 bg-white ${cardStyle}`}>
            <CardContent className={`flex p-2 ${cardContentStyle}`}>
                <p className="text-5xl text-center break-words max-xl:truncate">{value}</p>
            </CardContent>
        </Card>
        <p className="flex justify-center text-md text-center p-2 break-words max-xl:truncate">{title}</p>
    </div>      
    
    ) 
}

export default StatusCard;
