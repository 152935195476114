import React from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"


interface TabToggleProps {
    tab1Label: string
    tab1Content: React.ReactNode
    tab2Label: string
    tab2Content: React.ReactNode
  }
  
  export function TabToggle({ tab1Label, tab1Content, tab2Label, tab2Content }: TabToggleProps) {
    return (
      <Tabs defaultValue="tab1" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="tab1">{tab1Label}</TabsTrigger>
          <TabsTrigger value="tab2">{tab2Label}</TabsTrigger>
        </TabsList>
        <TabsContent value="tab1" className="mt-6">
          {tab1Content}
        </TabsContent>
        <TabsContent value="tab2" className="mt-6">
          {tab2Content}
        </TabsContent>
      </Tabs>
    )
  }
  
  