/* export type MetricInfo = {
    OverallCodeExcellence: Object,
    CodingPrecision: Object,
    SimplicityofCode: Object,
    UniquenessofCode: Object,
    DocumentationClarity: Object
}
 */

export type MetricInfo = {
    definition: string,
    benefit: string
}

/* const OverallCodeExcellence:MetricInfo={
    definition: "This metric is like an overall health check for the code submitted by the developer. A high score here means that the code is well-built and less likely to have problems that could disrupt service or frustrate users.",
    benefit: "Why it matters: when choosing developers, this score helps you pick those whose work will meet your customers' demand for quality and reliability."
}
const CodingPrecision:MetricInfo={
    definition: "Consider this as a measure of how meticulous the developer is. Fewer issues in the code signify that the developer pays close attention to detail and follows best practices, reducing the need for costly fixes later.",
    benefit: "Why it matters:  this metric indicates a candidate's ability to produce clean, trouble-free code right from the start."
}
const SimplicityofCode:MetricInfo={
    definition: "Easy-to-understand code is less costly and time-consuming to manage. This score tells you if the code is written in a way that is straightforward, making it easier for any developer to work on it in the future.",
    benefit: "Why it matters:  this metric suggests how well a developer can write code that's easy for the entire team to use and maintain."
}
const UniquenessofCode:MetricInfo={
    definition: "Unique code means the developer is good at writing original code that doesn't needlessly repeat itself, which is important for efficient and effective software development.",
    benefit: "Why it matters: a high score in this area indicates a developer who can innovate and produce work that contributes to a leaner and more manageable codebase."
}
const DocumentationClarity:MetricInfo={
    definition: "This is about how well the developer communicates in their code through comments and documentation. Clear documentation means less confusion for others who need to work with the code later, saving time and reducing misunderstandings.",
    benefit: "Why it matters:  this score helps identify developers who not only code well but also document their work for the benefit of their team and future maintenance."
}
 */
//export const metricInfo = [OverallCodeExcellence,CodingPrecision,SimplicityofCode,UniquenessofCode,DocumentationClarity]
    
export const metricInfo = {
    OverallCodeExcellence:{
        definition: "This metric is like an overall health check for the code submitted by the developer. A high score here means that the code is well-built and less likely to have problems that could disrupt service or frustrate users.",
        benefit: "When choosing developers, this score helps you pick those whose work will meet your customers' demand for quality and reliability."
    },
    CodingPrecision:{
        definition: "Consider this as a measure of how meticulous the developer is. Fewer issues in the code signify that the developer pays close attention to detail and follows best practices, reducing the need for costly fixes later.",
        benefit: "This metric indicates a candidate's ability to produce clean, trouble-free code right from the start."
    },
    SimplicityofCode:{
        definition: "Easy-to-understand code is less costly and time-consuming to manage. This score tells you if the code is written in a way that is straightforward, making it easier for any developer to work on it in the future.",
        benefit: "This metric suggests how well a developer can write code that's easy for the entire team to use and maintain."
    },
    UniquenessofCode:{
        definition: "Unique code means the developer is good at writing original code that doesn't needlessly repeat itself, which is important for efficient and effective software development.",
        benefit: "A high score in this area indicates a developer who can innovate and produce work that contributes to a leaner and more manageable codebase."
    },
    DocumentationClarity:{
        definition: "This is about how well the developer communicates in their code through comments and documentation. Clear documentation means less confusion for others who need to work with the code later, saving time and reducing misunderstandings.",
        benefit: "This score helps identify developers who not only code well but also document their work for the benefit of their team and future maintenance."
    }
}
    
    
    
