//import { ovr } from "@/mockData/ChartData"
import Dashboard from "../Overview/Dashboard";
import { Route, useParams } from "@tanstack/react-router";
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { manageAccessToken } from "@/utils/Auth/Session";
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login";
import {
  useGetHiringcampaignsId,
  useGetHiringresultsSearch,
} from "../../../openapi/api/endpoints/default/default";
import { HiringResultResultsMacro } from "../../../openapi/api/model/hiringResultResultsMacro";
//import { candidates, hiringProcesses } from "../EntrepriseOverview/utils/MockData"
import { CandidateDetailReponses } from "openapi/api/model/candidateDetailReponses";
import { MoreCandidateDetail } from "../EntrepriseOverview/utils/MoreCandidateMockData";

export type OverviewProps = {
  header: string | undefined;
  chartData: HiringResultResultsMacro | undefined;
  averages: number[];
};

const CandidateDetails = () => {
  const { candidateSub, hiringCampId } = useParams({
    from: "/allCampaigns/$hiringCampId",
  });

  console.log("Sub, HC: ", candidateSub.sub, hiringCampId);

  /* const mockCandidateData = candidates.find((candidate) =>{
    return candidate.sub === candidateID;
  })
  const mockHiringCampaign = hiringProcesses.find((hiringCamp) =>{
    return hiringCamp.hiringCampaignId === hiringCampId;
  }) */
  const { data: hiringCampaignInfo } = useGetHiringcampaignsId(hiringCampId);
  const { data: hiringRslt } = useGetHiringresultsSearch({
    hiringCampaignId: hiringCampId,
    sub: candidateSub,
    HR_format: "candidateDetail",
  });

  const hiringResult = hiringRslt as CandidateDetailReponses;
  console.log("HR: ", hiringResult);
  console.log("HR data: ", hiringResult?.candidateData);
  console.log("Info: ", hiringCampaignInfo);
  const candidate = hiringResult?.candidateData;
  console.log("cdata: ", hiringResult);
  //const userData = hiringResult?.results.macro ?? ovr
  const codeViolation = (candidate ? candidate[0].violations : 0) as number;
  const documented = (candidate ? candidate[0].Documented : 0) as number;
  const dataChart: MoreCandidateDetail = {
    overallCodeExcellence: [
      {
        issuesScore: parseInt(
          ((candidate ? candidate[0].issues_score : 0) as number).toFixed()
        ),
        documentationScore: parseInt(
          ((candidate ? candidate[0].Documentation_Score : 0) as number).toFixed()
        ),
        complexityScore: parseInt(
          ((candidate ? candidate[0].ComplexityScore : 0) as number).toFixed()
        ),
        duplicationScore: parseInt(
          ((candidate ? candidate[0].Duplication_Score : 0) as number).toFixed()
        ),
      },
      {
        issuesScore: parseInt(
          ((hiringResult ? hiringResult.maxIssues_score : 0) as number).toFixed()
        ),
        documentationScore: parseInt(
          ((hiringResult ? hiringResult.maxDocumentation_Score : 0) as number).toFixed()
        ),
        complexityScore: parseInt(
          ((hiringResult ? hiringResult.maxComplexityScore : 0) as number).toFixed()
        ),
        duplicationScore: parseInt(
          ((hiringResult ? hiringResult.maxDuplication_Score : 0) as number).toFixed()
        ),
      },
    ],

    codingPrecision: [
      {
        blocker_violations: parseInt(
          ((candidate ? candidate[0].blocker_violations : 0) as number).toFixed()
        ),
        critical_violations: parseInt(
          ((candidate ? candidate[0].critical_violations : 0) as number).toFixed()
        ),
        major_violations: parseInt(
          ((candidate ? candidate[0].major_violations : 0) as number).toFixed()
        ),
        minor_violations: parseInt(
          ((candidate ? candidate[0].minor_violations : 0) as number).toFixed()
        ),
        info_violations: parseInt(
          ((candidate ? candidate[0].info_violations : 0) as number).toFixed()
        ),
      },
      {
        blocker_violations: parseInt(
          (
            (hiringResult ? hiringResult.maxBlockerViolations : 0) as number
          ).toFixed()
        ),
        critical_violations: parseInt(
          (
            (hiringResult ? hiringResult.maxCriticalViolations : 0) as number
          ).toFixed()
        ),
        major_violations: parseInt(
          (
            (hiringResult ? hiringResult.maxMajorViolations : 0) as number
          ).toFixed()
        ),
        minor_violations: parseInt(
          (
            (hiringResult ? hiringResult.maxMinorViolations : 0) as number
          ).toFixed()
        ),
        info_violations: parseInt(
          (
            (hiringResult ? hiringResult.maxInfoViolations : 0) as number
          ).toFixed()
        ),
      },
    ],

    simplicityOfCode: [
      {
        complexity: parseInt(
          (
            (candidate ? candidate[0].ComplexityScoreSimplicity : 0) as number
          ).toFixed()
        ),
        cognitive_complexity: parseInt(
          (
            (candidate ? candidate[0].cognitive_complexity : 0) as number
          ).toFixed()
        ),
      },
      {
        complexity: parseInt(
          (
            (hiringResult
              ? hiringResult.maxComplexityScoreSimplicity
              : 0) as number
          ).toFixed()
        ),
        cognitive_complexity: parseInt(
          (
            (hiringResult ? hiringResult.maxCognitiveComplexity : 0) as number
          ).toFixed()
        ),
      },
    ],

    codeMaitainability: [
      {
        cleanCode: parseInt(
          (codeViolation ? 100 - codeViolation : 0).toFixed()
        ),
        codeViolations: parseInt((codeViolation ?? 0).toFixed()),
      },
      {
        cleanCode: parseInt(
          (codeViolation ? 100 - codeViolation : 0).toFixed()
        ),
        codeViolations: parseInt((codeViolation ?? 0).toFixed()),
      },
    ],
    uniquenessOfCode: {
      duplicateCode: (candidate ? candidate[0].duplicatecode : 0) as number,
      uniqueCode: (candidate ? candidate[0].uniquecode : 0) as number,
    },
    documentationClarity: {
      documented: documented ?? 0,
      undocumented: documented ? 100 - documented : 0,
    },
    overallSolutionFitness: candidate ? candidate[0].overallSolutionFitness : 0,
    rank: (candidate ? candidate[0].ranking : 0) as number,
    status: candidate ? candidate[0].status : "",
  };

  return (
    <Dashboard
      header={candidate ? candidate[0].username : ""}
      email={candidate ? candidate[0].email : ""}
      chartData={dataChart}
      companyName={hiringCampaignInfo?.companyName ?? ""}
      skillset={hiringCampaignInfo?.skills ?? [""]}
      roleDesc={hiringCampaignInfo?.positionDescription ?? ""}
      experienceLvl={hiringCampaignInfo?.experienceLevel ?? ""}
      averages={[8.1, 6.2, 7.3, 9.5]}
      ranking={candidate ? candidate[0].ranking : 0}
    />
  );
};

const CandidateDetailseRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/allCampaigns/$hiringCampId/$candidateSub",
  component: CandidateDetails,

  beforeLoad: async ({ context: { auth, queryClient } }) => {
    const session = await manageAccessToken({ AuthPayload: auth });
    if (!session) {
      //no token means that token is still good
      return;
    }
    const newAuthPayload = {
      ...auth,
      session,
    } as AuthenticateUserResp;
    queryClient.setQueryData(["Auth"], newAuthPayload);
    return {
      auth: newAuthPayload,
    };
  },
});

export default CandidateDetailseRoute;
