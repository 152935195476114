/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthCard } from "@/components/Auth/AuthCard"
import FormElements from "@/components/ui/FormElements"
import { useSignIn } from "@/utils/Auth/Session";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { confirmPassword } from "../AuthUtils/ForgotPassword";
import { useForgotPassword } from "@/utils/Auth/Password";
import { Input } from "@/components/ui/input";
import { CardWithNoFooter } from "@/components/ui/CardWithNoFooter";
import { Button } from "@/components/ui/button";
import { NewPasswordSchema } from "@/utils/FormUtils/FormSchema";

export const ForgotPassword = () => {
  const navigate=useNavigate({from:'/auth'})
  const queryClient=useQueryClient()
  const { register, handleSubmit, formState: {errors}, getValues } = useForm<any>({});
  const { register:verificationCodeRegister, handleSubmit:verificationCodeSubmit, getValues:getCodeValue  } = useForm()
  const { register:confirmPasswordRegister, handleSubmit:confirmPasswordSubmit, formState: {errors:pwdErrors}  } = useForm<z.infer<typeof NewPasswordSchema>>({
    resolver: zodResolver(NewPasswordSchema),
    defaultValues: {
      newPassword: "",
    }
  })
  const [codeSubmitted,setCodeSubmitted] = useState(false)
  const {mutate:Login,isSuccess:loginSuccess}=useSignIn({queryClient})
  const {mutate:ForgotPassword,isSuccess}=useForgotPassword() 
  const _triggerSubmit = () => {
    submitRef?.current?.click();
  };
  const submitRef = useRef(null) as any

  const onSubmit = ({userName}:{userName:string}) => {
    ForgotPassword({
      username:userName,
    })
    
  };

  if(loginSuccess && queryClient.getQueryData(['Auth'])){
    navigate({to:'/profile'})
  }

  const passwordSubmit = async(data:any) => {
    const username = getValues('userName')
    const code = getCodeValue('code')
    console.log(username,code,data.newPassword as string)
    try{
      await confirmPassword({username:username,verificationCode:code,newPassword:data.newPassword})
      Login({userName:username,password:data.newPassword})
      toast.success("Password reset successfully!",{
        description: 'Your password has been reset, please try logging in',
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#a3e635'
        }
      })
    }catch(e){
      toast.success("Failed to create new password",{
        description: 'Wrong confirmation code, please try again',
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  }

  const codeSubmit = (data:any) => {
    //const username = getValues('userName')
    console.log("Code is: ",data)
    setCodeSubmitted(true)
  };

  if(codeSubmitted){
    return(
      <>
        <CardWithNoFooter 
        cardTitle="Password Reset"
        cardDescription="Please enter your new password.">
          <form onSubmit={confirmPasswordSubmit(passwordSubmit)} className="flex flex-col space-y-1.5">
            <Input 
                id={'newPassword'} 
                type={'password'} 
                {...confirmPasswordRegister('newPassword')} 
                name={'newPassword'}
                className='border-primary100 border-2 rounded-xl text-center'/>
                {pwdErrors['newPassword'] && pwdErrors['newPassword'].message && <p className="text-error text-sm">{pwdErrors['newPassword'].message}</p>}
              <Button type='submit' className="rounded-full bg-primary200 px-16">Confirm</Button>
          </form>
        </CardWithNoFooter>
      </>
    )
  }

  if(isSuccess){
    console.log(isSuccess)
    return(
      <>
        <CardWithNoFooter 
        cardTitle="Password Reset"
        cardDescription="A verification code has been sent to your email. Please enter it below.">
          <form onSubmit={verificationCodeSubmit(codeSubmit)} className="flex flex-col space-y-1.5">
          <Input 
              id={'code'} 
              type={'text'} 
              {...verificationCodeRegister('code')} 
              name={'code'}
              className='border-primary100 border-2 rounded-xl text-center'/>
            <Button type='submit' className="rounded-full bg-primary200 px-16">Confirm</Button>
          </form>
        </CardWithNoFooter>
      </>
    )
    
  }
  
  
  return (
    <AuthCard 
    cardTitle="Password Reset"
    buttonText="Continue"
    cardDescription="Enter your the username associated to your account and we'll send you a link to reset your password"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormElements
        errors={errors}
        formMembers={[
          {id:"userName",label:"Username",type:"text",register,name:"userName"},
      ]}/>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>

    </AuthCard>
  )
}