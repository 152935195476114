import { createContext, useState } from 'react';

export const AuthContext = createContext({
    confirmationStage: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setConfirmationStage: (_confirmationStage:boolean)=>{},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContextProvider = ({children}: any) => {
    const [authUserConfirmationStage, setAuthUserConfirmationStage] = useState(false);
    const setConfirmationStage = (confirmationStage:boolean) => {
        setAuthUserConfirmationStage(confirmationStage);
    }

    const value = {
        confirmationStage: authUserConfirmationStage,
        setConfirmationStage: setConfirmationStage,
    }

    return(
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;