import * as z from "zod"
// Regex patterns for password validation
const passwordRegex = {
    number: /\d/,                     // at least one digit
    uppercase: /[A-Z]/,               // at least one uppercase letter
    lowercase: /[a-z]/,               // at least one lowercase letter
    // specialChar: /[^A-Za-z0-9]/,   // Uncomment this if you decide to require a special character
  };

export const SignupSchema = z.object({
  userName: z.string().min(3, 'Username must be at least 3 characters long'),
  email: z.string()
    .email('Invalid email address'), // Validates the email
  password: z.string()
    .min(8, 'Password must be at least 8 characters long')
    .regex(passwordRegex.number, 'Password must contain at least 1 number')
    .regex(passwordRegex.uppercase, 'Password must contain at least 1 uppercase letter')
    .regex(passwordRegex.lowercase, 'Password must contain at least 1 lowercase letter'),
    // .regex(passwordRegex.specialChar, 'Password must contain at least 1 special character'), // Uncomment if special character is required
  confirmPassword: z.string(),
  firstName:z.string().min(1, 'First name must be at least 1 character long'),
  lastName:z.string().min(1, 'Last name must be at least 1 character long'),
  phone:z.string()
    .min(10, 'Phone number must be 10 characters long')
    .optional(),
  company:z.string().min(1, 'Please enter your company name'),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword'], // Error message is attached to confirmPassword field
});

export const LoginSchema = z.object({
  userName: z.string().min(3, 'Username must be at least 3 characters long'),
  password: z.string()
  .min(8, 'Password must be at least 8 characters long')
  .regex(passwordRegex.number, 'Password must contain at least 1 number')
  .regex(passwordRegex.uppercase, 'Password must contain at least 1 uppercase letter')
  .regex(passwordRegex.lowercase, 'Password must contain at least 1 lowercase letter'),
  // .regex(passwordRegex.specialChar, 'Password must contain at least 1 special character'), // Uncomment if special character is required
});


export const NewPasswordSchema = z.object({
  newPassword: z.string()
  .min(8, 'Password must be at least 8 characters long')
  .regex(passwordRegex.number, 'Password must contain at least 1 number')
  .regex(passwordRegex.uppercase, 'Password must contain at least 1 uppercase letter')
  .regex(passwordRegex.lowercase, 'Password must contain at least 1 lowercase letter'),
  // .regex(passwordRegex.specialChar, 'Password must contain at least 1 special character'), // Uncomment if special character is required
});

export const NewUserSchema = z.object({
  firstName: z.string()
    //.min(1, 'First name must be at least 1 character 1 long')
    .optional(),
  lastName: z.string()
    //.min(1, 'Last name must be at least 1 character 1 long')
    .optional(),
  email: z.string()
    .email('Invalid email address'), // Validates the email
  write: z.boolean()
    .optional(),
 /*  read: z.boolean()
    .optional(),
  position: z.string()
    .min(1, {
      message: 'Required'
    }), */
});

export const PendingUserSchema = z.object({
  email: z.string()
    .email('Invalid email address'), // Validates the email
  write: z.boolean()
    .optional(),
 
});



export const HiringCampaignFormSchema = z.object({
  position: z
    .string()
    .min(1, {
      message: "Position title cannot be blank",
    })
    .max(50, {
      message: "Position title is too long",
    }),
  positionDescription: z
    .string()
    .min(1, {
      message: "Role description cannot be blank",
    })
    .max(1000, {
      message: "Role must be at most 1000 characters.",
    }),
  companyName: z
    .string()
    .min(1, {
      message: "Company name must be at least 1 character.",
    })
    .max(50, {
      message: "Company name must not be longer than 50 characters.",
    }),
  experienceLevel: z
    .string()
    .min(5, {
      message: "Experience must be selected",
    })
    .max(30, {
      message: "Experience must be selected",
    }),
  startOfCampaignDate: z.date({
    required_error: "A start date is required.",
  }),
  endOfCampaignDate: z.date({
    required_error: "An end date is required.",
  }),
  skills: z.array(z.string()).refine((value) => value.some((skill) => skill), {
    message: "Tech Stack must be selected",
  }),
  //minimumScore: z.string()
});