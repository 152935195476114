import { CardTitle, Card, CardHeader, CardContent } from "@/components/ui/card";
//import BasicBarChart from "@/components/ui/MuiBarChart";
import { Title } from "@/components/ui/Title";
import BasicChart from "../../BarChart";
import BasicBarChart from "../../MuiBarChart";

export type CustomBarChartProps={
  //children:React.ReactNode,
  title?:string,
  values?:number[],
  maxValues?:number[],
  labels:string[],
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardDescriptionStyle?:string,
  cardContentStyle?:string
}

const CustomBarChart = ({
  title,
  values,
  maxValues,
  labels,
  cardStyle,
  cardContentStyle
}:CustomBarChartProps) => {

    return (  
    <div className="flex flex-col p-0">
        <Card className={`flex flex-col m-4 p-4 bg-white ${cardStyle}`}>
            <CardHeader className="h-1/4 pt-2 pl-2">
              <CardTitle>
                <Title>{title}</Title>
              </CardTitle>
            </CardHeader>
            <CardContent className={`flex flex-col justify-center items-center justify-items-center ${cardContentStyle}`}>
              {maxValues? <BasicBarChart data={values} maxData={maxValues} labels={labels}/>:
              <BasicChart data={values} labels={labels}/>}
            </CardContent>
        </Card>
    </div>      
    
    ) 
}

export default CustomBarChart;
