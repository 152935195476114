/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthCard } from "@/components/Auth/AuthCard"
import useOnAuth from "@/customMutators/useOnAuth";
import { useSignIn } from "@/utils/Auth/Session";
import { LoginSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { Input } from "@/components/ui/input";


export const Login = () => {
  const navigate=useNavigate({from:'/auth'})
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const queryClient=useQueryClient()
  const { register, handleSubmit, formState: {errors} } = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      userName: "",
      password: "",
    }});
  const {mutate:SignIn,isSuccess,isPending,isError,error}=useSignIn({queryClient})
  const {hrRefetch}=useOnAuth({onAuthSuccess:()=>{
    navigate({to:'/profile'})
  }})

  if(isSuccess){
    hrRefetch()
  }

  const _triggerSubmit = () => {
    submitRef?.current?.click();
  };
  const submitRef = useRef(null) as any

  const onSubmit = async (data:{userName:string,password:string}) => {
    SignIn({
      userName:data.userName,
      password:data.password
    })

    if (isError){
      console.log('Error: ',error);
      toast.error("Unable to login due to an error!",{
        description: `${error}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  };

  
  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  
  return (
    <AuthCard 
    loading={isPending}
    cardTitle="Sign In"
    buttonText="Log-in"
    //cardDescription="Enter your username and password to log into your account"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid w-full items-center gap-3">
          <div className="flex flex-col space-y-1.5" key="userName">
            <Input id="userName" type="text" {...register("userName")} name="userName" placeholder="Username" className="border-primary100 border-2 rounded-xl text-center"/>
            {errors["userName"] && errors["userName"].message && <p className="text-red-500 text-sm">{errors["userName"].message}</p>}
          </div>
          <div className="flex flex-col space-y-1.5" key="password">
            <div className="flex">
              <Input id="password" type={`${type}`} {...register("password")} name="password" placeholder="Password" className="border-primary100 border-2 rounded-xl text-center"/>
              <span className="flex justify-around items-center" onClick={handleToggle}>
                <Icon className="absolute mr-10" icon={icon} size={15}/>
              </span>
            </div>
            {errors["password"] && errors["password"].message && <p className="text-red-500 text-sm">{errors["password"].message}</p>}
          </div>
        </div>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>

    </AuthCard>
  )
}