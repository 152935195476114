import BasicBarChart from "@/components/ui/MuiBarChart";
import MacroCard from "./Components/MacroCard";
import { metricInfo } from "./utils/Text";
import { values_To_NumArray } from "./utils/UtilFunctions";

export type SimplicityofCodeProps={
    simplicityofCode:any
}

const SimplicityofCode = ({data}:{data?:any}) => {

    //const simplicityofCodeArr=values_To_NumArray(data)
    //console.log("SC: ",simplicityofCodeArr);

    let simplicityofCode=[0,0]
    let maxSimplicityofCode=[0,0]
    const labels=["Complexity","Cognitive Complexity"]
    
    if(data){
        const simplicityofCodeArr = values_To_NumArray(data[0])
        simplicityofCode = simplicityofCodeArr
        console.log("Simplicity: ",simplicityofCode)
        const maxSimplicityofCodeArr = values_To_NumArray(data[1])
        maxSimplicityofCode = maxSimplicityofCodeArr
    }

    return (  
        <MacroCard 
            title={"Simplicity Of Code"} 
            cardContentStyle={"m-2 p-2"} 
            definition={metricInfo.SimplicityofCode.definition} 
            benefit={metricInfo.SimplicityofCode.benefit}>
            { data? <BasicBarChart data={simplicityofCode} maxData={maxSimplicityofCode} labels={labels} avgLabel="Candidates Score"/> : <h1>No data to be displayed</h1> }
        </MacroCard>
    ) 
}

export default SimplicityofCode;
