//"use client"
//import Link from "next/link"
import { Button } from "@/components/ui/button";
import { PlusCircle } from 'lucide-react';
import { useState } from "react";
import { NewUserForm } from "./NewUserForm";

export function AddUser() {
    const [showForm,setShowForm] = useState(false)
  
    /* const addUserHandler = () => {

    } */

    return (
        <div className="flex flex-col space-y-1">
            <Button onClick={()=>setShowForm(!showForm)} className="flex h-16 bg-white text-black text-lg hover:bg-gray-300 space-x-4"><p>ADD USERS</p> {!showForm && <PlusCircle/>}</Button>
            {showForm && <NewUserForm/>}
        </div>
    )
}