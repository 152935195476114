import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "@/components/ui/popover"
import { Label } from '@/components/ui/label'
import { CalendarIcon } from 'lucide-react'
import { UseFormReturn } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/Calendar'
import { cn } from '@/lib/utils'
import { format } from 'date-fns'

export const StartOfCampaignDate = ({form,disabled}:{form:UseFormReturn<any>,disabled?:boolean}) => {
  return (
    <FormField
                        control={form.control}
                        name="startOfCampaignDate"
                        render={({ field }) => (
                            <FormItem className="space-y-2">
                                <Label htmlFor="startDate" className="text-base font-semibold flex items-center gap-2">
                                    <CalendarIcon className="w-5 h-5" />
                                    Start Date
                                </Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <FormControl>
                                            <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "w-full justify-start text-left font-normal transition-all duration-200 focus:ring-2 focus:ring-[#22b4b1] truncate",
                                                    !field.value && "text-muted-foreground",
                                                    form.formState.errors["startOfCampaignDate"] && "border-red-500"
                                                )}
                                                disabled={disabled}
                                            >
                                                {field.value ? format(field.value, "PPP") : <span>Pick a date</span>}
                                            </Button>
                                        </FormControl>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="single"
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            disabled={(date: any) => {
                                                const priorDate = new Date();
                                                priorDate.setDate(priorDate.getDate() - 1);
                                                // Disable dates prior to the current date or earlier
                                                return date < priorDate;
                                            }}
                                            initialFocus
                                        />
                                    </PopoverContent>
                                </Popover>
                                {form.formState.errors["startOfCampaignDate"] && (
                                    <FormMessage className="text-red-500"/>
                                )}
                            </FormItem>
                        )}
                        />
  )
}


export const EndOfCampaignDate = ({form}:{form:UseFormReturn<any>}) => {
  return (
    <FormField
                        control={form.control}
                        name="endOfCampaignDate"
                        render={({ field }) => (
                            <FormItem className="space-y-2">
                                <Label htmlFor="endOfCampaignDate" className="text-base font-semibold flex items-center gap-2">
                                <CalendarIcon className="w-5 h-5" />
                                End Date
                                </Label>
                                <Popover>
                                <PopoverTrigger asChild>
                                    <FormControl>
                                        <Button
                                            variant={"outline"}
                                            className={cn(
                                                "w-full justify-start text-left font-normal transition-all duration-200 focus:ring-2 focus:ring-[#22b4b1] truncate",
                                                !field.value && "text-muted-foreground",
                                                form.formState.errors["endOfCampaignDate"] &&
                                                    "border-red-500"
                                            )}
                                        >
                                            {field.value ? format(field.value, "PPP") : <span>Pick a date</span>}
                                        </Button>
                                    </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0">
                                    <Calendar
                                    mode="single"
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    disabled={(date:any) => {
                                        const startDate = form.getValues("startOfCampaignDate")
                                        const priorDate = new Date()
                                        console.log('Start Date: ',startDate)
                                        if(startDate > priorDate){
                                            return date <= startDate
                                        }
                                        return date < priorDate;
                                    }}
                                    initialFocus
                                    />
                                </PopoverContent>
                                </Popover>
                                {form.formState.errors["endOfCampaignDate"] && (
                                    <FormMessage className="text-red-500"/>
                                )}
                            </FormItem>
                        )}
                        />
  )
}
