import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

export type MacroProps={
  children:React.ReactNode,
  definition?:string,
  benefit?:string
}

const InfoCard = ({
  children,
  definition,
  benefit
}:MacroProps) => {
    return (          
        <HoverCard>
            <HoverCardTrigger>
                {children}
            </HoverCardTrigger>
            <HoverCardContent>
                <div className="flex justify-between space-x-4">
                    <div className="space-y-2">
                        <p className="text-sm font-normal tracking-normal">{definition}</p>
                        <div>
                          <p className="text-sm underline">Why it matters:</p>
                          <p className="text-sm font-normal tracking-normal">{benefit}</p>
                        </div>
                    </div>
                </div>
            </HoverCardContent>
        </HoverCard>
    ) 
}

export default InfoCard;
