export const dateFormat=(datetime:string)=>{
    //console.log('Date: ',date)
    const date = datetime.split("T")[0]
    const datePart = date.split(/[\s-]+/);
    const year = datePart[0]
    const month = datePart[1]
    const day = datePart[2]
    const dateFormatted = `${month}/${day}/${year}`
    return dateFormatted
}

export const capitalizeAndSplit=(str:string)=>{
    console.log('String: ',str)
    // Split the string before a capital letter
    const parts = str.split(/(?=[A-Z])/);
    
    // Capitalize the first letter of each part
    for (let i = 0; i < parts.length; i++) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
    }
    
    // Join the parts back together
    const result = parts.join(" ");
    
    return result;
}

export const parseZFormatDate=(dateString?:string)=>{
    if (!dateString) {
        return new Date(0); // This represents the Unix epoch (January 1, 1970)
    }
    console.log(dateString);
    // Split the date string into parts
    const parts = dateString.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const dayAndTime = parts[2].split('T');
    const day = parseInt(dayAndTime[0]);
    const time = dayAndTime[1].split(':');
    const hour = parseInt(time[0]);
    const minute = parseInt(time[1]);
    const second = parseInt(time[2].substring(0, 2)); // Remove the 'Z' at the end
    
    // Create a Date object
    return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
}

export const lowercaseAndRemoveLast=(str:string)=>{
    console.log('String: ',str)
    // Split the string before a capital letter
    let parts = str.split(" ").slice(0,-1);
  	console.log('Parts: ',parts)
    
    // Capitalize the first letter of each part
    for (let i = 0; i < parts.length; i++) {
        parts[i] = parts[i].charAt(0).toLowerCase() + parts[i].slice(1);
    }

    
    // Join the parts back together
    const result = parts.join("");
    
    return result;
}

export const lowercase=(str:string)=>{
    console.log('String: ',str)
    // Split the string before a capital letter
    let parts = str.split(" ")
    
    // Capitalize the first letter of each part
    for (let i = 0; i < parts.length; i++) {
        parts[i] = parts[i].charAt(0).toLowerCase() + parts[i].slice(1);
    }

    
    // Join the parts back together
    const result = parts.join(" ");
    
    return result;
}
