import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"

type ComponentProps = {
  componentTitle?:string|React.ReactNode,
  Component?:React.ReactNode,
  errors?:string|React.ReactNode|any,
  disabled?:boolean
}

export function DropdownButton({componentTitle,Component,errors,disabled}:ComponentProps) {
  return (
    <Accordion type="single" collapsible className="w-full" disabled={disabled}>
      <AccordionItem value="item-1">
        <AccordionTrigger className="flex space-between">
          <div className="flex w-3/4 items-start font-normal">
            {componentTitle}
          </div>
          {errors}
        </AccordionTrigger>
        <AccordionContent className="flex flex-1 ">
          {Component}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
