import { Header } from "@/components/ui/Title";
//import { TrackSelect } from "./TrackSelect"
import { BellIcon, EnvelopeClosedIcon } from "@radix-ui/react-icons";
import AvatarComponent from "./Avatar";
//import AddCampaign from "@/pages/HiringCampaign/utils/AddCampaignPopover";

const HeaderComponent = ({
  title,
  component,
}: {
  title?: string;
  component?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-row justify-between items-end mb-4 sm:ml-36 ">
      <div className="flex-grow text-center px-4 ">
        <Header>{title}</Header>
      </div>
      <div className="flex justify-center space-x-4 items-center px-4">
        {/* <AddCampaign/> */}
        {component}
        <BellIcon width="19" height="23" className="mx-2" />{" "}
        {/* mx-2 for some horizontal margin */}
        <EnvelopeClosedIcon width="20" height="23" className="mx-2" />
        <AvatarComponent />
      </div>
    </div>
  );
};

export default HeaderComponent;
