import { Card } from "@/components/ui/card";
//import { Header } from "@/components/ui/Title"
import MicroCard from "./Components/MicroCard";
import { metricInfo } from "./utils/Text";
//import ArcDesign from "@/components/ui/Gauge"
import OverallCodeExcellence from "./OverallCodeExcellence";
import CodingPrecision from "./CodingPrecision";
import SimplicityofCode from "./SimplicityOfCode";
import CodeMaintainability from "./CodeMaintainability";
import DocumentationClarity from "./DocumentationClarity";
import SmallText from "@/components/ui/SmallText";
import { MoreCandidateDetail } from "../EntrepriseOverview/utils/MoreCandidateMockData";
//import SmallText from "@/components/ui/SmallText"
import { SkillsCheckbox } from "../EntrepriseOverview/utils/SkillsCheckbox";
import { hiringProcesses } from "../EntrepriseOverview/utils/MockData";
import { useState } from "react";
import HeaderComponent from "@/components/Header/Header";
import HalfGauge from "@/components/ui/HalfGauge";
import StatusGauge from "./StatusGauge";
//import RankingGauge from "./Components/RankingGauge"
//import { CandidateData } from "openapi/api/model/candidateData"

export type OverviewProps = {
  header: string | undefined;
  chartData: MoreCandidateDetail;
  skillset: string[] | undefined;
  email: string | undefined;
  experienceLvl: string | undefined;
  roleDesc: string | undefined;
  companyName: string | undefined;
  averages: number[];
  ranking: number | undefined;
};

const Dashboard = ({
  header,
  chartData,
  averages,
  companyName,
  skillset,
  experienceLvl,
  roleDesc,
  email,
}: OverviewProps) => {
  const [copied, setCopied] = useState(false);
  console.log("ChartData: ", chartData);
  const copyHandler = () => {
    navigator.clipboard.writeText(email as string);
    setCopied(true);
  };
  let ranking: string;
  const rank = chartData.rank?.toString() ?? "0";
  if (rank.endsWith("1") && rank != "11") {
    ranking = rank + "st place";
  } else if (rank.endsWith("2") && rank != "12") {
    ranking = rank + "nd place";
  } else if (rank.endsWith("3") && rank != "13") {
    ranking = rank + "rd place";
  } else {
    ranking = rank + "th place";
  }

  let statusProgression = 0;
  let statusLabel = "";
  //if campaign status in progres, the gauge value will be 50%
  if (chartData.status === "In Progress" || chartData.status === "Ongoing") {
    statusProgression = 50;
    statusLabel = "In Progress";
    console.log("Status: ", statusLabel);
  } else if (chartData.status === "Completed") {
    statusProgression = 100;
    statusLabel = "Completed";
    console.log("Status: ", statusLabel);
  }
  let empty = false;
  if (chartData) {
    empty =
      (chartData.documentationClarity.documented === null &&
        chartData.documentationClarity.undocumented === null) ||
      (chartData.documentationClarity.documented == 0 &&
        chartData.documentationClarity.undocumented == 0);
    console.log(empty);
  }

  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
      <HeaderComponent title={header as string} />
      <div className="flex flex-row justify-center items-center space-x-1 mt-8">
        <p className="font-bold">{email}</p>
        <button
          className="rounded-sm p-1 bg-mint100 hover:bg-cyan-400 active:bg-cyan-500 focus:outline-none focus:ring focus:ring-cyan-100"
          onClick={copyHandler}
        >
          {!copied ? (
            <SmallText>Copy Email</SmallText>
          ) : (
            <SmallText>Copied!</SmallText>
          )}
        </button>
      </div>
      <div className="bg-gray-100 mt-4 mb-8 space-y-8">
        <Card className="flex flex-col items-start border bg-white ml-8 mr-4 p-4 space-y-4">
          <SmallText textStyle="flex text-left space-x-1">
            <p className="font-bold ">Company Name:</p>
            <p>{companyName}</p>
          </SmallText>
          <SmallText textStyle="flex text-left space-x-1">
            <p className="font-bold">Role Description:</p>
            <p>{roleDesc}</p>
          </SmallText>
          <SmallText textStyle="text-left">
            <p className="font-bold">Skill set:</p>
            <SkillsCheckbox skills={skillset ?? hiringProcesses[0].skills} />
          </SmallText>
          <SmallText textStyle="text-left">
            <p className="font-bold">Experience Level:</p>
            <p>{experienceLvl}</p>
          </SmallText>
        </Card>

        <Card className="grid grid-cols-5 bg-white border ml-8 mr-4">
          <div className="grid grid-rows-subgrid col-start-2 col-span-3">
            <div className="grid grid-cols-3 space-x-4 py-4">
              <Card className="flex flex-col border place-items-center">
                <StatusGauge
                  value={statusProgression}
                  status={statusLabel}
                  width={100}
                  height={200}
                />
                <p className="font-bold text-lg">Status</p>
              </Card>
              <Card className="grid grid-col border items-end">
                <div className=" text-center pt-20  xl:text-2xl">{ranking}</div>
                <div className="text-center">
                  <p className="font-bold text-lg">Overall Ranking</p>
                </div>
              </Card>
              <Card className="flex flex-col border items-center">
                <HalfGauge
                  value={parseInt((chartData.overallSolutionFitness ?? 0).toFixed())}
                  width={100}
                  height={200}
                />
                <p className="font-bold text-lg">Overall Score</p>
              </Card>
            </div>
          </div>
        </Card>

        <Card className="grid grid-cols-2 max-xl:overflow-x-auto bg-white border ml-8 mr-4">
          <OverallCodeExcellence data={chartData?.overallCodeExcellence} />
          <CodingPrecision data={chartData?.codingPrecision} />
          <SimplicityofCode data={chartData?.simplicityOfCode} />
          <CodeMaintainability data={chartData?.codeMaitainability} />
          <>
            {!empty && (
              <DocumentationClarity data={chartData?.documentationClarity} />
            )}
            {empty && <DocumentationClarity />}
          </>
          <div className="grid grid-rows-subgrid ">
            <div className="grid grid-cols-2">
              <MicroCard
                title={"Code Quality and Efficiency"}
                metric={averages[0]}
                definition={metricInfo.OverallCodeExcellence.definition}
                benefit={metricInfo.OverallCodeExcellence.benefit}
              />
              <MicroCard
                title={"Problem-Solving Skills"}
                metric={averages[1]}
                definition={metricInfo.CodingPrecision.definition}
                benefit={metricInfo.CodingPrecision.benefit}
              />
              <MicroCard
                title={"Project Management"}
                metric={averages[2]}
                definition={metricInfo.SimplicityofCode.definition}
                benefit={metricInfo.SimplicityofCode.benefit}
              />
              <MicroCard
                title={"Communication Skills"}
                metric={averages[3]}
                definition={metricInfo.DocumentationClarity.definition}
                benefit={metricInfo.DocumentationClarity.benefit}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
