/* eslint-disable @typescript-eslint/ban-ts-comment */
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { AuthenticateUserResp } from '../../src/pages/Authentication/AuthUtils/Login';
import { queryClient } from '../../src/App';
import { GetPermissionsHeader200 } from 'openapi/api/model';

const permissionsMiddleware=({config}:{config:AxiosRequestConfig})=>{
  //console.log('PermissionsMiddleware called: ',config)
  try{
    const resp=queryClient.getQueryData(['/permissionsHeaderCustom/']) as {data?:GetPermissionsHeader200}
    
    //console.log('X-Permissions-Token: ',data)
    if(resp.data && config.headers){
      config.headers={
        ...config.headers,
        'X-Permissions-Token': resp.data['X-Permissions-Token']??'' 
      }
    }
  }catch(err){
      console.log({err})
    }

  return config
}

const authMiddleware=({config}:{config:AxiosRequestConfig})=>{
    const resp=queryClient.getQueryData(['Auth']) as AuthenticateUserResp
    const {session}=resp
    //console.log('AuthMiddleware config: ',config)
    if(session){
      const idToken=session.getIdToken()
      config.headers={
        ...config.headers,
        Authorization: `Bearer ${idToken.getJwtToken()}`
      }
    }
    return config
}

export const AXIOS_INSTANCE = Axios.create({ 
  //withCredentials:false,// enabling the browser to include relevant cookies
  baseURL: 'https://api.apisdevf.net' }); //change URL to actual backend url

// add a second `options` argument here if you want to pass extra options to each generated query
export const customClient = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig,
    ): Promise<T> => {
    const source = Axios.CancelToken.source();
    const authConfig=authMiddleware({config})
    const authPermissionsConfig=permissionsMiddleware({config:authConfig})
    const promise = AXIOS_INSTANCE({
        ...authPermissionsConfig,
        ...options,
        cancelToken: source.token,
    }).then(({ data }) => data)
    .catch((err)=>console.log(err));

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };

    return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
 