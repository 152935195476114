import BasicBarChart from "@/components/ui/MuiBarChart";
import MacroCard from "./Components/MacroCard";
import { metricInfo } from "./utils/Text";
import { values_To_NumArray } from "./utils/UtilFunctions";

export type CodeMaintainabilityProps={
    codeMaintainability:any
}

const CodeMaintainability = ({data}:{data?:any}) => {
    //const codeMaitainabilityArr=values_To_NumArray(data)
    
    let codeMaitainability=[0,0]
    let maxCodeMaitainability=[0,0]
    const labels=["Clean Code","Code Violations"]

    if(data){
        const codeMaitainabilityArr = values_To_NumArray(data[0])
        codeMaitainability = codeMaitainabilityArr
        console.log("Simplicity: ",codeMaitainability)
        const maxCodeMaitainabilityArr = values_To_NumArray(data[1])
        maxCodeMaitainability = maxCodeMaitainabilityArr
    }

    return (  
        <MacroCard 
            title={"Code Maintainability"} 
            cardContentStyle={"m-2 p-2"} 
            definition={metricInfo.OverallCodeExcellence.definition} 
            benefit={metricInfo.OverallCodeExcellence.benefit}>
            { data? <BasicBarChart data={codeMaitainability} maxData={maxCodeMaitainability} labels={labels} avgLabel="Candidates Score"/> : <h1>No data to be displayed</h1> }
        </MacroCard>
    ) 
}

export default CodeMaintainability;
