import MacroCard from "./Components/MacroCard";
import { metricInfo } from "./utils/Text";
import BasicBarChart from "@/components/ui/MuiBarChart";
import { values_To_NumArray } from "./utils/UtilFunctions";
/* 
export type OverallCodeExcellenceProps={
    overallCodeExcellence:any
} */

const OverallCodeExcellence = ({data}:{data?:any}) => {
    console.log("OCE data: ",data)
    //const overallCodeExcellenceArr=values_To_NumArray(data)
    let overallCodeExcellence=[0,0,0,0,0]
    let maxCodeExcellence=[0,0,0,0,0]
    const labels=["Issues","Complexity","Duplication","Documentation","Functionality %"]

    if(data){
        const overallCodeExcellenceArr = values_To_NumArray(data[0])
        overallCodeExcellence = overallCodeExcellenceArr
        console.log("OCE data: ",overallCodeExcellence)
        const maxCodeExcellenceArr = values_To_NumArray(data[1])
        maxCodeExcellence = maxCodeExcellenceArr
      }

    return (  
        <MacroCard 
            title={"Overall Code Excellence"}  
            cardContentStyle={"p-2"} 
            definition={metricInfo.OverallCodeExcellence.definition} 
            benefit={metricInfo.OverallCodeExcellence.benefit}>
            { data? <BasicBarChart data={overallCodeExcellence} maxData={maxCodeExcellence} labels={labels} avgLabel="Candidates Score"/> : <h1>No data to be displayed</h1> }
        </MacroCard>
    ) 
}

export default OverallCodeExcellence;
