import { Input } from "@/components/ui/input";
import { AccountUsers } from "openapi/api/model";
import { Toaster } from "@/components/ui/sonner";
import DeleteUser from "./DeleteUser";
import { Label } from "@/components/ui/label";


export function EditPendingUserForm({user}:{user?:AccountUsers}) {
    const permissionID = user?._id
    const currentUrl = window.location.href;

    return (
        <div className="flex flex-col w-full bg-white border rounded-md p-8">
            <div className="py-8">
                <Label className="text-destructive">Share the following link to your new user:</Label>
                <Input value={`${currentUrl.split("/profile")[0]}/newUser/${permissionID}`}/>
            </div>
            <div className="flex flex-col items-center mt-4">
                <DeleteUser user={user} />
            </div>
            <Toaster/>
        </div>
    )
}