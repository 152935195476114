import React, { useState } from 'react'
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import SearchFilter from '@/components/ui/custom/Table/filters/SearchFilter';
import RangeFilter from '@/components/ui/custom/Table/filters/RangeFilter';
import { DataTableFacetedFilter } from '@/components/ui/custom/Table/filters/FacetedFilter';
import { Button } from '@/components/ui/button';
import { CheckCircledIcon, Cross2Icon, StopwatchIcon,Pencil1Icon,LockClosedIcon } from '@radix-ui/react-icons';

export interface HCToolbarProps<TData>{
    setColumnFilters:React.Dispatch<React.SetStateAction<ColumnFiltersState>>,
    table:Table<TData> 
}

function HCToolBar<TData>({table,setColumnFilters}:HCToolbarProps<TData>) {
    const isFiltered = table.getState().columnFilters.length > 0
    const filterActionHandler=(columnId: string, filterValue: [number | string, number | string]) => {
        setColumnFilters((columnFilters)=>[...columnFilters,{id:columnId,value:filterValue}])
    };
    const [candidateFilter, setCandidateFilter] = useState <[string | number, string | number]>(['', '']);
    const [daysLeftFilter, setdaysLeftFilter] = useState <[string | number, string | number]>(['', '']);
    
  return (
    <div className="grid grid-cols-7 items-center space-x-2">
        {/* <SearchFilter columnId="startdate" table={table} placeHolder='Start Date'/> */}
        <SearchFilter columnId="position" table={table} placeHolder='Role'/>
        <DataTableFacetedFilter 
            column={table.getColumn("statusUI")}
            title={"Status"}
            options={[
            {
                label: 'Draft',
                value: 'draft',
                icon: Pencil1Icon
            },
            {
                label: 'Completed',
                value: 'completed',
                icon: CheckCircledIcon
            },
            {
                label: 'Closed',
                value: 'closed',
                icon: LockClosedIcon
            },
            {
                label: 'In Progress',
                value: 'inProgress',
                icon: StopwatchIcon
            }
            ]}/>
        {/* <RangeFilter
            valueName="met"
            onFilterChange={filterActionHandler}
            columnId="metScoreCriteria" 
            maxColumnValue={100}
            currentFilter={table.getState().columnFilters.find((filter)=>filter.id==="metScoreCriteria")?.value as [number | string,number | string]}
        /> */}
        <SearchFilter columnId="companyName" table={table} placeHolder='Company'/>
        <RangeFilter
            valueName="# of Candidates"
            columnId="numberApplicants"
            onFilterChange={(columnId, value) => {
                filterActionHandler(columnId, value);
                setCandidateFilter(value);
              }} 
            maxColumnValue={100}
            currentFilter={candidateFilter}
        />
        <RangeFilter
            valueName="Days Left"
            columnId="daysLeft" 
            onFilterChange={(columnId, value) => {
                filterActionHandler(columnId, value);
                setdaysLeftFilter(value);
              }}
            maxColumnValue={100}
            currentFilter={daysLeftFilter}
        />
        {/* <SearchFilter columnId="username" table={table} placeHolder='Created by'/> */}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {table.resetColumnFilters(true); 
                setCandidateFilter(['','']); setdaysLeftFilter(['',''])
            }}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )} 
    </div>
  )
}

export default HCToolBar