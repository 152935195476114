export type Skill = {
    id: string;
    label: string;
}

export type Role = {
    id: string;
    label: string;
}

export type Framework = {
    id: string;
    label: string;
}


export const skills: Skill[] = [
    /* {
        id: "javascript",
        label: "JavaScript"
    }, */
    {
        id: "python",
        label: "Python"
    }, 
    /* "Java", 
    "C++", 
    "Ruby", 
    "Go", 
    "Rust", */ 
    /* {
        id: "typescript",
        label: "TypeScript"
    }, */
    {
        id: "react",
        label: "React (Typescript)"
    }, 
    {
        id: "vue",
        label: "Vue (Typescript)"
    }, 
    {
        id: "angular",
        label: "Angular (Typescript)"
    }, 
    {
        id: "express",
        label: "Express (Typescript)"
    },
    {
        id: "bash",
        label: "Bash"
    },
    {
        id: "sql",
        label: "SQL"
    },
    /* "Node.js", 
    "Django", 
    "Flask", 
    "Spring Boot",
    "Docker", 
    "Kubernetes", 
    "AWS", 
    "Azure", 
    "GCP", 
    "CI/CD", 
    "Git", 
    "Agile",
    "Machine Learning", 
    "Data Science", 
    "Blockchain", 
    "IoT", 
    "Mobile Development" */
]
  
export const roles: string[] = [
    "Frontend Developer", "Backend Developer", "Full Stack Developer",
    //"DevOps Engineer", 
    /* "Data Scientist", "Machine Learning Engineer",
    "Mobile Developer", "UI/UX Designer", "Product Manager", "Scrum Master" */
]
  
export const frameworks: Record<string, string[]> = {
    "Frontend Developer": [
        "React (Typescript)", 
        "Vue (Typescript)", 
        "Angular (Typescript)", 
        //"Svelte"
    ],
    "Backend Developer": [
        "Express (Typescript)", 
        //"Typescript",
        //"Python",
        //"Django", 
        //"Spring Boot", 
        //"Ruby on Rails"
    ],
    "Full Stack Developer": [
        "React + Express (Typescript)", 
        "Angular + Express (Typescript)", 
        //"Django + React", 
        //"Ruby on Rails + Vue"
    ],
    /* "DevOps Engineer": [
        "Docker", 
        "Kubernetes", 
        //"Jenkins", 
        //"Terraform"
    ], */
    /* "Data Scientist": ["PyTorch", "TensorFlow", "Scikit-learn", "R"],
    "Machine Learning Engineer": ["TensorFlow", "PyTorch", "Keras", "Scikit-learn"],
    "Mobile Developer": ["React Native", "Flutter", "Ionic", "Native Android/iOS"],
    "UI/UX Designer": ["Figma", "Sketch", "Adobe XD", "InVision"],
    "Product Manager": ["Agile", "Scrum", "Kanban", "Lean"],
    "Scrum Master": ["Scrum", "Kanban", "SAFe", "LeSS"] */
}
  
export const frameworkSkills: Record<string, any[]> = {
    "Python": [
        {
            id: "python",
            label: "Python"
        }, 
    ],
    "React (Typescript)": [
        {
            id: "react",
            label: "React (Typescript)"
        }, 
    ],
    "Vue (Typescript)": [
        {
            id: "vue",
            label: "Vue (Typescript)"
        }, 
    ],
    "Angular (Typescript)": [ 
        {
            id: "angular",
            label: "Angular (Typescript)"
        }, 
    ],
    "React + Express (Typescript)": [
        {
            id: "react",
            label: "React (Typescript)"
        },  
        {
            id: "express",
            label: "Express (Typescript)"
        }, 
    ],
    "Angular + Express (Typescript)": [
        {
            id: "angular",
            label: "Angular (Typescript)"
        },  
        {
            id: "express",
            label: "Express (Typescript)"
        }, 
    ],
    "Express (Typescript)": [
        {
            id: "express",
            label: "Express (Typescript)"
        }
    ],
    /*
    "Django": ["Python", "Django", "PostgreSQL", "REST API"],
    "Spring Boot": ["Java", "Spring Boot", "Hibernate", "REST API"],
    */
    /* "Docker": [
        {
            id: "docker",
            label: "Docker"
        },  
        //"Linux", 
        //"Networking", 
        //"Shell Scripting"
    ], 
    "Kubernetes": [
        {
            id: "kubernetes",
            label: "Kubernetes"
        },
        //"Docker", 
        //"Cloud Platforms", 
        //"YAML"
    ], */
    /* "PyTorch": ["Python", "PyTorch", "Machine Learning", "Deep Learning"],
    "TensorFlow": ["Python", "TensorFlow", "Machine Learning", "Deep Learning"],
    "React Native": ["JavaScript", "React Native", "Mobile Development"],
    "Flutter": ["Dart", "Flutter", "Mobile Development"],
    "Figma": ["UI Design", "Prototyping", "User Research"],
    "Agile": ["Agile Methodologies", "Scrum", "Product Management"],
    "Scrum": ["Scrum", "Agile Methodologies", "Team Management"] */
}