import { Route, useParams } from "@tanstack/react-router";
import { columns } from "../../components/Tables/Candidates/Column";
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { manageAccessToken } from "@/utils/Auth/Session";
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login";
import { Card, CardContent } from "@/components/ui/card";
import { useState } from "react";
import {
  useGetHiringcampaignsId,
  useGetHiringresultsSearch,
  useGetUserHiringresource,
} from "../../../openapi/api/endpoints/default/default";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import CandidatesTable from "@/components/Tables/Candidates/CandidatesTable";
import TotalApplicants from "./TotalApplicants";
import OverallScores from "./OverallScores";
import AverageScores from "./AverageScores";
import MetScoreCriteria from "./MetScoreCriteria";
import CampaignDetails from "./CampaignDetails";
import SmallText from "@/components/ui/SmallText";
import HeaderComponent from "@/components/Header/Header";
import {
  CampaignDetailResponse,
  FilterCampaignDetailReponse,
} from "openapi/api/model";
import { TabToggle } from "@/components/ui/custom/TabToggle";
import { ApplicantsTable } from "../AllCampaigns/ApplicantsTable";

export type HiringProcessData = {
  hiringProcessName: string;
  companyName: string;
  hiringProcessID: string;
  sub: string;
  hiringProcessSummary: string;
};

export type HiringCampaignMockTemplate = {
  _id: string;
  tracktemplate: {
    trackName: string;
    trackDescription: string;
  };
  companyName: string;
};

const HiringProcess = () => {
  const { hiringCampaignId } = useParams({
    from: "/allCampaigns",
  });
  console.log("ID: ", hiringCampaignId);
  const [copied, setCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const { data: HcInfo } = useGetHiringcampaignsId(hiringCampaignId);
  
  
  const { data: HCdetail } = useGetHiringresultsSearch({
    HR_format: "CampaignDetail",
    hiringCampaignId: hiringCampaignId,
  });
  const { data: HCcandidates } = useGetHiringresultsSearch({
    HR_format: "tableCampaignDetail",
    hiringCampaignId: hiringCampaignId,
  });

  const detail = HCdetail as CampaignDetailResponse;
  console.log('Page detail: ',detail)
  const candidateArr = HCcandidates as FilterCampaignDetailReponse[]|undefined;
  
  const metScore = { passed: detail?.passed, failed: detail?.failed };

  const { data: userData, isLoading, isFetched } = useGetUserHiringresource();
  const licenseId = userData?.licenseId;

  //reversed the data to show results from DB first
  const data = [...(candidateArr ?? [])].reverse();

  if (!licenseId && isFetched) {
    return (
      <div>
        Couldn't find a license associated with this account Start by adding and
        configuring a license fore your organization
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
        ...Loading
      </div>
    );
  }

  const copyHandler = () => {
    navigator.clipboard.writeText(hiringCampaignId);
    setCopied(true);
  };

  const copyLinkHandler = () => {
    //const currentUrl = window.location.href;
    navigator.clipboard.writeText(`https://app.thedevfactory.ca/apply/${hiringCampaignId}`);
    setLinkCopied(true);
  };


  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen ">
      {/* <div className="sm:ml-48"> */}
        <HeaderComponent title={detail?.position as string}/>
      {/* </div> */}

      <div className="p-4 space-y-2">
        <div className="flex flex-row justify-center items-center space-x-1 my-4">
          <p>Share Campaign:</p>
          <button
            className="rounded-sm p-1 bg-mint100 hover:bg-cyan-400 active:bg-cyan-500 focus:outline-none focus:ring focus:ring-cyan-100"
            onClick={copyHandler}
          >
            {!copied ? (
              <SmallText>Copy ID</SmallText>
            ) : (
              <SmallText>Copied!</SmallText>
            )}
          </button>
          <button
            className="rounded-sm p-1 bg-mint100 hover:bg-cyan-400 active:bg-cyan-500 focus:outline-none focus:ring focus:ring-cyan-100"
            onClick={copyLinkHandler}
          >
            {!linkCopied ? (
              <SmallText>Copy Link</SmallText>
            ) : (
              <SmallText>Copied!</SmallText>
            )}
          </button>
        </div>
        <Card className="bg-white py-4 m-4 grid">
          <CampaignDetails
            hcID={hiringCampaignId}
            detail={detail}
            companyName={HcInfo?.companyName}
            status={detail?.statusUI ?? ""}
            daysLeft={detail?.daysleft ?? 0}
          />
        </Card>

        <div className="grid grid-cols-2">
          <TotalApplicants
            data={[detail?.inProgressCount, detail?.completedCount]}
          />
          <OverallScores
            value={detail?.overallScoresAvg}
            minValue={detail?.overallScoresMin}
            maxValue={detail?.overallScoresMax}
          />
          <AverageScores
            dataAvg={[
              detail?.codeExcellenceAvg,
              detail?.precisionAvg,
              detail?.simplicityAvg,
              detail?.uniquenessAvg,
              detail?.documentationAvg,
            ]}
            dataMax={[
              detail?.codeExcellenceMax,
              detail?.precisionMax,
              detail?.simplicityMax,
              detail?.uniquenessMax,
              detail?.documentationMax,
            ]}
          />
          <MetScoreCriteria data={metScore} />
        </div>
        <Card className="bg-white">
          <CardContent className="pt-4 space-y-4">
          <h1 className="text-3xl font-bold mb-6">Your candidates</h1>
            <TabToggle
              tab1Label="All Applicants"
              tab1Content={<ApplicantsTable hiringCampaignId={hiringCampaignId} />}
              tab2Label="Evaluation results"
              tab2Content={<CandidatesTable columns={columns} data={data} />}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const HiringProcessTableRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/allCampaigns/$hiringCampaignId",
  component: HiringProcess,
  //stringifyParams: ({ hiringCampId }) => ({ hiringCampId: `${hiringCampId}` }),

  beforeLoad: async ({ context: { auth, queryClient } }) => {
    const session = await manageAccessToken({ AuthPayload: auth });
    if (!session) return;

    const newAuthPayload = { ...auth, session } as AuthenticateUserResp;
    queryClient.setQueryData(["Auth"], newAuthPayload);
    return {
      auth: newAuthPayload,
    };
  },
});

export default HiringProcessTableRoute;
