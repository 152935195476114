import { Route } from "@tanstack/react-router";
//import { rootRoute } from "../../App"
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { manageAccessToken } from "@/utils/Auth/Session";
import { AuthenticateUserResp } from "../Authentication/AuthUtils/Login";
import { HiringCampaignForm } from "./HiringCampaignForm";
import { CardContent } from "@/components/ui/card";
import HeaderComponent from "@/components/Header/Header";
//import { useGetUser } from '../../../openapi/api/endpoints/default/default';

const NewCampaign = () => {
  console.log("usegetuser test:  ");
  //const {data,isSuccess} = useGetUser()

  /*  if(isSuccess){
    console.log('user data is',data?.sub)
  } */

  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen">
      <HeaderComponent />
      <div className="flex flex-col m-4">
        <div className="border-none ml-4">
          <CardContent className="p-4">
            <HiringCampaignForm />
          </CardContent>
        </div>
      </div>
    </div>
  );
};

const NewCampaignRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/newCampaign",
  component: NewCampaign,
  beforeLoad: async ({ context: { auth, queryClient } }) => {
    const session = await manageAccessToken({ AuthPayload: auth });
    if (!session) {
      //no token means that token is still good
      return;
    }
    const newAuthPayload = {
      ...auth,
      session,
    } as AuthenticateUserResp;
    queryClient.setQueryData(["Auth"], newAuthPayload);
    return {
      auth: newAuthPayload,
    };
  },
});
export default NewCampaignRoute;
