import {
    ColumnDef
  } from "@tanstack/react-table"
import TableComponent from "../../ui/custom/Table/TableComponent"
import CandidatesTableToolbar from './CandidatesTableToolbar';
import { useTable } from "../useTable";
import { MoreCandidateDetail, MoreCandidateDetail2 } from "@/pages/EntrepriseOverview/utils/MoreCandidateMockData";
import { CandidateData, FilterCampaignDetailReponse } from "openapi/api/model";

  
export type Candidate={
    userName:string,
    email:string,
    hiringCampaignId:string,
    sub:string,
    overallRatingData:number,
    Code_Maintainability:number,
    Security_Compliance:number,
    overallSolutionFitness:number,//from deployment testing results
    completedEval:boolean,
    moreDetail: MoreCandidateDetail|MoreCandidateDetail2,
    data?: CandidateData

}

export type CData={
    data?: FilterCampaignDetailReponse,
    hiringCampaignID?:string
}

export interface DataTableProps<TData, TValue>{
columns:ColumnDef<TData, TValue>[],
data:TData[]
}

function CandidatesTable<TData, TValue>({
    columns,
    data,
}: DataTableProps<TData, TValue>) {


const {setColumnFilters,table}=useTable({columns,data})


return (
    <div>
        <CandidatesTableToolbar setColumnFilters={setColumnFilters} table={table}/>
        <TableComponent columns={columns} table={table}/>
    </div>    
    
)
}
export default CandidatesTable