import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { User } from 'lucide-react'
import { UseFormReturn } from 'react-hook-form'

export const PositionInput = ({form,disabled}:{form:UseFormReturn<any>,disabled?:boolean}) => {
    return (
        <FormField
            control={form.control}
            name="position"
            render={() => (
                <FormItem className="space-y-2">
                    <Label htmlFor="position" className="text-base font-semibold flex items-center gap-2">
                        <User className="w-5 h-5" />
                        Position
                    </Label>
                    <FormControl>
                        <Input
                            id="position"
                            placeholder="Enter position title"
                            {...form.register("position")}
                            //required
                            className={`w-full transition-all duration-200 focus:ring-2 focus:ring-[#22b4b1] ${
                                form.formState.errors["position"] ? "border-red-500" : "border"
                            }`}
                            disabled={disabled}
                            />
                    </FormControl>
                    {form.formState.errors["position"] && (
                        <FormMessage className="text-red-500"/>
                    )}
                </FormItem>
            )}
        />
  )
}
