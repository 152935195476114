import SmallText from "@/components/ui/SmallText";
import { Button } from "@/components/ui/button";
import { CloseCampaignDialog } from "./components/CloseCampaignDialog";
import { capitalizeAndSplit, parseZFormatDate } from "@/utils/Functions/Functions";
import { useGetUserHiringresource, useGetUserHiringresourceAccount, usePutHiringcampaignsId } from "../../../openapi/api/endpoints/default/default";
import { useQueryClient } from "@tanstack/react-query";
import { CampaignDetailResponse, HiringCampaign, HrUser } from "openapi/api/model";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { HiringCampaignFormSchema } from "@/utils/FormUtils/FormSchema";
import { HiringCampaignFormValues } from "../HiringCampaign/components/editHiringCampaignForm";
import { Form } from "@/components/ui/form";
import { EndOfCampaignDate, StartOfCampaignDate } from "../HiringCampaign/components/CampaignPeriodInputs";
import PositionDescriptionInput from "../HiringCampaign/components/PositionDescriptionInput";
import ExperienceLevelSelector from "../HiringCampaign/components/ExperienceLevelSelector";
import { CompanyNameInput } from "../HiringCampaign/components/CompanyNameInput";
import { useEffect } from "react";
import { FormSkillsCheckbox } from "./utils/SkillsCheckbox";
import { PositionInput } from "../HiringCampaign/components/PositionInput";

export type CampaignDetailsProps = {
  hcID: string;
  companyName?: string;
  status?: "Draft" | "Closed" | "In Progress" | "Complete" | string;
  //daysLive?: number,
  daysLeft?: number;
  detail: CampaignDetailResponse
};

const CampaignDetails = ({
  hcID,
  companyName,
  status,
  //daysLive,
  daysLeft,
  detail
}: CampaignDetailsProps) => {
  const { data } = useGetUserHiringresource();
  const { data:users } = useGetUserHiringresourceAccount()
  const write = users?.find((user)=>user.user?._id===data?._id)?.permissions[0].permissions.write

  const userData = data as HrUser;
  const queryClient = useQueryClient()

  //const detail = HCdetail as CampaignDetailResponse;
  console.log('Campaign Detail: ',detail)

  const form = useForm<HiringCampaignFormValues>({
    /* defaultValues: {
      //position: detail?.position??'',
      positionDescription: detail.positionDescription??'',
      companyName: companyName??'',
      experienceLevel: detail.experienceLevel??'',
      skills: detail.skills??[''],
      startOfCampaignDate: parseZFormatDate(detail?.startDate),
      endOfCampaignDate: parseZFormatDate(detail?.endDate), 
    }, */
    resolver: zodResolver(HiringCampaignFormSchema),
  });

  
  const { mutate, error } = usePutHiringcampaignsId({
    mutation: {
      onSuccess:()=>{  
        toast.success("Submitted!", {
          description: "Hiring Campaign updated successfully",
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: "#a3e635",
          },
        });
      },
      onError:()=>{
        console.log("Error: ", error);
        toast.error("An error occured!", {
          description: `${error}`,
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: "#dc2626",
          },
        });
      },
      onSettled:()=>{
        console.log('On settled: ',hcID)
        queryClient.invalidateQueries()
      }
    }
  });

  const onSubmit = (data: any) => {
    //console.log('sumbitted data: ',data)
    const submittedData = data as HiringCampaignFormValues;
    const startDate = submittedData?.startOfCampaignDate.toISOString(); //.split("T")[0]
    const endDate = submittedData?.endOfCampaignDate.toISOString(); //.split("T")[0]

    console.log("Submitted!!", submittedData);
    const campaignInfo: HiringCampaign = {
      position: submittedData.position,
      positionDescription: submittedData.positionDescription,
      skills: submittedData.skills,
      experienceLevel: submittedData.experienceLevel,
      companyName: submittedData.companyName,
      devlimit: 150,
      companyId: userData.companyId as string,
      startDate: startDate,
      endDate: endDate,
      manuallyClosed: false,
      minimumScore: 80,
    };
    console.log("Payload: ", campaignInfo);
    
    mutate({id:hcID, data:campaignInfo});
    
  }

  useEffect(() => {
    if (detail) {//only rerender if these conditions are satisfied
      form.reset({
        position: detail?.position??'',
        positionDescription: detail.positionDescription??'',
        companyName: companyName??'',
        experienceLevel: detail.experienceLevel??'',
        skills: detail.skills??[],
        startOfCampaignDate: parseZFormatDate(detail?.startDate),
        endOfCampaignDate: parseZFormatDate(detail?.endDate),
      });
      console.log('Form: ',form)
    }
  }, [form, detail]);
  
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2 px-4 py-4 mx-4">
        <div className="grid grid-cols-2 pb-4 px-4 max-xl:grid-cols-1 max-xl:gap-16"> 
          <div className="grid grid-cols-2 grid-rows-2 justify-center items-center gap-8 max-xl:order-last">
            <PositionInput form={form} disabled={form.getValues("startOfCampaignDate")<new Date()}/>
            <CompanyNameInput form={form} disabled={form.getValues("startOfCampaignDate")<new Date()}/>
            <StartOfCampaignDate form={form} disabled={form.getValues("startOfCampaignDate")<new Date()}/>
            <EndOfCampaignDate form={form}/>
          </div>
          <div className="relative flex flex-row pb-4 px-4 justify-end items-center space-x-4 xl:space-x-8">
          <div className="absolute top-0 right-0 flex flex-row items-center space-x-8">
            <SmallText textStyle="flex text-center space-x-1">
              <p className="font-bold">Days Left:</p> 
              <p>{daysLeft}</p>
            </SmallText>
            
            <SmallText textStyle="flex flex-row items-center text-center space-x-1">
              <p className="font-bold">Status:</p>
              <div className="flex flex-row items-center px-2 space-x-2">
                <p>{capitalizeAndSplit(status as string)}</p>
                <CloseCampaignDialog
                  id={hcID}
                  btn={
                    <Button className="bg-mint100 text-black hover:bg-teal100" disabled={!write} >
                      Close Campaign
                    </Button>
                  }
                />
              </div>
            </SmallText>
            </div>
            
          </div>  
        </div>

        <div className="flex flex-col py-0 px-4 space-y-8">
          <PositionDescriptionInput form={form} disabled={form.getValues("startOfCampaignDate")<new Date()}/>
          <div className="grid grid-cols-2 gap-32">
          
            <div>
              <FormSkillsCheckbox form={form} disabled={form.getValues("startOfCampaignDate")<new Date()}/>
            </div>

            <div>
              <ExperienceLevelSelector 
                form={form} 
                placeholder={form.getValues("experienceLevel")}
                disabled={form.getValues("startOfCampaignDate")<new Date()}/>
            </div>
            
          </div>
          <div className="flex justify-end">
            <Button 
              type="submit"
              className="bg-mint100 text-black hover:bg-teal100">
                Save changes
            </Button>
          </div>
          
        </div>
      </form>
    </Form>
  );
};

export default CampaignDetails;
